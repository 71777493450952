import React from "react";

import './loader.css';

const Loader = () => {
  return (
    <>
      <div className="balls">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </>
  );
};

export default Loader;
