import React from "react";

import {
  SCDigitalCardCont,
  SCDigitalItemsCont,
  SCDigitalTitleCont,
  SCDigitalWrap,
} from "./SCStyled";

import useWindowDimensions from "../../hooks/useDimensions";

import InfoItemCircleCheck from "../InfoItemCircleCheck/InfoItemCircleCheck";
import { MaxWidthWrap, TitleSection } from "../Reusable";
import { TextGradient } from "../GradientComponents/Gradient";

import cardImg from "../../assets/img/app-sc-animated.png";

const SCDigital = (props) => {
  const { sectionID } = props;

  const { width } = useWindowDimensions();

  return (
    <>
      <SCDigitalWrap id={sectionID}>
        <MaxWidthWrap>
          <SCDigitalCardCont>
            <img src={cardImg} alt="" />
          </SCDigitalCardCont>

          <SCDigitalTitleCont>
            <TitleSection colorLight>
              {width < 1023 ? (
                <>
                  ¿Qué puedo hacer con la tarjeta digital prepago Mastercard®{" "}
                  <TextGradient>Sabores Card?</TextGradient>
                </>
              ) : (
                <>
                  ¿Qué puedo hacer con la tarjeta digital <br /> prepago
                  Mastercard® <TextGradient>Sabores Card?</TextGradient>
                </>
              )}
            </TitleSection>
          </SCDigitalTitleCont>

          <SCDigitalItemsCont>
            {/* Item 1 */}
            <InfoItemCircleCheck
              title="Pagos online"
              paragraph="Podrás pagar online, tu delivery favorito, supermercado,
                restaurante, entre otros."
            />

            {/* Item 2 */}
            <InfoItemCircleCheck
              title="Gestionar tus beneficios"
              paragraph="Podrás revisar tu saldo, movimientos, ver estadísticas,
                  búsquedas de comercios."
            />

            {/* Item 3 */}
            <InfoItemCircleCheck
              title="La seguridad primero"
              paragraph="Podrás cambiar tu contraseña, bloquear y activar tarjeta y
                  pagos a través de clave dinámica."
            />

            {/* Item 4 */}
            <InfoItemCircleCheck
              title="Siempre conectado"
              paragraph="Podrás recibir notifiaciones de ofertas, promociones y
                  novedades de tus beneficios."
            />
          </SCDigitalItemsCont>
        </MaxWidthWrap>
      </SCDigitalWrap>
    </>
  );
};

export default SCDigital;
