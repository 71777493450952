import React from "react";

import { FooterDisclaimerContainer, FooterWrap } from "./FooterStyled";

import useWindowDimensions from "../../hooks/useDimensions";

import { MaxWidthWrap } from "../Reusable";

import FooterMobile from "./FooterMobile";
import FooterDesktop from "./FooterDesktop";

const Footer = (props) => {
  //const {} = props;

  //
  const { width } = useWindowDimensions();

  //
  const handleMailTo = (e) => {
    window.location.href = "mailto:contacto@saborescard.cl";
    e.preventDefault();
  };

  return (
    <>
      <FooterWrap>
        <MaxWidthWrap isTablet>
          {width < 1024 ? (
            <FooterMobile handleMailTo={handleMailTo} />
          ) : (
            <FooterDesktop handleMailTo={handleMailTo} />
          )}

          <FooterDisclaimerContainer>
            <p>
              Infórmese sobre las entidades autorizadas para emitir Tarjetas de
              Pago en el país, quienes se encuentran inscritas en los Registros
              de Emisores de Tarjetas que lleva la CMF, en&nbsp;
              <a
                target="_blank"
                href="https://www.cmfchile.cl/"
                rel="noopener noreferrer"
              >
                https://www.cmfchile.cl/
              </a>
            </p>
          </FooterDisclaimerContainer>
        </MaxWidthWrap>
      </FooterWrap>
    </>
  );
};

export default Footer;
