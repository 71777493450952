import React from "react";

import "@lottiefiles/lottie-player";

import { CircleGradientAlt } from "../GradientComponents/Gradient";

import { InfoItemCircleCheckWrap } from "./InfoItemCircleCheckStyled";

const InfoItemCircleCheck = (props) => {

  const { title, paragraph } = props;

  return (
    <>
      <InfoItemCircleCheckWrap>
        <div>
          <CircleGradientAlt>
            <lottie-player
              autoplay
              //preserveAspectRatio={'xMaxYMid slice'}
              //count={2}
              loop
              mode="bounce"
              //hover
              src="https://assets10.lottiefiles.com/packages/lf20_lw5cykwf.json"
              style={{ transform: "scale(1.7)" }}
            ></lottie-player>
          </CircleGradientAlt>
        </div>

        <div>
          <h5>{title}</h5>
          <p>
            {paragraph}
          </p>
        </div>
      </InfoItemCircleCheckWrap>
    </>
  );
};

export default InfoItemCircleCheck;
