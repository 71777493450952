import React, { useContext, useEffect, useState, useRef } from 'react';

import Swal from 'sweetalert2';

import ReCAPTCHA from 'react-google-recaptcha';

import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { sendMailConversion } from '../../api/apiMail';

import {
  convertNumber,
  allowOnlyNumber,
  allowOnlyLetters,
  handleSpecialCh,
  handleArrowKeys,
  phoneRegExp,
  allowNumsLetters,
} from '../../utils/form';

import {
  FormConversionWrap,
  FormSingleInputCont,
  FormSubmitCont,
  FormTwoInputCont,
  ReCaptchaCont,
  ReCaptchaMessage,
} from './FormStyled';

import Loader from '../Loader/Loader';

import { FormInputError, FormInputWrap } from '../FormInput/FormInputStyled';
import { Button } from '../Button/ButtonStyled';
import { MainContext } from '../../context/useContext';

//import { RECAPTCHA_KEY } from '../../api';

//
const schema = yup.object().shape({
  firstName: yup.string().trim().required('Nombre es requerido.'),
  lastName: yup.string().trim().required('Apellido es requerido.'),
  business: yup.string().trim().required('Empresa es requerida.'),
  email: yup
    .string()
    .trim()
    .required('Email es requerido.')
    .email('Email es inválido'),
  phone: yup
    .string()
    .matches(phoneRegExp, 'El teléfono no es válido')
    .required('Teléfono es requerido.')
    .min(9, 'Debe tener al menos 9 dígitos.')
    .max(9, 'No debe exceder de 9 dígitos'),
  workers: yup
    .number()
    .integer('Cantidad debe ser un entero')
    .positive('Cantidad debe ser positivo')
    .transform((value) => (isNaN(value) ? undefined : value))
    .min(1, 'Cantidad debe ser mayor a uno')
    .required('Cantidad es requerida'),
  assignedAmount: yup
    .number()
    .integer('Cantidad debe ser un entero')
    .positive('Cantidad debe ser positivo')
    .transform((value) => (isNaN(value) ? undefined : value))
    .min(500, 'Monto debe ser un mínimo de $500')
    .required('Monto es requerido'),
  comments: yup.string().trim(),
});

const FormConversion = (props) => {
  const [loading, setLoading] = useState(false);
  const [captchaValid, setCaptchaValid] = useState(false);
  const [inputClean, setInputClean] = useState('');

  const { workers, assignedAmount } = useContext(MainContext);

  //
  const {
    reset,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),

    defaultValues: {
      firstName: '',
      lastName: '',
      business: '',
      email: '',
      phone: '',
      workers: '',
      assignedAmount: '',
      comments: '',
    },
  });

  //
  const captcha = useRef(null);

  // Captcha
  const captchaOnChange = (value) => {
    //console.log(captcha.current.getValue());

    if (captcha.current.getValue()) {
      console.log('El usuario no es un robot');

      setCaptchaValid(true);
    } else {
      setCaptchaValid(false);
    }

    //console.log("Captcha value:", value);
  };

  // Submit Form
  const handleConversionForm = (data) => {
    //
    if (captcha.current.getValue()) {
      console.log('El usuario no es un robot');

      setCaptchaValid(true);

      // let valcaptcha = captcha.current.getValue();

      // captcha.current.reset();

      // console.log('ValCaptcha',  valcaptcha);



      //
      setLoading(true);

      // Tax Simulator
      let totalMonthAssigned = data.workers * data.assignedAmount;
      let totalAnnualAssigned = totalMonthAssigned * 12;

      let monthlyNetRecharge = Math.round(totalMonthAssigned / 1.19);
      let annualNetRecharge = Math.round(totalAnnualAssigned / 1.19);

      let monthlySavingIva = Math.ceil(monthlyNetRecharge * 0.19);
      let annualSavingIva = Math.ceil(annualNetRecharge * 0.19);

      let monthlySavingRentComplete = Math.round(monthlyNetRecharge * 0.27);
      let monthlySavingRentSimple = Math.round(monthlyNetRecharge * 0.1);
      let annualSavingRentComplete = Math.round(annualNetRecharge * 0.27);
      let annualSavingRentSimple = Math.round(annualNetRecharge * 0.1);

      let monthlySavingTotalComplete =
        monthlySavingIva + monthlySavingRentComplete;
      let monthlySavingTotalSimple = monthlySavingIva + monthlySavingRentSimple;
      let annualSavingTotalComplete =
        annualSavingIva + annualSavingRentComplete;
      let annualSavingTotalSimple = annualSavingIva + annualSavingRentSimple;

      let monthlyCostTotalComplete =
        totalMonthAssigned - monthlySavingTotalComplete;
      let monthlyCostTotalSimple =
        totalMonthAssigned - monthlySavingTotalSimple;
      let annualCostTotalComplete =
        totalAnnualAssigned - annualSavingTotalComplete;
      let annualCostTotalSimple = totalAnnualAssigned - annualSavingTotalSimple;

      // Send object
      const infoMail = {
        to: data.email,
        subject: 'Contacto Sabores Card',
        name: data.firstName + ' ' + data.lastName,
        business: data.business,
        phone: data.phone,
        workers: data.workers,
        assignedAmount: convertNumber(data.assignedAmount),
        text: data.comments,
        totalMonthAssigned: convertNumber(totalMonthAssigned),
        monthlySavingTotalComplete: convertNumber(monthlySavingTotalComplete),
        monthlyCostTotalComplete: convertNumber(monthlyCostTotalComplete),
        monthlyCostTotalSimple: convertNumber(monthlyCostTotalSimple),
        annualSavingTotalComplete: convertNumber(annualSavingTotalComplete),
        totalAnnualAssigned: convertNumber(totalAnnualAssigned),
        annualCostTotalComplete: convertNumber(annualCostTotalComplete),
        annualCostTotalSimple: convertNumber(annualCostTotalSimple),

        //captcha: valcaptcha,
      };

      //console.log("INFO MAIL", infoMail);

      // Api punch
      sendMailConversion(infoMail).then((response) => {
        //console.log("API Mail", response);
        //console.log(totalMonthAssigned);

        if (response.status === 'ERROR') {
          console.log('Error', response);

          reset();

          //
          setTimeout(() => {
            //
            Swal.fire({
              title: 'Oops..',
              text: 'Algo salió mal, por favor intenténtelo de nuevo',
              icon: 'error',
              confirmButtonText: 'Entendido',
              confirmButtonColor: '',
              buttonsStyling: false,
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            }).then((result) => {
              if (result.isConfirmed || result.isDenied) {
                //
              } else {
                //
              }
            });

            setLoading(false);
          }, 3000);
        } else {
          //console.log("OK", response);
          //console.log("conversion form submit", data);

          reset();

          //
          setTimeout(() => {
            //
            Swal.fire({
              title: 'Felicitaciones!',
              text: 'Has podido enviar el formulario con exito',
              icon: 'success',
              confirmButtonText: 'Entendido',
              confirmButtonColor: '',
              buttonsStyling: false,
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            }).then((result) => {
              if (result.isConfirmed || result.isDenied) {
                //
                setTimeout(() => {
                  //
                  window.location.href = '/';
                }, 1200);
              } else {
                //
                setTimeout(() => {
                  //
                  window.location.href = '/';
                }, 1200);
              }
            });

            setLoading(false);
          }, 3000);
        }
      });
      // .catch((err) => {
      //   console.error(err);
      // });
    } else {
      console.log('Por favor acepta el captcha');

      setCaptchaValid(false);

      return (
        <ReCaptchaMessage>
          <p>Debes activar el captcha</p>
        </ReCaptchaMessage>
      );
    }
  };

  //
  useEffect(() => {
    //console.log("CONVERSION", workers, assignedAmount);

    setValue('workers', workers);

    setValue('assignedAmount', assignedAmount);

    return () => {};
  }, [workers, assignedAmount]);

  //
  //console.log("firstName", watch("firstName"));
  // console.log("lastName", watch("lastName"));
  // console.log("email", watch("email"));
  // console.log("phone", watch("phone"));
  // console.log("Errors", errors);
  // console.log("isValid", isValid);

  return (
    <>
      <FormConversionWrap>
        <FormTwoInputCont>
          <FormInputWrap>
            <Controller
              name="firstName"
              control={control}
              render={({ field }) => (
                <>
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    autoComplete="off"
                    //{...register("firstName")}
                    {...field}
                    onChange={(val) => {
                      let value = allowOnlyLetters(val.target.value);
                      field.onChange(value);
                    }}
                  />
                  <label htmlFor="firstName">Nombre</label>
                </>
              )}
            />
            <FormInputError>{errors.firstName?.message}</FormInputError>
          </FormInputWrap>

          <FormInputWrap>
            <Controller
              name="lastName"
              control={control}
              render={({ field }) => (
                <>
                  <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    autoComplete="off"
                    //{...register("lastName")}
                    {...field}
                    onChange={(val) => {
                      let value = allowOnlyLetters(val.target.value);
                      field.onChange(value);
                    }}
                  />
                  <label htmlFor="lastName">Apellido</label>
                </>
              )}
            />
            <FormInputError>{errors.lastName?.message}</FormInputError>
          </FormInputWrap>
        </FormTwoInputCont>

        <FormSingleInputCont>
          <FormInputWrap>
            <Controller
              name="business"
              control={control}
              render={({ field }) => (
                <>
                  <input
                    type="text"
                    name="business"
                    id="business"
                    autoComplete="off"
                    //{...register("business")}
                    {...field}
                    onChange={(val) => {
                      let value = allowNumsLetters(val.target.value);
                      field.onChange(value);
                    }}
                  />
                  <label htmlFor="business">Empresa</label>
                </>
              )}
            />
            <FormInputError>{errors.business?.message}</FormInputError>
          </FormInputWrap>
        </FormSingleInputCont>

        <FormSingleInputCont>
          <FormInputWrap>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    autoComplete="off"
                    //{...register("email")}
                    {...field}
                    // onChange={(val) => {
                    //   let value = allowOnlyEmail(val.target.value);
                    //   field.onChange(value);
                    // }}
                  />
                  <label htmlFor="email">Correo electrónico</label>
                </>
              )}
            />
            <FormInputError>{errors.email?.message}</FormInputError>
          </FormInputWrap>
        </FormSingleInputCont>

        <FormSingleInputCont>
          <FormInputWrap>
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <>
                  <input
                    type="tel"
                    name="phone"
                    id="phone"
                    autoComplete="off"
                    {...field}
                    onChange={(val) => {
                      let value = allowOnlyNumber(val.target.value);
                      field.onChange(value);
                    }}
                  />
                  <label htmlFor="phone">Teléfono de contacto</label>
                </>
              )}
            />

            <FormInputError>{errors.phone?.message}</FormInputError>
          </FormInputWrap>
        </FormSingleInputCont>

        <FormTwoInputCont>
          <FormInputWrap>
            <Controller
              name="workers"
              control={control}
              render={({ field }) => (
                <>
                  <input
                    type="number"
                    name="workers"
                    id="workers"
                    autoComplete="off"
                    onKeyPress={handleSpecialCh}
                    onKeyDown={handleArrowKeys}
                    {...field}
                  />
                  <label htmlFor="workers">Cantidad de colaboradores</label>
                </>
              )}
            />
            <FormInputError>{errors.workers?.message}</FormInputError>
          </FormInputWrap>

          <FormInputWrap>
            <Controller
              name="assignedAmount"
              control={control}
              render={({ field }) => (
                <>
                  <input
                    type="number"
                    name="assignedAmount"
                    id="assignedAmount"
                    autoComplete="off"
                    onKeyPress={handleSpecialCh}
                    onKeyDown={handleArrowKeys}
                    {...field}
                  />
                  <label htmlFor="assignedAmount">
                    Monto de asignación mensual
                  </label>
                </>
              )}
            />
            <FormInputError>{errors.assignedAmount?.message}</FormInputError>
          </FormInputWrap>
        </FormTwoInputCont>

        <FormSingleInputCont>
          <FormInputWrap>
            <Controller
              name="comments"
              control={control}
              render={({ field }) => (
                <>
                  <textarea
                    {...field}
                    name="comments"
                    onChange={(val) => {
                      let value = allowNumsLetters(val.target.value);
                      field.onChange(value);
                    }}
                  />
                  <label htmlFor="comments">Comentarios</label>
                </>
              )}
            />
          </FormInputWrap>
        </FormSingleInputCont>

        <ReCaptchaCont>
          <ReCAPTCHA
            sitekey="6Lfb5SYfAAAAACYfUieUMlcLRlsUPNEV_5bx1a7u"
            hl="es-419"
            onChange={captchaOnChange}
            ref={captcha}
          />
        </ReCaptchaCont>

        <div>
          {isValid && !captchaValid && (
            <ReCaptchaMessage>
              <p>Debes activar el captcha</p>
            </ReCaptchaMessage>
          )}
        </div>

        <FormSubmitCont>
          <Button primary onClick={handleSubmit(handleConversionForm)}>
            {loading ? (
              <>
                <Loader />
              </>
            ) : (
              <>Enviar</>
            )}
          </Button>
        </FormSubmitCont>
      </FormConversionWrap>
    </>
  );
};

export default FormConversion;
