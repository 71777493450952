import styled from "styled-components";

export const AllianceWrap = styled.section`
  display: grid;

  background: radial-gradient(
    26.41% 17.72% at 50% 31.45%,
    rgba(255, 255, 255, 0) 0%,
    #f9f9f9 100%
  );

  padding: ${(props) => props.theme.spacing.sectionPaddingY}
    ${(props) => props.theme.spacing.sectionPaddingX};
`;

export const AllianceTitleCont = styled.div`
  text-align: center;

  margin-bottom: 2rem;

  & > div h3 {
    & span:first-child {
      color: ${(props) => props.theme.colors.primary2};
      font-weight: ${(props) => props.theme.fontWeight.bold};
    }
  }
`;

export const AllianceLogoCont = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;

  margin-bottom: 3rem;

  & > img:first-child {
    width: 14rem;
  }

  & > img:last-child {
    width: 6rem;
  }
`;

export const AllianceParagraphCont = styled.div`
  & > p {
    line-height: 1.4;
    margin-bottom: 3rem;
  }

  & a {
    text-decoration: underline;
  }

  @media ${(props) => props.theme.breakpoints.lg} {
    & > p {
      font-size: 1.8rem;
      line-height: 1.6;

      margin-bottom: 4rem;
    }
  }
`;
