import React from "react";

import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";

import {
  TermsContentCont,
  TermsItemCont,
  TermsListCont,
  TermsTitleCont,
  TermsWrap,
} from "../components/Terms/TermsStyled";

import {
  HeroSection,
  MaxWidthWrap,
  TitleSection,
} from "../components/Reusable";

const Cookies = () => {
  return (
    <>
      <TermsWrap>
        <Header />

        <HeroSection isTerms />

        <TermsContentCont>
          <MaxWidthWrap>
            <TermsTitleCont>
              <TitleSection>Políticas de cookies</TitleSection>
            </TermsTitleCont>

            {/* <TermsSubTitleCont>
              <h4>
                TÉRMINOS Y CONDICIONES GENERALES DE USO DE PORTAL Y SABORES CARD
                SPA (en adelante “SABORES CARD”)
              </h4>
            </TermsSubTitleCont> */}

            <TermsListCont>
              <TermsItemCont>
                <p>
                  El Emisor U-Pay Prepagos S.A. (en adelante “Emisor”), es quien
                  emite Tarjeta de Alimentación Sabores Card (en adelante
                  “Sabores Card”), desarrolló esta política con el objetivo de
                  ayudarte a comprender cómo y para qué Emisor de Sabores Card y
                  Holding U-Payments, utilizan las cookies y las opciones que
                  tienes para gestionarlas.
                </p>
              </TermsItemCont>

              <TermsItemCont>
                <h5>Definiciones</h5>

                <p>
                  Para efectos de la presente Política, las palabras que a
                  continuación se definen tendrán el significado asignado en
                  esta sección, sea que se escriban o no con inicial mayúscula,
                  o que se encuentren en plural o singular.
                </p>
                <ul>
                  <li>
                    <strong> Nosotros, Nos o Nuestro:</strong> U-Pay Prepagos
                    S.A. (en adelante “EMISOR”), es quien emite Tarjeta de
                    Alimentación Sabores Card (en adelante “SABORES CARD”) y
                    Holding U-Payments.
                  </li>
                  <li>
                    <strong>Usted o tú:</strong> Cualquier usuario/visitante del
                    sitio web y/o App.
                  </li>
                  <li>
                    <strong>Holding U-Payments: </strong>U-Payments Company
                    Ltda. (Holding), Sabores Card Spa (Tarjeta de Alimentación),
                    U-Pay Prepagos S.A. (Emisor), Universal Payments Spa.
                    (Software Factory), U-Pay Processing Spa (Procesador
                    Transaccional).
                  </li>
                  <li>
                    <strong>Sitio web: </strong>El sitio web de Sabores Card,
                    disponible en la dirección https://www.saborescard.cl
                  </li>
                  <li>
                    <strong>Tarjeta Habiente: </strong>Persona natural a quien
                    se le emite la Tarjeta de Alimentación para que la use en
                    compras del rubro Alimentación.
                  </li>

                  <li>
                    <strong>Cookies </strong>son ficheros que se almacenan en el
                    terminal del usuario que navega a través de Internet y que,
                    en particular, contiene un número que permite identificar
                    unívocamente al dispositivo del usuario, aunque éste cambie
                    de localización o de dirección IP.
                    <br /> Las cookies son instaladas durante la navegación por
                    Internet, bien por los sitios web que visita el usuario o
                    bien por terceros con los que se relaciona el sitio web, y
                    permiten a éste conocer su actividad en el mismo sitio o en
                    otros con los que se relaciona éste. Por ejemplo: el lugar
                    desde el que accede, el tiempo de conexión, el dispositivo
                    desde el que accede (fijo o móvil), el sistema operativo y
                    navegador utilizados, las páginas más visitadas, el número
                    de clicks realizados y datos respecto al comportamiento del
                    usuario en Internet. Los sitios web del Holding U-Payments,
                    son accesibles sin necesidad de que las cookies estén
                    activadas. Sin embargo, su desactivación puede impedir el
                    correcto funcionamiento de estos.
                  </li>
                </ul>
              </TermsItemCont>
              <TermsItemCont>
                <h5>USO DE COOKIES</h5>
                <p>
                  Utilizamos Cookies estrictamente necesarias y esenciales para
                  que utilices en nuestros sitios web y aplicaciones móviles y
                  puedas moverte libremente, utilizar áreas seguras y opciones
                  personalizadas
                </p>
                <p>
                  Además, utilizamos <strong>cookies</strong> que recogen datos
                  relativos al análisis de uso de la web. Éstas se utilizan para
                  ayudar a mejorar el servicio al cliente, midiendo el uso y el
                  rendimiento de la página para optimizarla y personalizarla.
                </p>
                Nuestros sitios también pueden tener enlaces de redes sociales
                (como Facebook o Twitter). Ni El Emisor U-Pay Prepagos S.A., ni
                ninguna de las demás empresas del Holding de U-Payments,
                controlan las <strong>cookies</strong> utilizadas por estas webs
                externas. Para más información sobre las
                <strong> cookies</strong> de las redes sociales u otras webs
                ajenas, te aconsejamos revisar sus propias políticas de
                <strong>cookies</strong>.
              </TermsItemCont>

              <TermsItemCont>
                <h5>TIPO Y USO DE COOKIES</h5>
                <p>
                  En el sitio web de Sabores Card, al igual que en el resto de
                  los sitios web del Emisor de Sabores Card y Holding
                  U-Payments, se utilizan <strong> cookies</strong> propias o de
                  terceros para:
                </p>
                <ul style={{ listStyleType: "upper-roman" }}>
                  <li>
                    Mejorar nuestros servicios y su funcionamiento, así como
                    para optimizar la experiencia de nuestros usuarios.
                  </li>
                  <li> Elaborar información estadística.</li>
                  <li>
                    Personalizar el contenido que ofrecemos a nuestros usuarios
                    en base a un análisis de sus hábitos de navegación.
                  </li>
                </ul>
                <p>
                  Los siguientes son los tipos de <strong> cookies</strong> que
                  utilizamos:
                </p>
                <p>
                  <strong> Cookies de sesión: </strong> Las{" "}
                  <strong> cookies</strong> de sesión son aquellas que duran el
                  tiempo que el usuario está navegando por la página web y se
                  borran al término. Sirven para almacenar información que solo
                  interesa conservar para la prestación del servicio solicitado
                  por el usuario en una sola ocasión.
                </p>
                <p>
                  <strong> Cookies persistentes: </strong> Estas{" "}
                  <strong> cookies</strong> quedan almacenadas en el terminal
                  del usuario por un tiempo más largo, facilitando así el
                  control de las preferencias elegidas sin tener que repetir
                  ciertos parámetros cada vez que se visite el sitio web.
                </p>
                <p>
                  <strong> Cookies propias:</strong> Son{" "}
                  <strong> cookies</strong> creadas por este sitio web y que
                  solo puede leer el propio sitio. En las webs del holding de
                  U-Payments, se instalan <strong> cookies</strong> propias
                  persistentes con las siguientes finalidades:
                </p>
                <ul>
                  <li>
                    <strong>Técnicas: </strong>Estas <strong> cookies</strong>{" "}
                    sirven para controlar la carga de las imágenes que aparecen
                    en el home, en función de los parámetros que se hayan
                    programado (tiempo, número de veces visto, etc..). Sirven,
                    además, para permitir el acceso a determinadas partes de la
                    web y para ubicar al usuario.
                  </li>
                  <li>
                    <strong>Personalización: </strong>Estas{" "}
                    <strong> cookies</strong> permiten al usuario acceder al
                    servicio con algunas características de carácter general
                    predefinidas, en función de una serie de criterios en el
                    terminal del usuario (por ejemplo, el idioma, el tipo de
                    navegador a través del cual accede al servicio, la
                    configuración regional desde donde accede al servicio,
                    etc.).
                  </li>
                  <li>
                    <strong>Análisis: </strong>Estas <strong> cookies</strong>{" "}
                    permiten seguir el tráfico entrante al sitio web y hacer
                    análisis del comportamiento de los usuarios en el sitio.
                    Estas cookies generan un id de usuario anónimo que se
                    utiliza para medir cuántas veces visita el sitio un usuario.
                    Asimismo, registra cuándo fue la primera y última vez que
                    visitó el sitio y cuándo se ha terminado una sesión y su
                    navegación. Ello, permite al Emisor y Holding U-Payments
                    introducir mejoras al sitio web en función del análisis de
                    los datos de uso que hacen los usuarios.
                  </li>
                </ul>
                <p>
                  <strong> Cookies de terceros: </strong> Son{" "}
                  <strong> cookies</strong> creadas por terceros. Estas{" "}
                  <strong> cookies</strong> consisten en:
                </p>
                <ul>
                  <li>
                    <strong>Cookies de redes sociales: </strong>En el Holding
                    U-Payments, utilizamos <strong> cookies</strong> de
                    Facebook, Twitter, LinkedIn, entre otras redes sociales,
                    para que el usuario pueda compartir contenidos del sitio web
                    o aplicación móvil en las citadas redes sociales; o, bien
                    para facilitar el registro en el sitio, de forma que con los
                    datos que los usuarios han facilitado a las redes sociales
                    podamos completar directamente los campos de formularios
                    según corresponda.
                  </li>
                  <li>
                    <strong>Cookies publicitarias: </strong>En el Holding
                    U-Payments, utilizamos <strong> cookies</strong> almacenadas
                    por terceras empresas que gestionan los espacios que
                    muestran publicidad del Emisor y Holding U-Payments, y a las
                    que los usuarios acceden. Estas <strong> cookies</strong>{" "}
                    permiten medir la efectividad de nuestras campañas online,
                    proporcionar información de tu interés y ofrecerte
                    contenidos publicitarios de tu preferencia. A través de las
                    políticas de <strong> cookies</strong> de estos terceros
                    puedes obtener más información sobre su funcionamiento y el
                    uso que hacen de las <strong> cookies</strong>.
                  </li>
                </ul>
                <p>
                  Si aceptas nuestras <strong> cookies</strong>, nos permites
                  mejorar el sitio web de Sabores Card y del Holding U-Payments,
                  para ofrecerte un acceso óptimo y darte un servicio más eficaz
                  y personalizado.
                </p>
                <p>
                  De todas formas, puedes deshabilitar el uso de{" "}
                  <strong> cookies</strong> cuando lo consideres oportuno a
                  través de las opciones de configuración/ajuste de tu
                  navegador. Puedes bloquear, restringir o deshabilitar el uso
                  de <strong> cookies</strong>. No obstante, te recordamos que,
                  en el caso de que decidas cambiar la configuración de las{" "}
                  <strong> cookies</strong>, el servicio prestado a través de
                  los diferentes sitios web del Holding U-Payments, podría verse
                  afectado parcial o totalmente.
                </p>
              </TermsItemCont>
              <TermsItemCont>
                <h5>VIGENCIA DE ESTA POLÍTICA</h5>
                <p>
                  La presente Política de <strong> cookies</strong>, tiene
                  duración indefinida. Sin embargo, es posible que en el futuro
                  modifiquemos la información contenida en esta política. En
                  caso de que lo hagamos, te lo notificaremos por distintas
                  vías, por ejemplo, a través de un banner, un pop-up o una
                  notificación push, de manera que puedas revisar los cambios,
                  valorarlos y, en su caso, oponerte modificando la
                  configuración de tu navegador.
                </p>
                <p>
                  Una versión actualizada de la Política siempre estará
                  publicada en la página web de Sabores Card, <a href="https://www.saborescard.cl" title="Sabores Card">www.saborescard.cl</a>.
                </p>
              </TermsItemCont>
            </TermsListCont>
          </MaxWidthWrap>
        </TermsContentCont>
        <Footer />
      </TermsWrap>
    </>
  );
};

export default Cookies;
