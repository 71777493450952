import styled from "styled-components";

export const ModalWrap = styled.div(
  ({ isOpen }) => `

  display: ${isOpen ? "flex" : "none"};
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;

  background-color: rgba(0, 0, 0, 0.75);

  width: 100%;

  min-height: 100vh;

  z-index: 4;
`
);

export const ModalContainer = styled.div`
  position: relative;

  background-color: #fff;

  width: 100%;

  height: 100vh;
  //min-height: 100%;

  //overflow-y: auto;

  @media ${(props) => props.theme.breakpoints.lg} {
    max-width: 900px;

    height: auto;
    min-height: 28rem;

    overflow-y: hidden;

    border-radius: 3rem;
  }

  @media ${(props) => props.theme.breakpoints.xl} {
    max-width: 1150px;
  }
`;

export const ModalClose = styled.div`
  display: flex;
  justify-content: flex-end;

  padding: 1rem;

  svg {
    font-size: 3.5rem;
  }
`;
