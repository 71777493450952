import React from "react";

//import { privacyData, privacyHeaderData } from "../utils/privacy";

import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";

import {
  PrivacyContentCont,
  PrivacyItemCont,
  // PrivacyItemSubCont,
  PrivacyListCont,
  // PrivacySubtitle,
  // PrivacyTableCont,
  PrivacyTitleCont,
  PrivacyWrap,
} from "../components/Privacy/PrivacyStyled";

import {
  HeroSection,
  MaxWidthWrap,
  TitleSection,
} from "../components/Reusable";

const Privacy = () => {
  return (
    <>
      <PrivacyWrap>
        <Header />

        <HeroSection isPolicy />

        <PrivacyContentCont>
          <MaxWidthWrap>
            <PrivacyTitleCont>
              <TitleSection>Política de privacidad Sabores Card</TitleSection>
            </PrivacyTitleCont>

            <PrivacyListCont>
              {/*  */}
              <PrivacyItemCont>
                <p>
                  El Emisor U-Pay Prepagos S.A. (en adelante “Emisor”), es quien
                  emite Tarjeta de Alimentación Sabores Card (en adelante
                  “Sabores Card”), toma la protección de sus datos personales
                  muy seriamente y trabajamos mejorando constantemente nuestros
                  servicios disponibles en el sitio web o en nuestras
                  aplicaciones móviles para cuidar de los datos personales que
                  nos compartes. Por ello, desarrollamos esta política para
                  informarle sobre las condiciones según las que recolectamos,
                  usamos y protegemos sus datos personales y queremos que la
                  conozcas, para familiarizarse con las categorías de datos
                  personales que están sujetas a recolección y procesamiento,
                  cómo usamos estos datos y con quiénes es probable que los
                  compartamos.
                </p>
                <p>
                  El Emisor cumple con todas las disposiciones normativas y
                  legales chilenas sobre la protección de datos personales. Por
                  favor, léala cuidadosamente y contactarnos para ejercer estos
                  derechos, o hacernos cualquier pregunta que pudiera tener en
                  relación con la protección de sus datos personales, al correo
                  electrónico{" "}
                  <a href="mailto:cumplimiento@u-payprepagos.com">
                    cumplimiento@u-payprepagos.com
                  </a>
                  .
                </p>
              </PrivacyItemCont>
              <PrivacyItemCont>
                <h3>Ambito de la política</h3>
                <p>
                  Este documento, en adelante “Política de Privacidad”, explica
                  en detalle los tipos de Datos Personales que recolectamos
                  acerca de ti cuando interactúas directa o indirectamente, con
                  alguna empresa del Holding U-Payments. También explica cómo
                  utilizamos y resguardamos tus Datos Personales, con quién los
                  compartimos, cuáles son tus derechos y la forma en qué puedes
                  ejercerlos.
                </p>
                <p>
                  Esta política puede ser modificada, complementada o
                  actualizada, en particular con el fin de cumplir con cualquier
                  desarrollo legal, normativo, de jurisprudencia o técnico que
                  pueda surgir. Sin embargo, sus datos personales siempre se
                  procesarán cumpliendo con la política vigente al momento de la
                  recolección de los datos, a menos que una prescripción legal
                  obligatoria determine lo contrario, y se debe aplicar de
                  manera retroactiva.
                </p>
                <p>
                  El Emisor se compromete a seguir protegiendo los datos
                  personales de sus usuarios, según la Ley 19.628 y la
                  Regulación General (EU) sobre Protección de Datos (en
                  adelante, la “GDPR”), que entró en vigor el día 25 de mayo de
                  2018.
                </p>
              </PrivacyItemCont>
              <PrivacyItemCont>
                <h3>DEFINICIONES</h3>
                <ul>
                  <li>
                    Para efectos de la presente Política de Privacidad, las
                    palabras que a continuación se definen tendrán el
                    significado asignado en esta sección, sea que se escriban o
                    no con inicial mayúscula, o que se encuentren en plural o
                    singular.
                  </li>
                  <li>
                    <strong>Datos personales: </strong>Significa cualquier
                    información relacionada con una persona natural identificada
                    o una que puede identificarse, directa o indirectamente, por
                    referencia, a un número de identificación o a uno o más
                    factores específicos de esta persona. Por ejemplo, Datos
                    Personales o de Contactabilidad como tu nombre, número de
                    identificación, domicilio, teléfono, correo electrónico;
                    Datos de Geolocalización; Datos Biométricos como tu huella
                    digital y rasgos faciales; Visita y Navegación en el Sitio
                    Web; Datos de Hábitos de Gastos (artículos o servicios que
                    adquieres, monto, fecha, frecuencia con que los adquieres,
                    etc.); entre otros datos.
                  </li>
                  <li>
                    <strong>Nosotros, Nos o Nuestro: </strong>Emisor U-Pay
                    Prepagos S.A. (en adelante “Emisor”), (en adelante
                    “EMISOR”), es quien emite Tarjeta de Alimentación Sabores
                    Card (en adelante “SABORES CARD”) y Holding U-Payments.
                  </li>
                  <li>
                    <strong>Usted o tú: </strong>Cualquier usuario/visitante del
                    sitio web y/o App.
                  </li>
                  <li>
                    <strong>Sitio web: </strong>El sitio web de SABORES CARD
                    disponible en la dirección https://www.saborescard.cl
                  </li>
                  <li>
                    <strong>Holding U-Payments: </strong>U-Payments Company
                    Ltda. (Holding), Sabores Card Spa (Tarjeta de Alimentación),
                    U-Pay Prepagos S.A. (Emisor), Universal Payments Spa.
                    (Software Factory), U-Pay Processing Spa (Procesador
                    Transaccional).
                  </li>
                  <li>
                    <strong>Tarjeta Habiente: </strong>Persona natural a quien
                    se le emite la Tarjeta de Alimentación para que la use en
                    compras del rubro Alimentación.
                  </li>
                  <li>
                    <strong>Autorización: </strong>Consentimiento específico,
                    inequívoco e informado del titular para llevar a cabo el
                    Tratamiento de Datos Personales
                  </li>
                </ul>
              </PrivacyItemCont>
              <PrivacyItemCont>
                <h3>AUTORIZACIÓN PARA RECOLECCIÓN</h3>
                <p>
                  Al aceptar esta Política de Privacidad autorizas al Emisor de
                  Sabores Card, de conformidad con las normas que regulan la
                  protección de la vida privada, a tratar tus Datos Personales
                  que nos proporcionas al comprar nuestros productos y/o
                  utilizar o contratar nuestros servicios, por cualquiera de
                  nuestros canales de comunicación o venta, tanto presenciales
                  como remotos. Asimismo, y siempre que no existan restricciones
                  legales para ello, autorizas al Emisor de Sabores Card a
                  compartir tus Datos con el resto del Holding de U-Payments y a
                  que estas últimas Traten, en su beneficio, tus Datos
                  Personales; y, los compartan entre sí.
                </p>
                <p>
                  Tanto Emisor de Sabores Card, como las demás empresas del
                  Holding de U-Payments, solo efectuarán el tratamiento de tus
                  Datos Personales cuando cuenten con tu Autorización o las
                  normas que regulan la protección de la vida privada les
                  reconozca otra base de legalidad para tratar tus Datos
                  Personales y, únicamente para los fines autorizados en el
                  numeral 6 más adelante.
                </p>
                <p>
                  Nos comprometemos a obtener su consentimiento y/o a permitirle
                  rechazar el uso de sus datos para ciertos fines, en cualquier
                  momento que sea necesario. Podríamos obtener tu Autorización a
                  través de cualquier medio que pueda ser objeto de consulta
                  posterior, tales como aprobación en la página web o App
                  Mobile, formularios online a través del sitio Web o correo
                  electrónico del Emisor de Sabores Card, formularios físicos, y
                  Concursos (presenciales u online como en redes sociales). En
                  cualquier caso, a usted se le informarán los fines para los
                  que sus datos se recolectan a través de diferentes formularios
                  de recolección de datos y a través del punto de la presente
                  política: “USO DE COOKIES”.
                </p>
              </PrivacyItemCont>
              <PrivacyItemCont>
                <h3>DATOS QUE RECOLECTAMOS</h3>
                <p>
                  Es posible que recolectemos los siguientes tipos de datos
                  personales:
                </p>
                <ul>
                  <li>
                    Aquellos Datos que entregues directamente en el curso de tus
                    relaciones con Emisor de Sabores Card y que sean necesarios
                    para que puedas adquirir los productos o servicios que se
                    ofrecen. Por ejemplo, recolectamos datos de identificación y
                    contacto como: nombre, cédula de identidad, domicilio,
                    teléfono, correo electrónico y datos transaccionales como:
                    tus compras, entre otros.
                  </li>
                  <li>
                    <p>Aquellos Datos que deriven de:</p>
                    <ul style={{ listStyleType: "upper-roman" }}>
                      <li>
                        La realización de operaciones relacionadas con la
                        adquisición de productos o contratación de servicios,
                        tales como, información acerca de tus hábitos de
                        compras, devoluciones, entre otras informaciones
                        económicas, salvo que su acceso esté prohibido por una
                        ley especial.
                      </li>
                      <li>
                        La utilización de servicios que ofrecen El Emisor de
                        Sabores Card, tales como servicio de atención al
                        cliente, entre otros. A través de estos servicios
                        podemos, por ejemplo, obtener datos de geolocalización y
                        conocer tu ubicación.
                      </li>
                      <li>
                        Al utilizar Sabores Card, podrías entregar datos
                        biométricos, por ejemplo, tu huella digital, rasgos
                        faciales y voz.
                      </li>
                      <li>
                        Otras fuentes de información tales como: fuentes de
                        acceso de carácter público, por ejemplo, tu fecha de
                        nacimiento disponible en el registro civil.
                      </li>
                      <li>
                        La navegación que realizas en los sitios web y
                        aplicaciones móviles, por ejemplo, el tipo de
                        dispositivo con el que te conectas, tu sistema operativo
                        y navegador web, el número identificador de tu
                        dispositivo de red (dirección MAC) o tu dirección IP,
                        entre otros.
                      </li>
                      <li>
                        La utilización de técnicas automáticas basadas en Datos
                        Personales tuyos actuales e históricos y datos
                        estadísticos. Así, por ejemplo, aplicando estas técnicas
                        podríamos acceder a datos sobre tus preferencias y
                        gustos.
                      </li>
                    </ul>
                  </li>
                </ul>
              </PrivacyItemCont>
              <PrivacyItemCont>
                <h3>USO DE TUS DATOS</h3>
                <p>
                  En virtud del consentimiento que nos entregas al aceptar esta
                  Política, estamos legitimados para hacer tratamiento de tus
                  datos para lo siguiente:
                </p>
                <ul style={{ listStyleType: "lower-alpha" }}>
                  <li>
                    <p>
                      La finalidad principal es la gestión y ejecución de los
                      contratos que el Cliente Empresa celebre para entregar
                      beneficio de Tarjeta Alimentación Sabores Card a sus
                      colaboradores (Tarjetahabientes), con o a través de la
                      gestión y la ejecución de los beneficios que entrega
                      Sabores Card. Eso significa que nos Autorizas a:
                    </p>
                  </li>
                  <ul>
                    <li>
                      Gestionar la ejecución de las Condiciones de Uso, que
                      autorice a través de la aplicación Mobile de Sabores Card,
                      responder tus consultas, reclamos, solicitudes, entre
                      otras acciones que debamos realizar para gestionar lo
                      anterior
                    </li>
                    <li>
                      Tratar tus datos personales para que puedas participar y
                      hacer uso de los beneficios de campañas promocionales, por
                      cualquiera de los canales de comunicación remotos con que
                      cuenta el Emisor de Sabores Card.
                    </li>
                    <li>
                      Contactarte con información de productos y servicios
                      prestados por el Emisor de Sabores Card. y, en general,
                      gestionar consultas, solicitudes y reclamos relacionados
                      con ellos.
                    </li>
                    <li>
                      Cumplir con objetivos de marketing, esto significa que el
                      Emisor de Sabores Card, pueda elaborar un perfil de
                      usuario (individual o agregado) aplicando o no modelos
                      predictivos y, así, mejorar los productos que se
                      comercializan o servicios que se ofrecen a través de las
                      aplicaciones Mobile y Web. Asimismo, el Emisor de Sabores
                      Card podría enviarte ofertas, promociones y anuncios
                      publicitarios de campañas promocionales, funcionalidades,
                      beneficios, sean de preferencia de nuestros usuarios en
                      general, o de ti en particular, e invitarte a participar
                      en concursos que ofrezca el Emisor de Sabores Card.
                    </li>
                    <li>
                      Analizar y elaborar estudios estadísticos sobre los
                      intereses de consumo y hábitos de compra de nuestros
                      usuarios y, en términos generales, sobre materias
                      relevantes para la preparación de estudios de mercado o
                      campañas de marketing propias del Emisor de Sabores Card.
                    </li>
                    <li>
                      Desarrollar acciones comerciales, incluidos servicios de
                      post venta, de carácter general o dirigidas personalmente
                      a ti, tendientes a mejorar tu experiencia como cliente,
                      por ejemplo: informarte sobre el estado de tu cuenta o
                      eventos relacionados a promociones o la compra de
                      productos, completar formularios, realizar encuestas de
                      satisfacción, campañas de actualización de Datos
                      Personales y realizar acciones de cobranza en el caso del
                      Cliente empresa quien contrata el beneficio de tarjeta de
                      Alimentación Sabores Card para sus colaboradores.
                    </li>
                    <li>
                      Contactarte a través de nuestros canales de comunicación,
                      por ejemplo: carta, línea telefónica, email y Chatbot
                      (sitio web o aplicaciones móviles), u otras plataformas
                      similares, al número de celular que has registrado o
                      cualquier otro dato de contacto que nos informes, con el
                      objeto de hacerte llegar información relacionada con las
                      finalidades descritas en esta sección.
                    </li>
                  </ul>
                  <li>
                    <p>
                      Tratamiento de tus Datos Personales de carácter comercial
                      para las siguientes finalidades:
                    </p>
                    <ul>
                      <li>
                        Realizar una mejor gestión de riesgo del Emisor, por
                        cumplimiento del regulador UAF (Unidad de análisis
                        Financiero).
                      </li>
                      <li>
                        Ejecutar protocolos de seguridad, y para prevenir y
                        detectar usos engañosos, fraudulentos o criminales de
                        nuestros sistemas e instalaciones, lo cual es necesario
                        para proteger los intereses legítimos del holding
                        U-Payments y de sus respectivos clientes.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <p>
                      Utilizaremos tus datos biométricos y de geolocalización
                      que recolectaremos a través de dispositivos de
                      digitalización de escritura y firma, y otros lectores
                      biométricos; escáneres de huellas digitales, cámaras de
                      seguridad, entre otros que se puedan implementar, y que
                      serán oportuna y debidamente informados; y, tecnología que
                      permite localizar tu ubicación, para las siguientes
                      finalidades:
                    </p>
                    <ul>
                      <li>
                        Para entregar información certera en la funcionalidad
                        “Estadísticas” que se encuentra en las aplicaciones
                        Mobile.
                      </li>
                      <li>
                        Prevenir y detectar usos engañosos, fraudulentos o
                        criminales de nuestros sistemas e instalaciones, lo cual
                        es necesario para proteger los intereses legítimos del
                        Emisor de Sabores Card y de sus respectivos clientes.
                      </li>
                      <li>
                        Elaborar un perfil de usuario (individual o agregado)
                        basado en tus preferencias de compra, navegación en
                        Internet, y la frecuencia y horarios con que acostumbras
                        a hacerlo.
                      </li>
                      <li>
                        Permitir la ejecución del o los contratos que celebres
                        de cualquiera de los formatos dispuestos para tal efecto
                        por el Emisor de Sabores Card.
                      </li>
                    </ul>
                  </li>
                </ul>
                <p>
                  Estos Datos sólo serán tratados mientras sean necesarios para
                  las finalidades para los cuales se otorgan, salvo aquellos
                  que, por disposiciones legales o regulatorias vigentes, sea
                  necesario mantenerlos por períodos de tiempo que van más allá
                  de este término, en cuyo caso, se mantendrán por el periodo
                  que indique esa normativa.
                </p>
              </PrivacyItemCont>
              <PrivacyItemCont>
                <h3>REVELACIÓN DE DATOS</h3>
                <p>
                  Tus Datos Personales, siempre que no exista una prohibición
                  legal para ello, serán compartidos entre el Holding
                  U-Payments.
                </p>
                <p>
                  Estas empresas podrán utilizar, en su beneficio, tus datos
                  solo para los fines autorizados en el siguiente punto de la
                  presente política: “TUS DATOS SE USAN EN”.
                </p>
                <p>
                  Para tratar tus Datos Personales podremos necesitar la ayuda
                  de terceras empresas (“Proveedores”) que presten servicios al
                  Holding U-Payments, por ejemplo, servicios de marketing,
                  logística, transporte y/o entrega de productos como Kit de
                  On-boarding, o folletería, entre otros. Estos Proveedores
                  desarrollarán esa tarea como encargados de la empresa del
                  Holding U-Payments que las contrate, actuando en nombre de
                  esta última; siguiendo sus instrucciones, sometiéndose a los
                  términos de esta autorización y adoptando medidas de seguridad
                  y confidencialidad adecuadas para el Tratamiento de tus datos
                  y el cumplimiento de las normas que regulan la protección de
                  la vida privada.
                </p>
                <p>
                  Todos estos Proveedores tendrán un contrato vigente de
                  prestación de servicios con el Holding U-Payments y solo
                  pueden tratar tus datos para efectos de dar cumplimiento con
                  el encargo específico encomendado.
                </p>
              </PrivacyItemCont>
              <PrivacyItemCont>
                <h3>RESPONSABLE FRENTE AL TRATAMIENTO DE TUS DATOS</h3>
                <p>
                  El Emisor de Sabores Card será Responsable del Tratamiento de
                  Datos que realicen todas las empresas del Holding U-Payments,
                  que se indican en los siguientes puntos: “TUS DATOS SE
                  MANTIENEN POR EL PERIODO “, y “RESGUARDOS DE PROTECCIÓN DE TUS
                  DATOS”, respecto de los Datos que comparta con ellos.{" "}
                </p>
                <p>
                  Lo anterior, sin perjuicio de la responsabilidad que a cada
                  una de ellas le quepa por cualquier incumplimiento de sus
                  obligaciones como responsables de acuerdo con las normas que
                  regulan la protección de la vida privada.{" "}
                </p>
              </PrivacyItemCont>
              <PrivacyItemCont>
                <h3>PERÍODO DE ALMACENAMIENTO DE TUS DATOS</h3>
                <p>
                  Almacenaremos sus datos solo por el tiempo que sea necesario
                  para cumplir con los fines para los que se recolectaron y
                  procesaron, salvo aquellos que por disposiciones legales o
                  regulatorias deban ser mantenidos por períodos de tiempo que
                  van más allá de este término, en cuyo caso se mantendrán por
                  el periodo que indique esa normativa.
                </p>
              </PrivacyItemCont>
              <PrivacyItemCont>
                <h3>RESGUARDOS DE PROTECCIÓN DE TUS DATOS</h3>
                <p>
                  El Emisor de Sabores Card, mantiene las salvaguardias físicas,
                  electrónicas y de procedimiento que exige la normativa legal
                  en relación con la recolección, almacenamiento y transferencia
                  de tus Datos Personales, y evalúa su efectividad
                  periódicamente.
                </p>
                <p>
                  El objetivo de estas salvaguardias es evitar el acceso no
                  autorizado o ilegal, o la pérdida accidental, destrucción u
                  ocurrencia de daños a tus Datos Personales. Por ello, cuando
                  El Emisor recolecta datos a través de su sitio o aplicación
                  móvil, lo hace a través de un servidor seguro que cuenta con
                  programas de protección. Además, los procedimientos de
                  seguridad que el Emisor exige que, en ocasiones, se te
                  solicite una prueba de identidad antes de poder entregarte
                  información de tus Datos Personales. El Emisor utiliza,
                  además, sistemas que codifican la información evitando usos
                  fraudulentos. Si bien no es posible garantizar lo anterior,
                  estos sistemas han probado ser efectivos en el manejo de
                  información reservada impidiendo el acceso de amenazas
                  externas (por ejemplo, hackers). Sin perjuicio de lo antes
                  señalado, te recomendamos{" "}
                  <strong>no enviar datos de tarjetas</strong> sin cifrar o
                  desde sitios o equipos públicos o no seguros.{" "}
                  <strong>
                    Ten en cuenta que eres el único responsable de la protección
                    contra el acceso no autorizado a tu contraseña y computadora
                  </strong>
                  .
                </p>
                <p>
                  Finalmente, es importante que tengas en cuenta que las páginas
                  web de Sabores Card contiene algunos enlaces informativos a
                  otros sitios web correspondientes a entidades u organizaciones
                  ajenas al Holding U-Payments, por ende, este último, no se
                  hará responsable con relación al registro de datos personales
                  que puedas hacer en dichos sitios web.
                </p>
              </PrivacyItemCont>
              <PrivacyItemCont>
                <h3>DATOS PROPORCIONADOS DESDE O DE TERCEROS</h3>
                <p>
                  Algunos de los productos o servicios que ofrece el Holding
                  U-Payments, implican que Tratemos Datos Personales de un
                  tercero que tú, como usuario o como cliente, nos facilitas,
                  por ejemplo, solicitas el envío de información o promociones a
                  un tercero, entre otros. Si nos proporcionas Datos Personales
                  de terceros, confirmas que les has informado de las
                  finalidades y de la forma en que necesitamos Tratar sus Datos
                  Personales.
                </p>
                <p>
                  Si un tercero nos ha proporcionado tus Datos Personales o tú
                  los has proporcionado como resultado de una función o servicio
                  solicitado por uno de nuestros clientes, te informamos que los
                  utilizaremos para gestionar la función o servicio en cuestión
                  y conforme a las finalidades enumeradas en esta Política de
                  Privacidad.
                </p>
              </PrivacyItemCont>
              <PrivacyItemCont>
                <h3>USO DE COOKIES Y TECNOLOGÍAS SIMILARES</h3>
                <p>
                  Al aceptar esta Política de Privacidad, además, autorizas al
                  Emisor de Sabores Card, el uso de cookies y tecnologías
                  similares para personalizar y mejorar tu experiencia de
                  cliente y para mostrarte publicidad online relevante en los
                  términos que se indican a continuación.
                </p>
                <p>
                  Durante tu visita a los sitios web y aplicaciones móviles,
                  permaneces anónimo, a menos que tengas una cuenta en alguno de
                  los sitios web o aplicaciones móviles del Emisor de Sabores
                  Card, y/o Holding U-Payments, en cuyo caso podríamos
                  identificarte.
                </p>
                <p>
                  Las cookies son pequeños archivos de texto que contienen un
                  identificador único que se almacena en el computador o aparato
                  móvil a través del cual accedes a los sitios web o apps de del
                  Emisor de Sabores Card, y o Holding U-Payments de manera que
                  aquellos pueden ser reconocidos cada vez que usas estos sitios
                  o aplicaciones móviles, este archivo contiene información como
                  el nombre de dominio, el proveedor del acceso a internet y el
                  sistema operativo, así como la fecha y la hora en que el
                  usuario accedió al sitio.
                </p>
                <p>
                  Con excepción de las cookies que son necesarias para el
                  funcionamiento del sitio o aplicaciones móviles, puedes elegir
                  deshabilitar en cualquier momento algunas o todas las cookies
                  que utilizan El Emisor. Sin embargo, esto podría restringir tu
                  uso del sitio web y/o aplicaciones móviles, ya que ciertas
                  funcionalidades podrían no funcionar con cookies desactivadas
                  y limitar tu experiencia en los mismos.
                </p>
                <p>
                  Puedes obtener más información en nuestra Política de Cookies,
                  sobre para qué las usamos, qué tipo de cookies usamos, esto se
                  encuentra en el sitio Web de Sabores Card{" "}
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.saborescard.cl"
                  >
                    www.saborescard.cl
                  </a>
                  .
                </p>
              </PrivacyItemCont>
              <PrivacyItemCont>
                <h3>TUS DERECHOS SOBRE TUS DATOS </h3>
                <p>
                  Puedes revocar en cualquier momento la autorización que
                  entregas para tratar tus Datos Personales, en cuyo caso es
                  posible que no puedas utilizar alguno o todos los servicios o
                  beneficios que ofrece El Emisor de Sabores Card o del Holding
                  de U-Payments.
                </p>
                <p>
                  Es tu derecho pedirnos información sobre tus Datos Personales,
                  que hayamos recolectado y ejercer tus derechos de
                  rectificación, cancelación, oposición y todos los demás
                  derechos que confieren las normas que regulan la protección de
                  la vida privada. Para ejercer estos derechos sólo deberás
                  enviar un correo electrónico a cumplimiento@u-payprepagos.com.
                  Del mismo modo, siempre puedes solicitar la suspensión de
                  comunicaciones promocionales o publicitarias, de conformidad a
                  lo dispuesto en el artículo 28 B de la Ley N°19.496 sobre
                  Protección de los Derechos de los Consumidores.
                </p>
              </PrivacyItemCont>
              <PrivacyItemCont>
                <h3>INFORMACIÓN PERSONAL Y MENORES DE EDAD</h3>
                <p>
                  Solo personas adultas que tengan la capacidad de celebrar un
                  contrato, según la legislación del país donde se encuentran,
                  pueden usar el sitio web.
                </p>
                <p>
                  Los menores que no tengan la capacidad legal de contratar bajo
                  la legislación chilena deben obtener un consentimiento de sus
                  tutores legales antes de enviar sus datos personales en el
                  sitio web.
                </p>
              </PrivacyItemCont>
              <PrivacyItemCont>
                <h3>VIGENCIA DE ESTA POLÍTICA</h3>
                <p>
                  La presente Política de Privacidad tiene duración indefinida.
                  Sin embargo, es posible que en el futuro modifiquemos la
                  información contenida en esta política de privacidad. En caso
                  de que lo hagamos, te lo notificaremos por distintas vías, por
                  ejemplo, a través de un banner, un pop-up o una notificación
                  push, o incluso te lo comunicaremos a tu dirección de correo
                  electrónico cuando el cambio en cuestión sea significativo, de
                  manera que puedas revisar los cambios, valorarlos y, en su
                  caso, oponerte revocando tu consentimiento para tratar tus
                  Datos Personales.
                </p>
                <p>
                  Una versión actualizada de la Política de Privacidad siempre
                  estará publicada en la página web Sabores Card y aplicación
                  móvil de Sabores Card.
                </p>
              </PrivacyItemCont>
            </PrivacyListCont>
          </MaxWidthWrap>
        </PrivacyContentCont>
        <Footer />
      </PrivacyWrap>
    </>
  );
};

export default Privacy;
