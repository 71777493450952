import React from "react";

import useWindowDimensions from "../hooks/useDimensions";


import Header from "../components/Header/Header";
import { TitleSection } from "../components/Reusable";
import SimulatorForm from "../components/Simulator/SimulatorForm";
import Footer from "../components/Footer/Footer";

import {
  SimuladorFormCont,
  SimuladorTitleCont,
  SimuladorWrap,
} from "../components/Simulator/SimulatorStyled";

const Simulador = () => {

  const { width } = useWindowDimensions();


  return (
    <SimuladorWrap>
      <Header />

      <SimuladorFormCont>
        <SimuladorTitleCont>
          <TitleSection>
            {width < 1023 ? (
              <>
                Simulador de <span>ahorro</span> <br/> tributario
                <span> Sabores Card</span>
              </>
            ) : (
              <>
                Simulador de <span>ahorro</span> <br /> tributario{" "}
                <span> Sabores Card</span>
              </>
            )}
          </TitleSection>
        </SimuladorTitleCont>

        <SimulatorForm />
      </SimuladorFormCont>

      <Footer />
    </SimuladorWrap>
  );
};

export default Simulador;
