export const faqData = [
  {
    title: "¿Qué es Sabores Card?",
    listContent: [
      "Es una tarjeta de pago con provisión de fondos que permite a las empresas entregar el beneficio de alimentación a sus colaboradores a través de la tarjeta de prepago Sabores Card con Marca Mastercard.",
      "Esta tarjeta es nominada en formato digital que cuenta con una aplicación Mobile para Android que podrás descargar directamente en la tienda Google Play.",
      "Esta tarjeta será cargada mensualmente directamente desde la empresa a sus colaboradores y a su vez los colaboradores que posean la Tarjeta de Alimentación sabores Card podrán utilizarla para realizar compras en comercios digitales del rubro de alimentación.",
      "Esta tarjeta no permite retiros en cajeros automáticos ni otro tipo de recargas por ser una tarjeta de Alimentación.",
    ],
    content: null,
  },
  {
    title: "¿Dónde puedo utilizar mi tarjeta Sabores Card?",
    content:
      "Puedes utilizar tu Tarjeta Sabores Card, en cualquier comercio digital del rubro de alimentación que acepten tarjetas Bancarias.",
  },
  {
    title: "¿Qué beneficios me otorga la Tarjeta como empresa?",
    content:
      "Dentro de los beneficios que otorga la tarjeta, puedes ahorrar hasta un 38.7% de ahorro tributario mensual, además este producto ofrece 100% de control al empleador.",
  },
  {
    title: "¿Qué significa que está asociada a Mastercard?",
    content:
      "Es una tarjeta Mastercard prepago que puedes usarla en todos los comercios digitales adheridos al rubro de alimentación.",
  },
  {
    title: "¿Qué beneficio tiene Sabores Card?",
    content:
      "Posibilidad de efectuar las compras por los montos exactos, sin preocuparse por el vuelto, además podrás comprar en cualquier comercio del rubro de alimentación que este adherido a MasterCard.",
  },
  {
    title: "¿En qué tipo de comercio puedo utilizar mi tarjeta Sabores Card?",
    content:
      "En comercios e-commerce, desde supermercados hasta pequeños locales, restaurantes, cafeterías, panaderías, abastos, minimarket y mucho más.",
  },
  {
    title: "¿Qué no puedo comprar? ",
    content:
      "No puedes comprar, productos de categorías distintas a alimentos y bebestibles; tales como: artículos de limpieza, cuidado personal, tabaquería y otros. También queda excluida la compra de bebidas alcohólicas.",
  },
  {
    title: "¿Cómo funciona la APP de Sabores Card?",
    // listContent: [
    //   "Descarga la aplicación desde tu celular la cual está disponible para dispositivos Android (Google Play), ingresa con tu usuario y contraseña donde podrás visualizar tu saldo, últimos movimientos, clave de autorización y clave dinámica para generar compras online cuando el comercio la requiera.",
      
    // ],
    content: "Descarga la aplicación desde tu celular la cual está disponible para dispositivos Android (Google Play), ingresa con tu usuario y contraseña donde podrás visualizar tu saldo, últimos movimientos, clave de autorización y clave dinámica para generar compras online cuando el comercio la requiera.",
  },
  {
    title: "¿Hay un costo adicional por realizar compras?",
    content:
      "No, no hay costos adicionales. Solo debes pagar el monto del pedido al comercio.",
  },
  {
    title:
      "Soy una empresa y quiero entregar el beneficio de alimentación ¿Qué debo hacer? ",
    content:
      "Completa nuestro formulario de contacto y un ejecutivo te contactará.",
  },
];
