import { ThemeProvider } from "styled-components";

import theme from "./assets/themes/default";

import GlobalStyles from "./assets/styles/GlobalStyles";

import { Container } from "./assets/styles/Container";

import { HelmetSEO } from "./components/Helmet/Helmet";

import RoutesApp from "./RoutesApp";

import MainContextProvider from "./context/useContext";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyles />
        
        <HelmetSEO />

        <Container>
          <MainContextProvider>
            <RoutesApp />
          </MainContextProvider>
        </Container>
      </>
    </ThemeProvider>
  );
}

export default App;
