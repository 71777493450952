import React from "react";

import {
  ContactFormWrap,
  ContactTitleCont,
  ContactWrap,
} from "../components/Contact/ContactStyled";

import Header from "../components/Header/Header";
import { HeroSection, TitleSection } from "../components/Reusable";
import FormContact from "../components/Forms/FormContact";
import Footer from "../components/Footer/Footer";
import { BorderGradient } from "../components/GradientComponents/Gradient";

const Contact = () => {
  return (
    <ContactWrap>
      <Header />

      <HeroSection isContact />

      <ContactFormWrap>
        <ContactTitleCont>
          <TitleSection>Formulario de contacto</TitleSection>
        </ContactTitleCont>

        <FormContact />
      </ContactFormWrap>

      <BorderGradient />

      <Footer />
    </ContactWrap>
  );
};

export default Contact;
