import React, { useEffect, useRef } from "react";

import { HashLink as Link } from "react-router-hash-link";

import { motion } from "framer-motion";

import {
  HeroWrap,
  HeroContentContainer,
  HeroTextContainer,
  HeroTitle,
  MainTitle,
  MainIcon,
  HeroDescription,
  HeroImageContainer,
  HeroCTAContainer,
} from "./HomeHeroStyled";

import useWindowDimensions from "../../../hooks/useDimensions";
import { useScrollAnimation } from "../../../hooks/useScrollAnimation";
import {
  fromDown,
  fromRightAnimation,
  fromUp,
  fromUpTwo,
} from "../../../utils/animations";

import { Button } from "../../Button/ButtonStyled";

import { TextGradient } from "../../GradientComponents/Gradient";

import chefHat from "../../../assets/img/chefHat.svg";
import heroCardPhone from "../../../assets/img/heroCardPhone.png";

const HomeHero = (props) => {
  const { sectionID } = props;

  const { width } = useWindowDimensions();

  const [element, controls] = useScrollAnimation();

  useEffect(() => {
    return () => {};
  }, []);

  const cardRef = useRef();

  return (
    <>
      <HeroWrap id={sectionID} ref={element}>
        <HeroContentContainer>
          <HeroTextContainer>
            <HeroTitle
              as={motion.div}
              animate={controls}
              initial="hidden"
              variants={fromUp}
            >
              <MainIcon src={chefHat} />
              <MainTitle>Sabores Card</MainTitle>
            </HeroTitle>

            <HeroDescription
              as={motion.p}
              animate={controls}
              initial="hidden"
              variants={fromDown}
            >
              {width < 1023 ? (
                <>
                  La <TextGradient>REVOLUCIÓN</TextGradient> en tarjetas de{" "}
                  <br />
                  alimentación y beneficios para <br />
                  <span> colaboradores</span>
                </>
              ) : (
                <>
                  La <TextGradient>REVOLUCIÓN</TextGradient> en <br /> tarjetas
                  de alimentación <br /> y beneficios para <br />
                  <span> colaboradores</span>
                </>
              )}
            </HeroDescription>
          </HeroTextContainer>

          <HeroImageContainer
            as={motion.div}
            animate={controls}
            initial="hidden"
            variants={fromUpTwo}
          >
            <img src={heroCardPhone} alt="" />

            {/* {width > 1023 && (
              <HeroImageApp
                src={app}
                as={motion.img}
                animate={controls}
                initial="hidden"
                variants={fromRightAnimation}
              />
            )}

            <motion.div
              animate={controls}
              initial="hidden"
              variants={fromUpTwo}
            >
              <HeroImageCard src={card} ref={cardRef} />
            </motion.div> */}
          </HeroImageContainer>
        </HeroContentContainer>

        <HeroCTAContainer>
          <Link to="/#Form">
            <motion.div
              as={motion.button}
              animate={controls}
              initial="hidden"
              variants={fromRightAnimation}
            >
              <Button primary>Solicitar Sabores Card</Button>
            </motion.div>
          </Link>
        </HeroCTAContainer>
      </HeroWrap>
    </>
  );
};

export default HomeHero;
