import React from "react";

import { motion } from "framer-motion";

import { MaxWidthWrap, TitleSection } from "../../Reusable";
import {
  AllianceLogoCont,
  AllianceParagraphCont,
  AllianceTitleCont,
  AllianceWrap,
} from "./HomeAllianceStyled";

import useWindowDimensions from "../../../hooks/useDimensions";
import { useScrollAnimation } from "../../../hooks/useScrollAnimation";

import UPayments from "../../../assets/img/u-paymentsDark.svg";
import Mastercard from "../../../assets/img/mastercard.svg";
import {
  fromDown,
  fromLeftAnimation,
  fromRightAnimation,
  fromUp,
} from "../../../utils/animations";

const HomeAlliance = (props) => {
  const { sectionID } = props;

  const { width } = useWindowDimensions();

  const [element, controls] = useScrollAnimation();

  return (
    <>
      <AllianceWrap id={sectionID} ref={element}>
        <MaxWidthWrap>
          <AllianceTitleCont
            as={motion.div}
            animate={controls}
            initial="hidden"
            variants={fromUp}
          >
            <TitleSection weightNormal>
              {width < 1023 ? (
                <>
                  <span>Sabores Card</span>, un <br /> producto de la alianza{" "}
                  <br />
                  <strong> U-Payments</strong> y <strong> Mastercard®</strong>
                </>
              ) : (
                <>
                  <span>Sabores Card</span>, un producto de la alianza <br />
                  <strong> U-Payments</strong> y <strong> Mastercard®</strong>
                </>
              )}
            </TitleSection>
          </AllianceTitleCont>

          <AllianceLogoCont
            as={motion.div}
            animate={controls}
            initial="hidden"
            variants={fromDown}
          >
            <img src={UPayments} alt="" />
            <img src={Mastercard} alt="" />
          </AllianceLogoCont>

          <AllianceParagraphCont>
            <motion.p
              animate={controls}
              initial="hidden"
              variants={fromLeftAnimation}
            >
              <strong>U-Payments</strong> y{" "}
              <strong>
                <a
                  href="https://www.mastercard.cl/es-cl.html"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  Mastercard
                </a>
              </strong>
              , se han unido para desarrollar el mejor producto que entrega a
              los colaboradores de empresas, beneficios de alimentación y
              próximante beneficios en transporte, sala cuna entre otros.
            </motion.p>

            <motion.p
              animate={controls}
              initial="hidden"
              variants={fromRightAnimation}
            >
              De esta alianza, se espera poder entregar un servicio seguro a
              través de Tarjeta Alimentación <strong>Sabores Card</strong>, que
              permita realizar compras en cualquier restaurante a nivel
              nacional, podrás gastar donde quieras, incluso comprando en línea
              en&nbsp;
              <a
                href="https://www.ubereats.com/cl"
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                Uber Eats,
              </a>{" "}
              <a
                href="https://www.rappi.cl/"
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                Rappi,
              </a>{" "}
              <a
                href="https://www.getjusto.com/"
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                Justo,
              </a>{" "}
              <a
                href="https://www.pedidosya.cl/"
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                Pedidos ya,{" "}
              </a>
              <a
                href="https://cornershopapp.com/es-cl/"
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                CornerShop,
              </a>{" "}
              entre otros.
            </motion.p>
          </AllianceParagraphCont>
        </MaxWidthWrap>
      </AllianceWrap>
    </>
  );
};

export default HomeAlliance;
