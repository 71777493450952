import React from "react";

import { SCWrap } from "../components/SaboresCard/SCStyled";

import useWindowDimensions from "../hooks/useDimensions";

import Header from "../components/Header/Header";
import SCHero from "../components/SaboresCard/SCHero";
import SCPhysical from "../components/SaboresCard/SCPhysical";
import SCDigital from "../components/SaboresCard/SCDigital";
import { SimuladorFormCont, SimuladorTitleCont } from "../components/Simulator/SimulatorStyled";
import SimulatorForm from "../components/Simulator/SimulatorForm";
import { BorderGradient } from "../components/GradientComponents/Gradient";
import Footer from "../components/Footer/Footer";

import { TitleSection } from "../components/Reusable";


const SaboresCard = () => {

  const { width } = useWindowDimensions();

  return (
    <>
      <SCWrap>
        <Header />

        <SCHero sectionID="SCHero" />

        <BorderGradient />

        <SCPhysical />

        <SCDigital sectionID="SCDigital" />

        <SimuladorFormCont>
          <SimuladorTitleCont>
            <TitleSection>
              {width < 1023 ? (
                <>
                  Simulador de <span>ahorro</span> <br /> tributario
                  <span> Sabores Card</span>
                </>
              ) : (
                <>
                  Simulador de <span>ahorro</span> <br /> tributario
                  <span> Sabores Card</span>
                </>
              )}
            </TitleSection>
          </SimuladorTitleCont>

          <SimulatorForm />
        </SimuladorFormCont>

        <BorderGradient />

        <Footer />
      </SCWrap>
    </>
  );
};

export default SaboresCard;
