import React from "react";
import {
  FooterMobileCont,
  FooterMobileRowOne,
  FooterMobileRowTwo,
  FooterMobileRowThree,
  FooterMobileRowFour,
  FooterMobileRowSix,
  FooterMobileRowSeven,
  FooterMobileRowEight,
  FooterMailLink,
  FooterMobileRowNine,
} from "./FooterStyled";

import { Logo } from "../Reusable";
import Accordion from "../Accordion/Accordion";
import NavLinksFooter from "../NavLinks/NavLinksFooter";
import SocialMedia from "../SocialMedia/SocialMedia";

//
import LogoSC from "../../assets/img/LogoSaboresLight.svg";
import UProcessing from "../../assets/img/u-pay-processing1.png";
import UProcessing2 from "../../assets/img/u-pay-processing2.png";
import UPayments from "../../assets/img/u-payments.svg";
import Mastercard from "../../assets/img/mastercard.svg";
import Award from "../../assets/img/premio-excelencia.svg";
import Pci from "../../assets/img/pci.svg";
import PciCertified from "../../assets/img/pci-certified.svg";
import MicrosoftPartner from "../../assets/img/microsoft-partner.svg";
import BestCompanies from "../../assets/img/best-companies.svg";
import Seglan from "../../assets/img/seglan.svg";
import Oracle from "../../assets/img/oracle.svg";
import Cisco from "../../assets/img/cisco.svg";

const FooterMobile = (props) => {
  const { handleMailTo } = props;

  return (
    <>
      <FooterMobileCont>
        <FooterMobileRowOne>
          <div>
            <Logo src={LogoSC} />
          </div>
          <div>
            <img src={UProcessing2} alt="logo u-pay processing two" />
            <img src={UProcessing} alt="logo u-pay processing" />
            <a
              target="_blank"
              href="https://www.u-payments.com/"
              rel="noopener noreferrer"
            >
              <img src={UPayments} alt="logo u-payments" />
            </a>

            <a
              target="_blank"
              href="https://www.mastercard.cl/es-cl.html"
              rel="noopener noreferrer"
            >
              <img src={Mastercard} alt="logo mastercard" />
            </a>
          </div>
        </FooterMobileRowOne>

        <FooterMobileRowTwo>
          <Accordion title={"Home"}>
            <NavLinksFooter isHome />
          </Accordion>

          <Accordion title={"Menú"}>
            <NavLinksFooter isMenu />
          </Accordion>

          <Accordion title={"Legales"}>
            <NavLinksFooter isLegal />
          </Accordion>
        </FooterMobileRowTwo>

        <FooterMobileRowThree>
          <h5>
            Premios y certificaciones <br />
            U-Payments
          </h5>
        </FooterMobileRowThree>

        <FooterMobileRowFour>
          <ul>
            <li>
              <img src={Award} alt="premio" />
            </li>
            <li>
              <img src={BestCompanies} alt="premio" />
            </li>
            <li>
              <img src={Pci} alt="pci" />
            </li>
            <li>
              <img src={PciCertified} alt="pci certified" />
            </li>
            <li>
              <img src={Seglan} alt="seglan" />
            </li>
            <li>
              <img src={Oracle} alt="oracle" />
            </li>
            <li>
              <img src={MicrosoftPartner} alt="premio" />
            </li>
            <li>
              <img src={Cisco} alt="cisco" />
            </li>
          </ul>
        </FooterMobileRowFour>

        {/* <FooterMobileRowFive>
          <ul>
            <li>
              <img src={} alt="pci" />
            </li>
            <li>
              <img src={MicrosoftPartner} alt="premio" />
            </li>
          </ul>
        </FooterMobileRowFive> */}

        <FooterMobileRowSix>
          <h4>&copy; {new Date().getFullYear()} Sabores Card</h4>
        </FooterMobileRowSix>

        <FooterMobileRowSeven>
          <h5>Síguenos en</h5>
          <SocialMedia />
        </FooterMobileRowSeven>

        <FooterMobileRowEight>
          <FooterMailLink to="#" onClick={handleMailTo}>
            contacto@saborescard.cl
          </FooterMailLink>
        </FooterMobileRowEight>

        <FooterMobileRowNine>
          <a className="footer__phone-link" href="tel:600 3600 405">600 3600 405</a>
        </FooterMobileRowNine>
      </FooterMobileCont>
    </>
  );
};

export default FooterMobile;
