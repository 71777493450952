import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  margin: auto;

  overflow: hidden;

  @media ${(props) => props.theme.breakpoints.sm} {
  }

  @media ${(props) => props.theme.breakpoints.lg} {
  }
`;
