import React, { useState, useRef } from "react";

import { useNavigate } from "react-router-dom";

import { motion } from "framer-motion";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import { AiOutlinePlusCircle } from "react-icons/ai";
import { FaRegTimesCircle } from "react-icons/fa";

import useWindowDimensions from "../../../hooks/useDimensions";
import useModal from "../../../hooks/useModal";
import { useScrollAnimation } from "../../../hooks/useScrollAnimation";

import { TextGradient } from "../../GradientComponents/Gradient";
import { TitleSection } from "../../Reusable";
import { Button } from "../../Button/ButtonStyled";
import Modal from "../../Modal/Modal";

import HomeWhyModal from "./HomeWhyModal";

import {
  WhyWrap,
  WhyItemsContainer,
  WhyItemContainer,
  WhyItemBox,
  WhyCTAContainer,
  WhyTitleCont,
} from "./HomeWhyStyled";

import SimulatorForm from "../../Simulator/SimulatorForm";

import workerImg from "../../../assets/img/workerWhite.svg";
import shopImg from "../../../assets/img/shop.svg";
import buildingImg from "../../../assets/img/buildingWhite.svg";
import {
  fromDown,
  parentContainerAnimation,
  childrenAnimation,
} from "../../../utils/animations";

const HomeWhy = (props) => {
  const { sectionID } = props;

  //
  const [showSimulator, setShowSimulator] = useState(false);

  // Custom hooks
  const [isOpenWorker, openModalWorker, closeModalWorker] = useModal(false);
  const [isOpenShop, openModalShop, closeModalShop] = useModal(false);
  const [isOpenBusiness, openModalBusiness, closeModalBusiness] =
    useModal(false);

  const navigate = useNavigate();

  const { width } = useWindowDimensions();

  const [element, controls] = useScrollAnimation();

  const vidRef = useRef(null);

  const handlePlayVideo = () => {
    if (width > 1024) {
      vidRef.current.play();
    }
  };

  const handleRoute = () => {
    navigate("/simulador");
  };

  const handleOpenSimulator = () => {
    setShowSimulator(!showSimulator);
  };

  return (
    <>
      <WhyWrap id={sectionID} ref={element}>
        <WhyTitleCont
          as={motion.div}
          animate={controls}
          initial="hidden"
          variants={fromDown}
        >
          <TitleSection>
            ¿Por qué <TextGradient>Sabores card?</TextGradient>
          </TitleSection>
        </WhyTitleCont>

        <WhyItemsContainer>
          {width < 1023 ? (
            <Swiper
              spaceBetween={30}
              slidesPerView={"auto"}
              //onSlideChange={() => console.log("slide change")}
              //onSwiper={(swiper) => console.log(swiper)}
            >
              {/* Item 1 */}
              <SwiperSlide>
                <WhyItemContainer worker>
                  <WhyItemBox worker>
                    <img src={workerImg} alt="colaboradores" />
                  </WhyItemBox>

                  <p>colaboradores</p>

                  <AiOutlinePlusCircle onClick={openModalWorker} />
                </WhyItemContainer>
              </SwiperSlide>

              {/* Item 2 */}
              <SwiperSlide>
                <WhyItemContainer shop>
                  <WhyItemBox shop>
                    <img src={shopImg} alt="comercios" />
                  </WhyItemBox>

                  <p>Comercios</p>

                  <AiOutlinePlusCircle onClick={openModalShop} />
                </WhyItemContainer>
              </SwiperSlide>

              {/* Item 3 */}
              <SwiperSlide>
                <WhyItemContainer>
                  <WhyItemBox>
                    <img src={buildingImg} alt="empresas" />
                  </WhyItemBox>

                  <p>Empresas</p>

                  <AiOutlinePlusCircle onClick={openModalBusiness} />
                </WhyItemContainer>
              </SwiperSlide>
            </Swiper>
          ) : (
            <motion.div
              animate={controls}
              initial="hidden"
              variants={parentContainerAnimation}
            >
              {/* Item 1 */}
              <motion.div variants={childrenAnimation}>
                <WhyItemContainer worker>
                  <WhyItemBox worker>
                    <img src={workerImg} alt="Colaboradores" />
                  </WhyItemBox>

                  <p>Colaboradores</p>

                  <AiOutlinePlusCircle onClick={openModalWorker} />
                </WhyItemContainer>
              </motion.div>

              {/* Item 2 */}
              <motion.div variants={childrenAnimation}>
                <WhyItemContainer shop>
                  <WhyItemBox shop>
                    <img src={shopImg} alt="comercios" />
                  </WhyItemBox>

                  <p>Comercios</p>

                  <AiOutlinePlusCircle onClick={openModalShop} />
                </WhyItemContainer>
              </motion.div>

              {/* Item 3 */}
              <motion.div variants={childrenAnimation}>
                <WhyItemContainer>
                  <WhyItemBox>
                    <img src={buildingImg} alt="empresas" />
                  </WhyItemBox>

                  <p>Empresas</p>

                  <AiOutlinePlusCircle onClick={openModalBusiness} />
                </WhyItemContainer>
              </motion.div>
            </motion.div>
          )}
        </WhyItemsContainer>

        <WhyCTAContainer
          as={motion.div}
          animate={controls}
          initial="hidden"
          variants={fromDown}
        >
          {width < 1023 ? (
            <Button primary onClick={handleRoute}>
              Simular ahorro tributario
            </Button>
          ) : (
            <>
              <p>
                Simulador de <span>ahorro tributario</span> para empresas
              </p>

              {showSimulator ? (
                <>
                  <FaRegTimesCircle onClick={handleOpenSimulator} />
                </>
              ) : (
                <>
                  <AiOutlinePlusCircle onClick={handleOpenSimulator} />
                </>
              )}
            </>
          )}
        </WhyCTAContainer>

        {showSimulator && (
          <>
            <SimulatorForm />
          </>
        )}
      </WhyWrap>

      <Modal
        isOpen={
          isOpenWorker ? isOpenWorker : isOpenShop ? isOpenShop : isOpenBusiness
        }
        closeModal={
          closeModalWorker
            ? closeModalWorker
            : closeModalShop
            ? closeModalShop
            : closeModalBusiness
        }
      >
        {isOpenWorker && (
          <>
            <HomeWhyModal
              isWorker
              closeModal={closeModalWorker}
              vidRef={vidRef}
              handlePlayVideo={handlePlayVideo}
            />
          </>
        )}

        {isOpenShop && (
          <>
            <HomeWhyModal
              isShop
              closeModal={closeModalShop}
              vidRef={vidRef}
              handlePlayVideo={handlePlayVideo}
            />
          </>
        )}

        {isOpenBusiness && (
          <>
            <HomeWhyModal
              isBusiness
              closeModal={closeModalBusiness}
              vidRef={vidRef}
              handlePlayVideo={handlePlayVideo}
            />
          </>
        )}
      </Modal>
    </>
  );
};

export default HomeWhy;
