import styled from "styled-components";

import bgFormDesk from "../../../assets/img/bg-home-form.jpg";

export const FormWrap = styled.section`
  background: ${(props) => props.theme.colors.light};

  padding: ${(props) => props.theme.spacing.sectionPaddingY}
    ${(props) => props.theme.spacing.sectionPaddingX};

  & > div {
    display: grid;

    @media ${(props) => props.theme.breakpoints.lg} {
      place-items: center;
    }
  }

  @media ${(props) => props.theme.breakpoints.lg} {
    place-items: center;

    background-image: url(${bgFormDesk});

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    // FORM FIX FOR SAFARI
    .formContainerSafari {
      width: 100%;

      background: #fff;

      min-width: 58rem;
      min-height: 80rem;

      padding: 1rem 3rem;

      transition: 1s ease-in-out;

      border-radius: 10px 10px 30px 30px;

      & > div:first-child {
        text-align: center;
      }

      & h3 {
        font-size: 3rem;
        color: #e71d73;

        margin: 3rem 0;
      }
    }
  }
`;

export const FormTitleContainer = styled.div`
  text-align: center;

  margin-bottom: 2.4rem;

  & > h3 {
    color: #e71d73;
  }
`;

export const FormContainer = styled.div(
  ({ theme, showFormContact }) => `
    width: 100%;
  
    background: #fff;
  
    @media ${theme.breakpoints.lg} {
      position: relative;

      display: flex;
      flex-direction: column;
      align-items: center;
  
      min-width: 58rem;
      min-height: 80rem;
  
      padding: 1rem 3rem;
  
      transform: ${showFormContact ? "rotateY(180deg)" : "rotateY(0)"};
      -webkit-transform: ${showFormContact ? "rotateY(180deg)" : "rotateY(0)"};

      transition: 1s ease-in-out;

      transform-style: preserve-3d;
      -webkit-transform-style: preserve-3d;
  
      border-radius: 10px 10px 30px 30px;

      // 
      .formCard {
        transition: all 0.8s ease-in-out;
  
        /* We don't want to see the back part of the element. */
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        backface-visibility: hidden;
    
        border-radius: 3px;

        & > div:first-child {
          text-align: center;

          margin: 3rem 0;

          & h3 {
            font-size: 3rem;
            color: #e71d73;
          }
        }

      }
    }
  `
);

export const FormCardFront = styled.div(
  () => `

  width: 100%;
  height: 100%; 

  transform-style: inherit;
  -webkit-transform-style: inherit;
`
);

export const FormCardBack = styled.div(
  ({ theme, showFormContact }) => `

  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  width: 90%;
  height: 100%;

  margin: 0 auto;  
  
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  
`
);

// FORM TAB
export const FormTabsCont = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2rem;

  width: 58rem;

  margin-bottom: 2rem;
`;

export const FormTabConversion = styled.div(
  ({ theme, showFormConversion }) => `

  flex: 1;

  background: ${
    showFormConversion ? theme.colors.primary1 : theme.colors.light
  };

  text-align: center;

  padding: 2rem 4rem;

  border-radius: 30px 10px 10px 10px;

  & > h4 {
    color: ${showFormConversion ? theme.colors.light : theme.colors.primary1};
  }

  cursor: pointer;
`
);

export const FormTabContact = styled.div(
  ({ theme, showFormContact }) => `

  flex: 1;

  background: ${showFormContact ? theme.colors.primary1 : theme.colors.light};
  
  text-align: center;

  padding: 2rem 4rem;  

  border-radius: 10px 30px 10px 10px;

  & > h4 {
    color: ${showFormContact ? theme.colors.light : theme.colors.primary1};
  }

  cursor: pointer;
`
);
