import styled from "styled-components";

import { Link } from "react-router-dom";

export const FooterWrap = styled.footer`
  display: grid;

  background: ${(props) => props.theme.colors.background4};

  padding: 4rem ${(props) => props.theme.spacing.sectionPaddingX} 2rem;
`;

// Footer Mobile
export const FooterMobileCont = styled.div``;

export const FooterMobileRowOne = styled.div`
  margin-bottom: 3rem;

  & > div:first-child {
    text-align: center;

    margin-bottom: 1.4rem;
  }

  & > div:last-child {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;

    & > img:first-child {
      width: 18rem;
    }

    & > img:nth-child(2) {
      width: 18rem;
    }
    & > img:nth-child(3) {
      width: 12rem;
    }

    & > img:last-child {
      width: 6rem;
    }
  }
`;

export const FooterMobileRowTwo = styled.div`
  margin-bottom: 3rem;
`;

export const FooterMobileRowThree = styled.div`
  text-align: center;

  margin-bottom: 3rem;

  & > h5 {
    font-size: 1.5rem;
    color: ${(props) => props.theme.colors.light};

    margin-bottom: 2.2rem;
  }
`;

export const FooterMobileRowFour = styled.div`
  text-align: center;

  margin-bottom: 3rem;

  & > ul {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: 1.6rem;

    & > li {
      margin-bottom: 2rem;

      & > img {
        width: 9rem;
      }
    }
  }
`;

// export const FooterMobileRowFive = styled.div`
//   text-align: center;

//   margin-bottom: 3rem;

//   & > ul {

//     & > li {
//       margin-bottom: 2rem;

//       & > img {
//       }
//     }
//   }
// `;

export const FooterMobileRowSix = styled.div`
  text-align: center;

  margin-bottom: 3rem;

  & > h4 {
    color: ${(props) => props.theme.colors.light};
    font-weight: ${(props) => props.theme.fontWeight.light};
  }
`;

export const FooterMobileRowSeven = styled.div`
  margin-bottom: 2rem;

  & > h5 {
    font-size: 1.6rem;
    color: ${(props) => props.theme.colors.light};

    margin-bottom: 2rem;
  }

  & > ul {
    display: flex;
    gap: 2rem;

    & > li {
      font-size: 2.3rem;
      color: ${(props) => props.theme.colors.light};
    }
  }

  @media ${(props) => props.theme.breakpoints.lg} {
  }
`;

export const FooterMobileRowEight = styled.div`
  margin-bottom: 2rem;
`;

export const FooterMobileRowNine = styled.div`
  margin-bottom: 2rem;

  & > .footer__phone-link {
    font-size: 1.4rem;
    color: ${(props) => props.theme.colors.primary1};
  }
`;

// Footer Desktop
export const FooterDesktopCont = styled.div`
  margin-bottom: 4rem;
`;

export const FooterDesktopRowOne = styled.div`
  @media ${(props) => props.theme.breakpoints.lg} {
    display: grid;
    grid-template-columns: repeat(5, 1fr);

    margin-bottom: 2rem;
  } ;
`;

export const FooterDesktopRowTwo = styled.div`
  @media ${(props) => props.theme.breakpoints.lg} {
    display: grid;
    grid-template-columns: auto 1fr;
  } ;
`;

export const FooterSubtitle = styled.h4`
  font-size: 1.4rem;
  font-weight: ${(props) => props.theme.fontWeight.bold};
  color: ${(props) => props.theme.colors.primary1};

  margin-bottom: 1rem;
`;

// Footer Desktop Row One
export const FooterColumnOne = styled.div`
  @media ${(props) => props.theme.breakpoints.lg} {
    display: flex;
    flex-direction: column;

    & > img:not(:first-child) {
      width: 20rem;
    }

    & > div {
      margin: 1rem 0;

      & > h4 {
        color: ${(props) => props.theme.colors.light};
        font-weight: ${(props) => props.theme.fontWeight.light};
      }
    }
  }
`;

export const FooterColumnTwo = styled.div`
  @media ${(props) => props.theme.breakpoints.lg} {
  }
`;

export const FooterColumnThree = styled.div`
  @media ${(props) => props.theme.breakpoints.lg} {
  }
`;

export const FooterColumnFour = styled.div`
  @media ${(props) => props.theme.breakpoints.lg} {
  }
`;

export const FooterColumnFive = styled.div`
  @media ${(props) => props.theme.breakpoints.lg} {
    display: flex;
    align-items: start;
    gap: 1rem;

    & > img:first-child {
      width: 14rem;
    }

    & > img:last-child {
      width: 6rem;
    }
  }
`;

// Footer Desktop Row Two
export const FooterColumnOneAlt = styled.div`
  & > div:first-child {
    margin-bottom: 2rem;

    & > h5 {
      font-size: 1.6rem;
      color: ${(props) => props.theme.colors.light};

      margin-bottom: 2rem;
    }

    & > ul {
      display: flex;
      gap: 2rem;

      & > li {
        font-size: 2.3rem;
        color: ${(props) => props.theme.colors.light};
      }
    }
  }

  & > div:nth-child(2) {
    margin-bottom: 2rem;
  }

  & > div .footer__phone-link {
    font-size: 1.4rem;
    color: ${(props) => props.theme.colors.primary1};
  }
`;

export const FooterColumnTwoAlt = styled.div`
  & > div:first-child {
    text-align: right;

    margin-bottom: 2rem;

    & > h5 {
      font-size: 1.6rem;
      color: ${(props) => props.theme.colors.light};

      margin-bottom: 2rem;
    }
  }
`;

export const FooterColumnLogoList = styled.div`
  margin-bottom: 1rem;

  & > ul {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 2rem;

    & > li:nth-child(1) > img {
      width: 10rem;
    }
  }
`;

export const FooterMailLink = styled(Link)`
  font-size: 1.4rem;
  font-weight: 300;
  color: ${(props) => props.theme.colors.light};
`;

// Disclaimer
export const FooterDisclaimerContainer = styled.div`
  & > p {
    font-size: 1.2rem;
    font-weight: 300;
    font-style: italic;
    color: ${(props) => props.theme.colors.light};

    & > a {
      color: #de9dc3;
    }
  }

  @media ${(props) => props.theme.breakpoints.lg} {
    text-align: center;

    & > p {
      font-size: 1.4rem;
    }
  }
`;
