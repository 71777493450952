import React from "react";

//import { BsTwitter } from "react-icons/bs";
import { MdFacebook } from "react-icons/md";
import { FaInstagram } from "react-icons/fa";
import { AiFillLinkedin } from "react-icons/ai";
import { BsYoutube } from "react-icons/bs";

const SocialMedia = () => {
  return (
    <>
      <ul>
        <li>
          <a
            target="_blank"
            href="https://www.facebook.com/profile.php?id=100076367858716"
            rel="noopener noreferrer"
          >
            <MdFacebook />
          </a>
        </li>
        <li>
          <a
            target="_blank"
            href="https://www.instagram.com/saborescard/"
            rel="noopener noreferrer"
          >
            <FaInstagram />
          </a>
        </li>
        <li>
          <a
            target="_blank"
            href="https://www.linkedin.com/company/u-payments-company/"
            rel="noopener noreferrer"
          >
            <AiFillLinkedin />
          </a>
        </li>
        <li>
          <a
            target="_blank"
            href="https://www.youtube.com/channel/UChi1qPTICSAQRVTayuwKEmg"
            rel="noopener noreferrer"
          >
            <BsYoutube />
          </a>
        </li>
      </ul>
    </>
  );
};

export default SocialMedia;
