import React from "react";

import { RiCloseCircleLine } from "react-icons/ri";

import { ModalClose, ModalContainer, ModalWrap } from "./ModalStyled";

const Modal = ({ children, isOpen, closeModal, isCloseIcon }) => {
  //
  const handleModalContainerClick = (e) => {
    e.stopPropagation();
  };

  //
  const handleCloseModal = () => {
    closeModal();
  };

  return (
    <>
      <ModalWrap onClick={handleCloseModal} isOpen={isOpen}>
        <ModalContainer onClick={handleModalContainerClick}>
          {children}

          {isCloseIcon && (
            <ModalClose>
              <RiCloseCircleLine onClick={handleCloseModal} />
            </ModalClose>
          )}
        </ModalContainer>
      </ModalWrap>
    </>
  );
};

export default Modal;
