import React from "react";

import {
  FAQContentCont,
  FAQListCont,
  FAQTitleCont,
  FAQWrap,
} from "../components/Faq/FaqStyled";

import { faqData } from "../utils/faq";

import Header from "../components/Header/Header";

import {
  HeroSection,
  MaxWidthWrap,
  TitleSection,
} from "../components/Reusable";

import Accordion from "../components/Accordion/Accordion";
import { BorderGradient } from "../components/GradientComponents/Gradient";
import Footer from "../components/Footer/Footer";

const FAQ = () => {
  return (
    <FAQWrap>
      <Header />
      <HeroSection isFAQ />

      <FAQContentCont>
        <MaxWidthWrap>
          <FAQTitleCont>
            <TitleSection>Preguntas frecuentes</TitleSection>
          </FAQTitleCont>

          <FAQListCont>
            {faqData.map(({ title, content, listContent }, index) => (
              <Accordion title={title} isFAQ key={index}>
                {content
                  ? content
                  : listContent.map((item, i) => (
                      <div key={i}>
                        <p>{item}</p>
                        <br />
                      </div>
                    ))}
              </Accordion>
            ))}
          </FAQListCont>
        </MaxWidthWrap>
      </FAQContentCont>

      <BorderGradient />
      <Footer />
    </FAQWrap>
  );
};

export default FAQ;
