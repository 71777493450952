import styled from "styled-components";

// Simulator view
export const SimuladorWrap = styled.div`
  padding-top: ${(props) => props.theme.spacing.headerHeight};
`;

export const SimuladorFormCont = styled.section`
  padding: ${(props) => props.theme.spacing.sectionPaddingY}
    ${(props) => props.theme.spacing.sectionPaddingX};
`;

export const SimuladorTitleCont = styled.div`
  text-align: center;

  margin-bottom: 4rem;

  & > h3 {
    & > span {
      color: ${(props) => props.theme.colors.primary2};
    }
  }

  @media ${(props) => props.theme.breakpoints.lg} {
    grid-template-columns: 30% 1fr;
    gap: 3rem;

    margin-bottom: 6rem;
  }
`;

// Simulator Form
export const SimulatorFormWrap = styled.div``;

export const SimulatorRowOne = styled.div`
  display: grid;

  font-size: 1.3rem;
  color: #442251;

  max-width: 350px;
  margin: 3rem auto;

  @media ${(props) => props.theme.breakpoints.lg} {
    grid-template-columns: 30% 1fr;
    gap: 4rem;

    max-width: 1170px;
    margin: 5rem auto;
  }
`;

export const SimulatorRowTwo = styled.div`
  text-align: center;
`;

export const SimulatorCTACont = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;

  margin-bottom: 3rem;
`;

export const SimulatorBlockOne = styled.div``;

export const SimulatorBlockTwo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;

  padding: 4rem 1rem;

  border: 1px solid #442251;
  border-radius: 2rem;

  @media ${(props) => props.theme.breakpoints.lg} {
    flex-direction: row;
    align-items: initial;

    padding: 2rem;
  }
`;

export const SimulatorSwitchCont = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
`;

export const Switch = styled.div(
  ({ theme, isMonthly }) => `
  display: flex;

  align-self: center;  

  & > * {
    padding: 0.5rem 1.4rem;
    border: 1px solid #e71d73;

    min-width: 0;
    flex-basis: 100%;    
    
    cursor: pointer;
  }  
`
);

export const SwitchLeftMonth = styled.div(
  ({ theme, isMonthly }) => `

  background: ${isMonthly ? theme.colors.primary2 : "#fff"};

  border-radius: 20px 0px 0px 20px;

  & > p {
    color: ${isMonthly ? "#fff" : theme.colors.secondary1};
    font-weight: ${theme.fontWeight.normal};
  }

`
);

export const SwitchRightYear = styled.div(
  ({ theme, isYearly }) => `

  background: ${isYearly ? theme.colors.primary2 : "#fff"};

  border-radius: 0px 20px 20px 0px;

  & > p {
    color: ${isYearly ? "#fff" : theme.colors.secondary1};
    font-weight: ${theme.fontWeight.normal};
  }
`
);

export const TotalCostCont = styled.div`
  margin-top: 2rem;

  & > p {
    font-size: 1.2rem;
    font-weight: ${(props) => props.theme.fontWeight.semiBold};

    margin-bottom: 1rem;
  }

  & > div {
    background: #502361;

    padding: 1rem;

    border-radius: 10px;

    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12);

    & > p {
      color: ${(props) => props.theme.colors.light};
    }
  }
`;

export const SimulatorContCompleta = styled.div`
  align-self: center;

  & > h4 {
    font-size: 1.6rem;

    margin-bottom: 3rem;
  }

  @media ${(props) => props.theme.breakpoints.lg} {
    align-self: start;
  }
`;

export const SimulatorContSimplificada = styled.div`
  align-self: center;

  @media ${(props) => props.theme.breakpoints.lg} {
    align-self: flex-end;
  }
`;

export const ContabilidadTitle = styled.h5`
  font-size: 1.4rem;
  text-decoration: underline;
  color: ${(props) => props.theme.colors.primary2};

  margin-bottom: 1rem;

  @media ${(props) => props.theme.breakpoints.lg} {
    text-decoration: none;
  }
`;

export const AhorroCont = styled.div(
  ({ theme, isTotal }) => `

  font-weight: ${theme.fontWeight.semiBold};
  color: #502361;

  margin-bottom: 1rem;

  & > p:last-child {
    color: ${isTotal ? theme.colors.primary2 : "#502361"}
  }
  
  `
);

export const SimulatorInput = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: space-between;

  padding: 1rem;

  //border-bottom: 1px solid #442251;

  & > div {
    flex: 1;

    text-align: center;

    & > img {
      color: #442251;
      width: 3.2rem;
    }

    & > input {
      border: 1px solid #442251;
    }
  }
`;

export const SimulatorTotal = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  padding: 1rem 0;

  & > p {
    font-weight: 600;
  }

  & > p:last-child {
    color: ${(props) => props.theme.colors.primary2};
  }
`;

export const SimulatorDisclaimer = styled.p`
  font-size: 1.3rem;
  color: #afafaf;
  font-style: italic;

  max-width: 55rem;

  margin: 0 auto;

  @media ${(props) => props.theme.breakpoints.lg} {
    font-size: 1.4rem;

    max-width: inherit;
    margin: 0;
  }
`;
