import styled from "styled-components";

export const FormConversionWrap = styled.div`
  @media ${(props) => props.theme.breakpoints.lg} {
    min-width: 52rem;
  }
`;

export const FormContactWrap = styled.div`
  @media ${(props) => props.theme.breakpoints.lg} {
    min-width: 52rem;
  }
`;

export const FormSingleInputCont = styled.div``;

export const FormTwoInputCont = styled.div`
  @media ${(props) => props.theme.breakpoints.lg} {
    display: flex;
    justify-content: space-between;
    gap: 2rem;

    & > * {
      flex: 1;
    }
  }
`;

export const FormSubmitCont = styled.div`
  display: flex;
  justify-content: center;

  & > button {
    width: 56%;
  }
`;

// Captcha
export const ReCaptchaCont = styled.div`
  display: flex;
  justify-content: center;

  margin-bottom: 2.6rem;

  @media ${(props) => props.theme.breakpoints.lg} {
  } ;
`;

//
export const ReCaptchaMessage = styled.div`
  text-align: center;

  margin-bottom: 2.6rem;

  & > p {
    font-size: 1.4rem;
    color: ${(props) => props.theme.colors.primary2};
  }

  @media ${(props) => props.theme.breakpoints.lg} {
  } ;
`;

