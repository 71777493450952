import { encryptedaes256cbc } from "../utils/crypto";

import { API_MAIL } from "./index";

// POST send Contact Mail
export const sendMailContact = (values) => {
  //console.log(values);

  const encrypted = encryptedaes256cbc(JSON.stringify(values));

  const data = {
    param: encrypted,
  };

  console.log(API_MAIL);

  return fetch(
    `${API_MAIL}/api/mail/contact/web`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  )
    .then((response) => {
      console.log("MAIL call", response);

      let result = response.json();

      return result;
    })
    .catch((err) => {
      console.log(err.toString());
      let error = { data: { status: "ERROR", message: err.toString() } };
      return error.data;
    });
};

// POST send Conversion Mail
export const sendMailConversion = (values) => {
  //console.log(values);

  const encrypted = encryptedaes256cbc(JSON.stringify(values));

  const data = {
    param: encrypted,
  };

  console.log(API_MAIL);

  return fetch(`${API_MAIL}/api/mail/convert/web`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      console.log("MAIL call", response);

      let result = response.json();

      return result;
    })
    .catch((err) => {
      console.log(err.toString());
      let error = { data: { status: "ERROR", message: err.toString() } };
      return error.data;
    });
};
