import React, { createContext, useState } from "react";

export const MainContext = createContext();

const MainContextProvider = ({ children }) => {
  const [workers, setWorkers] = useState(0);
  const [assignedAmount, setAssignedAmount] = useState(0);

  //console.log('WORKERS', workers);

  return (
    <>
      <MainContext.Provider
        value={{ workers, setWorkers, assignedAmount, setAssignedAmount }}
      >
        {children}
      </MainContext.Provider>
    </>
  );
};

export default MainContextProvider;
