import styled from "styled-components";

export const AppWrap = styled.section`
  @media ${(props) => props.theme.breakpoints.lg} {
  }
`;

export const AppWhiteRow = styled.div(
  ({ theme, isTop }) => `

  background: ${isTop ? theme.colors.light : "transparent"};

  height: 8rem;
`
);

export const AppContentCont = styled.div`
  background: ${(props) => props.theme.colors.background5};

  text-align: center;

  padding: ${(props) => props.theme.spacing.sectionPaddingY}
    ${(props) => props.theme.spacing.sectionPaddingX};

  @media ${(props) => props.theme.breakpoints.lg} {
    text-align: unset;

    padding: 4rem ${(props) => props.theme.spacing.sectionPaddingX};

    height: 44rem;

    & > div {
      display: grid;
      grid-template-columns: 1fr auto;
    }
  }
`;

export const AppContentColOne = styled.div`
  position: relative;
`;

export const AppTitleCont = styled.div`
  margin-bottom: 1rem;
`;

export const AppSubtitleCont = styled.div`
  margin-bottom: 3.2rem;

  & > h5 {
    font-size: 1.7rem;
    font-weight: ${(props) => props.theme.fontWeight.normal};
    color: ${(props) => props.theme.colors.light};

    & > span {
      color: ${(props) => props.theme.colors.primary2};
    }
  }

  @media ${(props) => props.theme.breakpoints.lg} {
    margin-bottom: 4.2rem;

    & > h5 {
      font-size: 2rem;
    }
  }

  @media ${(props) => props.theme.breakpoints.xl} {
    & > h5 {
      font-size: 3rem;
    }
  }
`;

export const AppImgCont = styled.div`
  position: relative;

  max-width: 38rem;

  margin: auto;

  @media ${(props) => props.theme.breakpoints.lg} {
    top: -15%;
    right: -8%;

    max-width: 60rem;

    margin: 0;

    overflow: hidden;
  }
`;

export const AppBadgesCont = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  gap: 1.4rem;

  margin: 3rem auto 5rem;

  max-width: 40rem;

  @media ${(props) => props.theme.breakpoints.lg} {
    margin: 0;

    max-width: 48rem;
  }
`;

export const AppBadgeItem = styled.div`
  flex-basis: 100%;

  display: flex;
  flex-direction: column;

  & > img:first-child {
  }

  & img.badgeOne {
  }

  & img.badgeTwo {
  }

  & img.badgeThree {
  }

  @media ${(props) => props.theme.breakpoints.lg} {
    & > img {
      width: 12rem;
    }

    & > img:first-child {
      margin-bottom: 1rem;
    }

    & > img:last-child {
      border-radius: 0.8rem;
    }
  }
`;

// CTA btn
export const AppCTACont = styled.div`
  text-align: center;

  @media ${(props) => props.theme.breakpoints.lg} {
    position: absolute;
    bottom: 34%;
    left: 16%;
  }

  @media ${(props) => props.theme.breakpoints.xl} {
    //left: 12%;
    left: 18%;
  }
`;
