import React from "react";

import {
  SCPhysicalCardCont,
  SCPhysicalContentCont,
  SCPhysicalItemCont,
  SCPhysicalItemsCont,
  SCPhysicalTitleCont,
  SCPhysicalWrap,
} from "./SCStyled";

import useWindowDimensions from "../../hooks/useDimensions";

import { MaxWidthWrap, TitleSection } from "../Reusable";
import { BoxGradientAlt, TextGradient } from "../GradientComponents/Gradient";

import scCardName from "../../assets/img/sc-card-name.png";
import shopImg from "../../assets/img/shop.svg";
import benefitsImg from "../../assets/img/benefits.svg";
import trophyImg from "../../assets/img/trophy.svg";

const SCPhysical = () => {
  const { width } = useWindowDimensions();

  return (
    <>
      <SCPhysicalWrap>
        <MaxWidthWrap>
          {width > 1023 && (
            <SCPhysicalCardCont>
              <img src={scCardName} alt="" />
            </SCPhysicalCardCont>
          )}

          <SCPhysicalContentCont>
            <SCPhysicalTitleCont>
              <TitleSection colorLight>
                {width < 1023 ? (
                  <>
                    ¿Qué es la tarjeta prepago Mastercard®{" "}
                    <TextGradient>Sabores Card?</TextGradient>
                  </>
                ) : (
                  <>
                    ¿Qué es la tarjeta <br /> prepago Mastercard®{" "}
                    <TextGradient>Sabores Card?</TextGradient>
                  </>
                )}
              </TitleSection>
            </SCPhysicalTitleCont>

            <SCPhysicalItemsCont>
              {/* Item 1 */}
              <SCPhysicalItemCont>
                <BoxGradientAlt>
                  <img src={trophyImg} alt="" />
                </BoxGradientAlt>

                <h4>
                  La mejor tarjeta de <br /> alimentación
                </h4>

                <p>
                  Es una tarjeta prepago diseñada por Mastercard® y U-Payments
                  Company, pensada para que la empresa otorgue a los
                  colaboradores beneficios de alimentación de la manera más
                  cómoda e inteligente del mercado.
                </p>
              </SCPhysicalItemCont>

              {/* Item 2 */}
              <SCPhysicalItemCont>
                <BoxGradientAlt>
                  <img src={benefitsImg} alt="" />
                </BoxGradientAlt>

                <h4>Más beneficios</h4>
                <br />

                <p>
                  *Próximamente se extenderán más beneficios para los
                  colaboradores, tales como: tarjeta física, el pago de
                  transporte, sala cuna y muchos más!
                </p>
              </SCPhysicalItemCont>

              {/* Item 3 */}
              <SCPhysicalItemCont>
                <BoxGradientAlt>
                  <img src={shopImg} alt="" />
                </BoxGradientAlt>

                <h4>Comercios Digitales</h4>
                <br />

                <p>
                  Con la tarjeta prepago Sabores Card el colaborador podrá
                  comprar su alimento en cualquier establecimiento digital de
                  comida a lo largo de Chile.
                </p>
              </SCPhysicalItemCont>
            </SCPhysicalItemsCont>
          </SCPhysicalContentCont>
        </MaxWidthWrap>
      </SCPhysicalWrap>
    </>
  );
};

export default SCPhysical;
