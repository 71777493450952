import styled from "styled-components";

export const FAQWrap = styled.div`
  padding-top: ${(props) => props.theme.spacing.headerHeight};
`;

export const FAQTitleCont = styled.div`
  text-align: center;

  margin-bottom: 4rem;
`;

export const FAQContentCont = styled.div`
  padding: ${(props) => props.theme.spacing.sectionPaddingY}
    ${(props) => props.theme.spacing.sectionPaddingX};

  @media ${(props) => props.theme.breakpoints.lg} {
  }
`;

export const FAQListCont = styled.section``;