import styled from "styled-components";

import { Link } from "react-router-dom";

export const Sidebar = styled.nav`
  position: fixed;
  top: ${(props) => props.theme.spacing.headerHeight};
  left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};

  background: ${(props) => props.theme.colors.background1};

  min-width: 100vw;
  height: 100vh;

  //transform: ${({ sidebar }) =>
    sidebar ? "translateX(0)" : "translateX(-100%)"};

  transition: 0.5s ease-in-out;

  z-index: 3;

  @media ${(props) => props.theme.breakpoints.xs} {
    min-width: 280px;
  }
`;

export const SidebarWrap = styled.div`
  width: 100%;

  padding: 1rem;
`;

export const SidebarIcon = styled.div`
  font-size: 2rem;

  padding: 1rem 0;

  & svg {
    cursor: pointer;
  }
`;

export const SidebarLink = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: 1.4rem;
  color: ${(props) => props.theme.colors.primary1};

  padding: 1.2rem 1rem;

  & > div {
    & > img {
      color: ${(props) => props.theme.colors.primary1};
    }
  }

  &:first-child {
    border-bottom: ${(props) =>
      props.showsubnav ? "none" : "1px solid #d0d0d0"};
  }

  &:hover {
    background: ${(props) => props.theme.colors.background1};
    border-left: 5px solid ${(props) => props.theme.colors.secondary1};
    cursor: pointer;
  }
`;

export const DropdownLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 0.6rem;

  background: ${(props) => props.theme.colors.background1};

  padding: 0.6rem 0.6rem 0.6rem 2rem;

  text-decoration: none;
  color: ${(props) => props.theme.colors.text1};
  font-size: 14px;

  &:nth-child(3) {
    border-bottom: ${(props) =>
      props.showsubnav ? "1px solid #d0d0d0" : "none"};
  }

  &:hover {
    background: ${(props) => props.theme.colors.background1};
    cursor: pointer;
  }
`;

export const SidebarLabel = styled.span`
  margin-left: 1.6rem;
`;


