import styled from "styled-components";

export const BurgerStyled = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  //width: 1.6rem;
  height: 1.8rem;

  background: transparent;

  border: none;

  cursor: pointer;

  &:focus {
    outline: none;
  }

  div {
    //position: relative;

    background: ${(props) => props.theme.colors.light};

    width: 2rem;
    height: 3px;

    border-radius: 10px;

    transition: all 0.3s linear;

    transform-origin: 0px;

    :first-child {
      transform: ${({ sidebar }) => (sidebar ? "rotate(50deg)" : "rotate(0)")};
    }

    :nth-child(2) {
      opacity: ${({ sidebar }) => (sidebar ? "0" : "1")};
      transform: ${({ sidebar }) =>
        sidebar ? "translateX(20px)" : "translateX(0)"};
    }

    :nth-child(3) {
      transform: ${({ sidebar }) => (sidebar ? "rotate(-50deg)" : "rotate(0)")};
    }
  }
`;
