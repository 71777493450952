const env_status = process.env.REACT_APP_VARIABLES_ENV;

if (!env_status) {
  console.log(`⚠️  Couldn't find .env file  ⚠️: ${env_status}`);
} else {
  console.log(`⚠️  The .env file is  ⚠️: ${env_status}`);
}

const config = {
  MAIL_URL: process.env.REACT_APP_API_MAIL,
  RECAPTCHA_KEY: process.env.RECAPTCHA_KEY,
  NODE_ENV: 'development',
};

// console.log(config);

// capture the environment variables the application needs
const { MAIL_URL, RECAPTCHA_KEY ,NODE_ENV } = config;

module.exports = {
  NODE_ENV: NODE_ENV,
  API_MAIL: MAIL_URL,
  RECAPTCHA_KEY: RECAPTCHA_KEY,
};
