import React from "react";

import { MdCreditCard } from "react-icons/md";

import { BoxGradient } from "../GradientComponents/Gradient";

import {
  InfoItemContainer,
  InfoItemTextContainer,
  InfoItemsContainer,
} from "./InfoItemStyled";

import workerImg from "../../assets/img/worker.svg";
import buildingImg from "../../assets/img/building.svg";

const InfoItem = () => {
  return (
    <>
      <InfoItemsContainer>
        {/* Item 1 */}
        <InfoItemContainer>
          <BoxGradient>
            <MdCreditCard />
          </BoxGradient>

          <InfoItemTextContainer>
            <p>Tarjeta prepago Sabores Card</p>
            <p>
              Tarjeta de pago con provisión de fondos <span>Mastercard® </span>
              sin limites de uso en el rubro de alimentación, paga en donde
              quieras y accede a todos los beneficios pensados para ti.
            </p>
          </InfoItemTextContainer>
        </InfoItemContainer>

        {/* Item 2 */}
        <InfoItemContainer>
          <BoxGradient>
            <img src={workerImg} alt="" />
          </BoxGradient>

          <InfoItemTextContainer>
            <p>Aplicativo para colaboradores</p>
            <p>
              Los colaboradores contarán con una app disponible para sistemas
              operativos iOS y Android para gestionar su beneficio.
            </p>
          </InfoItemTextContainer>
        </InfoItemContainer>

        {/* Item 3 */}
        <InfoItemContainer>
          <BoxGradient>
            <img src={buildingImg} alt="" />
          </BoxGradient>

          <InfoItemTextContainer>
            <p>Herramienta de gestión empresa</p>
            <p>
              Herramienta de gestión, control y visualización de los beneficios
              entregados a los colaboradores.
            </p>
          </InfoItemTextContainer>
        </InfoItemContainer>
      </InfoItemsContainer>
    </>
  );
};

export default InfoItem;
