import React from "react";

import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";

import {
  LeyContentCont,
  LeyItemCont,
  LeyListCont,
  LeyTableCont,
  LeyTitleCont,
  LeyWrap,
} from "../components/Ley/LeyStyled";

import {
  HeroSection,
  MaxWidthWrap,
  TitleSection,
} from "../components/Reusable";

const Ley = () => {
  return (
    <>
      <LeyWrap>
        <Header />

        <HeroSection isTerms />

        <LeyContentCont>
          <MaxWidthWrap>
            <LeyTitleCont>
              <TitleSection>Ley 21.234</TitleSection>
            </LeyTitleCont>

            <LeyListCont>
              <LeyItemCont>
                <p>
                  En cumplimiento del artículo 11 de la Ley N°21.234 sobre
                  fraude en medios de pago, a continuación, publicamos la
                  información de los casos de hurto, robo o fraude que han
                  afectado a nuestros clientes durante el Primer semestre del
                  2022
                </p>
              </LeyItemCont>

              <LeyItemCont>
                <h3>DEFINICIONES</h3>

                <LeyTableCont>
                  <table>
                    <tbody>
                      <tr>
                        <td>Indicadores Periodo Primer semestre 2022</td>
                        <td>Tarjeta de Crédito</td>
                        <td>Tarjetas de Débito</td>
                        <td>Tarjeta prepago con provisión de fondos</td>
                        <td>Transferencia electrónica</td>
                        <td>Transacciones en cajero automático</td>
                        <td>Otros (especificar)</td>
                        <td>Totales</td>
                      </tr>

                      <tr>
                        <td>Nº de usuarios afectados</td>
                        <td>$--</td>
                        <td>$--</td>
                        <td>$0</td>
                        <td>$--</td>
                        <td>$--</td>
                        <td>$--</td>
                        <td>$0</td>
                      </tr>

                      <tr>
                        <td>Montos involucrados</td>
                        <td>$--</td>
                        <td>$--</td>
                        <td>0</td>
                        <td>$--</td>
                        <td>$--</td>
                        <td>$--</td>
                        <td>0</td>
                      </tr>

                      <tr>
                        <td>
                          Plazo de respuesta por transacciones hasta 35 UF (días
                          hábiles promedio)
                        </td>
                        <td>--</td>
                        <td>--</td>
                        <td>0</td>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                        <td>0</td>
                      </tr>
                    </tbody>
                  </table>
                </LeyTableCont>
              </LeyItemCont>

              <LeyItemCont>
                <h3>Definición de los campos</h3>

                <LeyTableCont>
                  <table>
                    <tbody>
                      <tr>
                        <td>Nº de usuarios afectados</td>
                        <td>
                          Corresponde al número de usuarios que ingresaron un
                          aviso en caso de hurto, robo, extravío o fraude.
                        </td>
                      </tr>

                      <tr>
                        <td>Montos involucrados</td>
                        <td>
                          Corresponde al número de usuarios que ingresaron un
                          aviso en caso de hurto, robo, extravío o fraude.
                        </td>
                      </tr>

                      <tr>
                        <td>
                          Plazo de respuestas por transacciones sobre 35 UF
                          (Días hábiles promedio).
                        </td>
                        <td>
                          Corresponde a los montos totales expresados en
                          millones de pesos, respecto de las operaciones en las
                          cuales el usuario desconoce haber otorgado su
                          autorización o consentimiento.
                        </td>
                      </tr>

                      <tr>
                        <td>Transferencias electrónicas</td>
                        <td>
                          Corresponde a los montos totales expresados en
                          millones de pesos, respecto de las operaciones en las
                          cuales el usuario desconoce haber otorgado su
                          autorización o consentimiento.
                        </td>
                      </tr>

                      <tr>
                        <td>Transacciones en cajeros automáticos</td>
                        <td>
                          Corresponde a los montos totales expresados en
                          millones de pesos, respecto de las operaciones en las
                          cuales el usuario desconoce haber otorgado su
                          autorización o consentimiento.
                        </td>
                      </tr>

                      <tr>
                        <td>Otros</td>
                        <td>
                          Giros realizados en cajeros automáticos no
                          reconocidos.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </LeyTableCont>
              </LeyItemCont>
            </LeyListCont>
          </MaxWidthWrap>
        </LeyContentCont>
        <Footer />
      </LeyWrap>
    </>
  );
};

export default Ley;
