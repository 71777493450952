import React, { useContext, useEffect, useState } from "react";

import { HashLink } from "react-router-hash-link";

//import NumberFormat from "react-number-format";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { MainContext } from "../../context/useContext";

import useWindowDimensions from "../../hooks/useDimensions";

import { FormInputError } from "../FormInput/FormInputStyled";
import { Button } from "../Button/ButtonStyled";
import { LineHR } from "../Reusable";

import {
  AhorroCont,
  ContabilidadTitle,
  SimulatorBlockOne,
  SimulatorBlockTwo,
  SimulatorContCompleta,
  SimulatorContSimplificada,
  SimulatorCTACont,
  SimulatorDisclaimer,
  SimulatorFormWrap,
  SimulatorInput,
  SimulatorRowOne,
  SimulatorRowTwo,
  SimulatorSwitchCont,
  SimulatorTotal,
  Switch,
  SwitchLeftMonth,
  SwitchRightYear,
  TotalCostCont,
} from "./SimulatorStyled";

import workerImg from "../../assets/img/icons/worker.svg";
import assignmentImg from "../../assets/img/icons/assignment.svg";
import totalAssignmentImg from "../../assets/img/icons/total-assign.svg";

//
const schema = yup.object().shape({
  workers: yup
    .number()
    .integer("Cantidad debe ser un entero")
    .positive("Cantidad debe ser positivo")
    .transform((value) => (isNaN(value) ? undefined : value))
    .min(1, "Cantidad debe ser mayor a uno")
    .max(1000000, "Cantidad debe ser un máximo de de 1.000.000")
    .required("Cantidad es requerida."),
  assignedAmount: yup
    .number()
    .integer("Cantidad debe ser un entero")
    .positive("Cantidad debe ser positivo")
    .transform((value) => (isNaN(value) ? undefined : value))
    .min(500, "Monto debe ser un mínimo de $500")
    .max(10000000, "Monto debe ser un máximo de de $10.000.000")
    .required("Monto es requerido."),
});

//
const SimulatorForm = (props) => {
  const [isMonthly, setIsMonthly] = useState(true);
  const [isYearly, setIsYearly] = useState(false);

  const [values, setValues] = useState({
    cantidadTrabajadores: 0,
    asignacionMensual: 0,
    totalAsignacionMensual: 0,
    totalAsignacionAnual: 0,
    recargaNetaMensual: 0,
    recargaNetaAnual: 0,
    ahorroIVAMensual: 0,
    ahorroIVAAnual: 0,
    ahorroRentaCompletaMensual: 0,
    ahorroRentaSimplificadaMensual: 0,
    ahorroRentaCompletaAnual: 0,
    ahorroRentaSimplificadaAnual: 0,
    ahorroTotalMensualCompleta: 0,
    ahorroTotalMensualSimplificada: 0,
    ahorroTotalAnualCompleta: 0,
    ahorroTotalAnualSimplificada: 0,
    costoTotalCompletoMensual: 0,
    costoTotalSimplificadaMensual: 0,
    costoTotalCompletoAnual: 0,
    costoTotalSimplificadaAnual: 0,
  });

  const {
    cantidadTrabajadores,
    asignacionMensual,
    totalAsignacionMensual,
    totalAsignacionAnual,
    //recargaNetaMensual,
    //recargaNetaAnual,
    ahorroIVAMensual,
    ahorroIVAAnual,
    ahorroRentaCompletaMensual,
    ahorroRentaSimplificadaMensual,
    ahorroRentaCompletaAnual,
    ahorroRentaSimplificadaAnual,
    ahorroTotalMensualCompleta,
    ahorroTotalMensualSimplificada,
    ahorroTotalAnualCompleta,
    ahorroTotalAnualSimplificada,
    costoTotalCompletoMensual,
    costoTotalSimplificadaMensual,
    costoTotalCompletoAnual,
    costoTotalSimplificadaAnual,
  } = values;

  //
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const { width } = useWindowDimensions();

  const { setWorkers, setAssignedAmount } = useContext(MainContext);

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -20;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  // Effects..
  useEffect(() => {
    //
    // console.log("workers", cantidadTrabajadores);
    // console.log("assignedAmount", asignacionMensual);

    //
    if (
      cantidadTrabajadores >= 1 &&
      cantidadTrabajadores <= 5000000 &&
      asignacionMensual >= 500 &&
      asignacionMensual <= 10000000
    ) {
      taxSavingSimulator(cantidadTrabajadores, asignacionMensual);
    } else {
      setValues({
        ...values,
        totalAsignacionMensual: 0,
        totalAsignacionAnual: 0,
        recargaNetaMensual: 0,
        recargaNetaAnual: 0,
        ahorroIVAMensual: 0,
        ahorroIVAAnual: 0,
        ahorroRentaCompletaMensual: 0,
        ahorroRentaSimplificadaMensual: 0,
        ahorroRentaCompletaAnual: 0,
        ahorroRentaSimplificadaAnual: 0,
        ahorroTotalMensualCompleta: 0,
        ahorroTotalMensualSimplificada: 0,
        ahorroTotalAnualCompleta: 0,
        ahorroTotalAnualSimplificada: 0,
        costoTotalCompletoMensual: 0,
        costoTotalSimplificadaMensual: 0,
        costoTotalCompletoAnual: 0,
        costoTotalSimplificadaAnual: 0,
      });
    }
  }, [cantidadTrabajadores, asignacionMensual]);

  // Functions...
  const taxSavingSimulator = (workers, assignedAmount) => {
    let totalMonthAssigned = workers * assignedAmount;
    let totalAnnualAssigned = totalMonthAssigned * 12;

    let monthlyNetRecharge = Math.round(totalMonthAssigned / 1.19);
    let annualNetRecharge = Math.round(totalAnnualAssigned / 1.19);

    let monthlySavingIva = Math.ceil(monthlyNetRecharge * 0.19);
    let annualSavingIva = Math.ceil(annualNetRecharge * 0.19);

    let monthlySavingRentComplete = Math.round(monthlyNetRecharge * 0.27);
    let monthlySavingRentSimple = Math.round(monthlyNetRecharge * 0.125);
    let annualSavingRentComplete = Math.round(annualNetRecharge * 0.27);
    let annualSavingRentSimple = Math.round(annualNetRecharge * 0.125);

    let monthlySavingTotalComplete =
      monthlySavingIva + monthlySavingRentComplete;
    let monthlySavingTotalSimple = monthlySavingIva + monthlySavingRentSimple;
    let annualSavingTotalComplete = annualSavingIva + annualSavingRentComplete;
    let annualSavingTotalSimple = annualSavingIva + annualSavingRentSimple;

    let monthlyCostTotalComplete =
      totalMonthAssigned - monthlySavingTotalComplete;
    let monthlyCostTotalSimple = totalMonthAssigned - monthlySavingTotalSimple;
    let annualCostTotalComplete =
      totalAnnualAssigned - annualSavingTotalComplete;
    let annualCostTotalSimple = totalAnnualAssigned - annualSavingTotalSimple;

    setValues({
      ...values,
      cantidadTrabajadores: workers,
      asignacionMensual: assignedAmount,
      totalAsignacionMensual: totalMonthAssigned,
      totalAsignacionAnual: totalAnnualAssigned,
      recargaNetaMensual: monthlyNetRecharge,
      recargaNetaAnual: annualNetRecharge,
      ahorroIVAMensual: monthlySavingIva,
      ahorroIVAAnual: annualSavingIva,
      ahorroRentaCompletaMensual: monthlySavingRentComplete,
      ahorroRentaSimplificadaMensual: monthlySavingRentSimple,
      ahorroRentaCompletaAnual: annualSavingRentComplete,
      ahorroRentaSimplificadaAnual: annualSavingRentSimple,
      ahorroTotalMensualCompleta: monthlySavingTotalComplete,
      ahorroTotalMensualSimplificada: monthlySavingTotalSimple,
      ahorroTotalAnualCompleta: annualSavingTotalComplete,
      ahorroTotalAnualSimplificada: annualSavingTotalSimple,
      costoTotalCompletoMensual: monthlyCostTotalComplete,
      costoTotalSimplificadaMensual: monthlyCostTotalSimple,
      costoTotalCompletoAnual: annualCostTotalComplete,
      costoTotalSimplificadaAnual: annualCostTotalSimple,
    });
  };

  const handleSpecialCh = (e) => {
    if (
      e.charCode === 43 ||
      e.charCode === 45 ||
      e.charCode === 46 ||
      e.charCode === 101
    )
      e.preventDefault(); // not allowed to type .
  };

  const handleArrowKeys = (e) => {
    if ([32, 38, 40].indexOf(e.keyCode) > -1) {
      e.preventDefault();
    }
  };

  // Register
  register("workers", {
    onChange: (e) => {
      const value = e.target.value.replace(/[^0-9]+/g, "");

      setValues({
        ...values,
        ["cantidadTrabajadores"]: value,
      });

      setWorkers(value);
    },
  });

  register("assignedAmount", {
    onChange: (e) => {
      const value = e.target.value;

      setValues({
        ...values,
        ["asignacionMensual"]: value,
      });

      setAssignedAmount(value);
    },
  });

  // Switch
  const handleSwitchMonth = () => {
    setIsYearly(false);
    setIsMonthly(true);
  };
  const handleSwitchYear = () => {
    setIsMonthly(false);
    setIsYearly(true);
  };

  // Form
  const handleSimulatorForm = (data) => {
    console.log("simulator form", data);
  };

  //console.log("IS VALID", isValid);

  return (
    <>
      <SimulatorFormWrap>
        {/* Row 1 */}
        <SimulatorRowOne>
          <SimulatorBlockOne>
            <SimulatorInput>
              <div>
                <img src={workerImg} alt="" />

                <p>
                  Cantidad de <br /> trabajadores
                </p>

                <Controller
                  name="workers"
                  control={control}
                  render={({ field }) => (
                    <>
                      <input
                        type="number"
                        name="workers"
                        id="workers"
                        autoComplete="off"
                        value={cantidadTrabajadores}
                        onKeyPress={handleSpecialCh}
                        onKeyDown={handleArrowKeys}
                        {...field}
                      />
                    </>
                  )}
                />
                <FormInputError>{errors.workers?.message}</FormInputError>
              </div>

              <div>
                <img src={assignmentImg} alt="" />

                <p>
                  Asignación <br /> mensual
                </p>

                <Controller
                  name="assignedAmount"
                  control={control}
                  render={({ field }) => (
                    <>
                      <input
                        type="number"
                        name="assignedAmount"
                        id="assignedAmount"
                        autoComplete="off"
                        value={asignacionMensual}
                        onKeyPress={handleSpecialCh}
                        onKeyDown={handleArrowKeys}
                        {...field}
                      />
                    </>
                  )}
                />
                <FormInputError>
                  {errors.assignedAmount?.message}
                </FormInputError>
              </div>
            </SimulatorInput>

            <LineHR />

            <SimulatorTotal>
              <img src={totalAssignmentImg} alt="" style={{ width: 20 }} />
              <p>Total asignación</p>
              <p>
                {isMonthly
                  ? Intl.NumberFormat("es-CL", {
                      style: "currency",
                      currency: "CLP",
                    }).format(totalAsignacionMensual)
                  : Intl.NumberFormat("es-CL", {
                      style: "currency",
                      currency: "CLP",
                    }).format(totalAsignacionAnual)}
              </p>
            </SimulatorTotal>

            {width > 1023 && (
              <>
                <SimulatorDisclaimer>
                  El análisis mostrado en pantalla es referencial, el Cliente
                  Empresa es el responsable de la gestión de este para acceder
                  al beneficio tributario. Sabores Card no se hace responsable
                  de este proceso.
                </SimulatorDisclaimer>
              </>
            )}
          </SimulatorBlockOne>

          {/*  */}
          <SimulatorBlockTwo>
            <SimulatorSwitchCont>
              <Switch>
                <SwitchLeftMonth
                  onClick={handleSwitchMonth}
                  isMonthly={isMonthly}
                >
                  <p>Mensual</p>
                </SwitchLeftMonth>

                <SwitchRightYear onClick={handleSwitchYear} isYearly={isYearly}>
                  <p>Anual</p>
                </SwitchRightYear>
              </Switch>

              <TotalCostCont>
                <p>Costo total sin Sabores Card</p>
                <div>
                  <p>
                    {isMonthly
                      ? Intl.NumberFormat("es-CL", {
                          style: "currency",
                          currency: "CLP",
                        }).format(totalAsignacionMensual)
                      : Intl.NumberFormat("es-CL", {
                          style: "currency",
                          currency: "CLP",
                        }).format(totalAsignacionAnual)}
                  </p>
                </div>
              </TotalCostCont>
            </SimulatorSwitchCont>

            <SimulatorContCompleta>
              <h4>Resultados</h4>

              <ContabilidadTitle>Régimen General</ContabilidadTitle>

              <AhorroCont>
                <p>Ahorro impto. a la renta 27% :</p>
                <p>
                  {isMonthly
                    ? Intl.NumberFormat("es-CL", {
                        style: "currency",
                        currency: "CLP",
                      }).format(ahorroRentaCompletaMensual)
                    : Intl.NumberFormat("es-CL", {
                        style: "currency",
                        currency: "CLP",
                      }).format(ahorroRentaCompletaAnual)}
                </p>
              </AhorroCont>

              <AhorroCont>
                <p>Ahorro IVA 19% :</p>
                <p>
                  {isMonthly
                    ? Intl.NumberFormat("es-CL", {
                        style: "currency",
                        currency: "CLP",
                      }).format(ahorroIVAMensual)
                    : Intl.NumberFormat("es-CL", {
                        style: "currency",
                        currency: "CLP",
                      }).format(ahorroIVAAnual)}
                </p>
              </AhorroCont>

              <AhorroCont isTotal>
                <p>Ahorro total :</p>
                <p>
                  {isMonthly
                    ? Intl.NumberFormat("es-CL", {
                        style: "currency",
                        currency: "CLP",
                      }).format(ahorroTotalMensualCompleta)
                    : Intl.NumberFormat("es-CL", {
                        style: "currency",
                        currency: "CLP",
                      }).format(ahorroTotalAnualCompleta)}
                </p>
              </AhorroCont>

              <TotalCostCont>
                <p>Costo total con Sabores Card</p>
                <div>
                  <p>
                    {isMonthly
                      ? Intl.NumberFormat("es-CL", {
                          style: "currency",
                          currency: "CLP",
                        }).format(costoTotalCompletoMensual)
                      : Intl.NumberFormat("es-CL", {
                          style: "currency",
                          currency: "CLP",
                        }).format(costoTotalCompletoAnual)}
                  </p>
                </div>
              </TotalCostCont>
            </SimulatorContCompleta>

            <SimulatorContSimplificada>
              <ContabilidadTitle>Régimen Pro Pyme</ContabilidadTitle>

              <AhorroCont>
                <p>Ahorro impto. a la renta 12,5% :</p>
                <p>
                  {isMonthly
                    ? Intl.NumberFormat("es-CL", {
                        style: "currency",
                        currency: "CLP",
                      }).format(ahorroRentaSimplificadaMensual)
                    : Intl.NumberFormat("es-CL", {
                        style: "currency",
                        currency: "CLP",
                      }).format(ahorroRentaSimplificadaAnual)}
                </p>
              </AhorroCont>

              <AhorroCont>
                <p>Ahorro IVA 19% :</p>
                <p>
                  {isMonthly
                    ? Intl.NumberFormat("es-CL", {
                        style: "currency",
                        currency: "CLP",
                      }).format(ahorroIVAMensual)
                    : Intl.NumberFormat("es-CL", {
                        style: "currency",
                        currency: "CLP",
                      }).format(ahorroIVAAnual)}
                </p>
              </AhorroCont>

              <AhorroCont isTotal>
                <p>Ahorro total :</p>
                <p>
                  {isMonthly
                    ? Intl.NumberFormat("es-CL", {
                        style: "currency",
                        currency: "CLP",
                      }).format(ahorroTotalMensualSimplificada)
                    : Intl.NumberFormat("es-CL", {
                        style: "currency",
                        currency: "CLP",
                      }).format(ahorroTotalAnualSimplificada)}
                </p>
              </AhorroCont>

              <TotalCostCont>
                <p>Costo total con Sabores Card</p>
                <div>
                  <p>
                    {isMonthly
                      ? Intl.NumberFormat("es-CL", {
                          style: "currency",
                          currency: "CLP",
                        }).format(costoTotalSimplificadaMensual)
                      : Intl.NumberFormat("es-CL", {
                          style: "currency",
                          currency: "CLP",
                        }).format(costoTotalSimplificadaAnual)}
                  </p>
                </div>
              </TotalCostCont>
            </SimulatorContSimplificada>
          </SimulatorBlockTwo>
        </SimulatorRowOne>

        {/* Row 2 */}
        <SimulatorRowTwo>
          <SimulatorCTACont>
            {isValid ? (
              <>
                {width < 1024 ? (
                  <HashLink
                    smooth
                    to="/#Form"
                    scroll={(el) => scrollWithOffset(el)}
                  >
                    <Button primary>Enviar simulación</Button>
                  </HashLink>
                ) : (
                  <>
                    <HashLink to={"/#Form"}>
                      <Button primary>Enviar simulación</Button>
                    </HashLink>
                  </>
                )}
              </>
            ) : (
              <>
                <Button
                  primary
                  onClick={handleSubmit(handleSimulatorForm)}
                  style={{ background: "#AFAFAF" }}
                >
                  Enviar simulación
                </Button>
              </>
            )}
          </SimulatorCTACont>

          {width < 1023 && (
            <>
              <SimulatorDisclaimer>
                El análisis mostrado en pantalla es referencial, el Cliente
                Empresa es el responsable de la gestión de este para acceder al
                beneficio tributario. Sabores Card no se hace responsable de
                este proceso.
              </SimulatorDisclaimer>
            </>
          )}
        </SimulatorRowTwo>
      </SimulatorFormWrap>
    </>
  );
};

export default SimulatorForm;
