import React from "react";

import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";

import {
  TermsContentCont,
  TermsItemCont,
  // TermsItemSubCont,
  TermsListCont,
  // TermsSubtitle,
  // TermsSubTitleCont,
  TermsTitleCont,
  TermsWrap,
} from "../components/Terms/TermsStyled";

import {
  HeroSection,
  MaxWidthWrap,
  TitleSection,
} from "../components/Reusable";

const Terms = () => {
  return (
    <>
      <TermsWrap>
        <Header />

        <HeroSection isTerms />

        <TermsContentCont>
          <MaxWidthWrap>
            <TermsTitleCont>
              <TitleSection>Términos y condiciones</TitleSection>
            </TermsTitleCont>

            {/* <TermsSubTitleCont>
              <h4>
                TÉRMINOS Y CONDICIONES GENERALES DE USO DE PORTAL Y SABORES CARD
                SPA (en adelante “SABORES CARD”)
              </h4>
            </TermsSubTitleCont> */}

            <TermsListCont>
              <TermsItemCont>
                <p>
                  El Emisor U-Pay Prepagos S.A. (en adelante “Emisor”), es quien
                  emite la tarjeta de pago con provisión de fondos, que emite la
                  tarjeta de alimentación “Sabores Card “(en adelante “Sabores
                  Card”), y es una sociedad anónima especial no bancaria,
                  constituida al amparo de la ley N° 20.950 y autorizada por la
                  Comisión para el Mercado Financiero o “CMF”, para emitir
                  tarjetas de pago con provisión de fondos.
                </p>

                <p>
                  Estos términos y condiciones (en adelante, los “Términos y
                  Condiciones”) regulan el acceso y/o uso por todo usuario (el
                  “Usuario” o “tú”) de los Servicios prestados por el Emisor, y
                  establecen los derechos, obligaciones y responsabilidades,
                  tanto de los Usuarios como de dicho Emisor, en relación con
                  los Servicios a los que se refieren estos Términos y
                  Condiciones. Asimismo, te hacemos presente que, para efectos
                  de estos <strong> Términos y Condiciones</strong>, al usar el
                  término “Tarjeta” nos referimos al instrumento, que permite a
                  su titular o usuario disponer de los recursos depositados en
                  una cuenta, para la adquisición de productos de alimentación,
                  sea que dicho instrumento (o Tarjeta) se emita de manera
                  física o solo se emita en forma virtual, como es el caso de la
                  Tarjeta de Alimentación Sabores Card.
                </p>

                <p>
                  Al aceptar estos <strong> Términos y Condiciones</strong>, ten
                  presente que:
                </p>

                <ul>
                  <li>
                    Obtendrás una cuenta de prepago asociada a una tarjeta
                    virtual, llamada Tarjeta Sabores Card, que podrás utilizar
                    únicamente a través de la aplicación mobile y plataforma del
                    Emisor y Holding U-Payments, donde deberás estar registrado
                    y previamente haber aceptado sus respectivos Términos y
                    Condiciones.
                  </li>

                  <li>
                    No te cobraremos comisiones por utilizar tu Tarjeta Sabores
                    Card.
                  </li>

                  <li>
                    Podrás solicitar en cualquier momento la restitución total o
                    parcial de los fondos provisionados en tu Tarjeta Sabores
                    Card, sin reajustes ni intereses.
                  </li>

                  <li>
                    Al usar tu Tarjeta Sabores Card, te sujetarás a la política
                    de privacidad y Cookies del emisor y Holding U-Payments.
                  </li>

                  <li>
                    Los presentes <strong>Términos y Condiciones</strong> serán
                    suscritos de forma remota a través de las aplicaciones
                    Mobile, Sin perjuicio de lo anterior, podrás acceder desde
                    la App a esta información y existirá una copia actualizada
                    publicada en la página web de Sabores Card{" "}
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="https://www.saborescard.cl"
                    >
                      www.saborescard.cl
                    </a>
                  </li>
                </ul>

                <p>
                  En caso de que aceptes estos{" "}
                  <strong>Términos y Condiciones</strong>, ello implicará que
                  celebraste un contrato con el Emisor, cuyos términos se
                  señalan en el presente instrumento. En virtud de tu
                  aceptación, y en la medida que cumplas con los requisitos que
                  se establecen en este documento y que la normativa aplicable
                  establezca, podrás usar y gozar de todos los derechos que te
                  otorgan estos <strong> Términos y Condiciones</strong>, además
                  de aquéllos que te reconoce la ley chilena. Pero también
                  deberás asumir y cumplir ciertas obligaciones que se
                  establecen en este documento, por lo que es importante que
                  entiendas y evalúes correctamente estos{" "}
                  <strong>Términos y Condiciones</strong> antes de aceptarlos.
                </p>

                <p>
                  El Emisor podrá modificar unilateralmente estos Términos y
                  Condiciones en cualquier momento por su sola decisión. En todo
                  caso, sólo modificará estos{" "}
                  <strong>Términos y Condiciones</strong> en la forma que aquí
                  se señala y no introducirá modificaciones que tengan efecto
                  retroactivo, sino que sólo podrá tener efectos hacia el
                  futuro.
                </p>

                <p>
                  En caso de que el Emisor modifique estos Términos y
                  Condiciones serás notificado de la modificación adoptada,
                  remitiéndote una copia actualizada de los Términos y
                  Condiciones, y solicitando tu aceptación cuando realices tu
                  primer ingreso en la aplicación Mobile Sabores Card, que
                  soporta los Servicios, una vez producido el cambio. Las
                  modificaciones a los <strong>Términos y Condiciones</strong>{" "}
                  entrarán en vigor 15 días corridos después de que te hayan
                  sido notificadas de acuerdo con lo indicado en el párrafo
                  anterior, salvo que el Emisor determine que entren en vigor en
                  un plazo mayor al señalado y así te lo notifique.
                </p>

                <p>
                  En caso de que no aceptes las modificaciones a los Términos y
                  Condiciones de acuerdo con lo antes indicado, deberás dar
                  término a la relación contractual entre tú y el Emisor, con lo
                  cual no podrás volver a usar los Servicios. En tal caso, de
                  haber saldos pendientes deberás comunicarte con el Emisor para
                  efectuar los ajustes y pagos correspondientes a la mayor
                  brevedad posible.
                </p>

                <p>
                  Deberás, en todo momento, dar un uso adecuado y lícito a los
                  Servicios, conforme estos{" "}
                  <strong>Términos y Condiciones</strong> señalan. Al aceptar
                  estos <strong> Términos y Condiciones</strong>, estarás
                  autorizado para acceder a los Servicios ofrecidos por el
                  Emisor y los contenidos relativos a la Tarjeta Alimentación
                  Sabores Card y Plataforma del holding U-Payments, tales como
                  software, textos, fotografías, imágenes, videos, marcas
                  comerciales, signos distintivos, y otros contenidos
                  protegibles por derechos de autor y privilegios industriales.
                  El uso que puedes hacer de tales contenidos, estén ellos
                  registrados o no, se limita al necesario para acceder a los
                  Servicios, y sólo para tu uso personal y privado, quedando
                  expresamente prohibido el uso con fines comerciales o para su
                  distribución, reproducción, transformación o des-compilación,
                  de cualquier forma, con cualquier alcance o por cualquier
                  modo, actual o futuro.
                </p>

                <p>
                  Te hacemos presente que la emisión y operación de medios de
                  pago con provisión de fondo por entidades no bancarias, como
                  es el caso del Emisor, se rige principalmente por las normas
                  contenidas en la ley N° 20.950 que autoriza emisión y
                  operación de medios de pago con provisión de fondo por
                  entidades no bancarias, en el Capítulo III.J.1 del Compendio
                  de Normas Financieras del Banco Central (“Compendio del Banco
                  Central”) y, especialmente, por las contenidas en su Capítulo
                  III.J.1.3, y por las normas establecidas en Circular N° 1 para
                  emisores no bancarios de tarjetas de pago, dictadas por la
                  CMF.
                </p>

                <p>
                  Infórmate sobre las entidades autorizadas para emitir Tarjetas
                  de Pago en el país, quienes se encuentran inscritas en los
                  Registros de Emisores de Tarjetas que lleva la CMF, en
                  www.cmfchile.cl
                </p>
              </TermsItemCont>

              <TermsItemCont>
                <h5>DEFINICIONES</h5>

                <ul>
                  <li>
                    <strong>Nosotros, Nos o Nuestro:</strong>Emisor U-Pay
                    Prepagos S.A. (en adelante “Emisor”), (en adelante
                    “EMISOR”), es quien emite Tarjeta de Alimentación Sabores
                    Card (en adelante “SABORES CARD”) y Holding U-Payments.
                  </li>

                  <li>
                    <strong>Usted o tú: </strong>Cualquier usuario/visitante del
                    sitio web y/o App.
                  </li>

                  <li>
                    <strong>Sitio web: </strong>El sitio web de SABORES CARD disponible en la dirección{" "}
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="https://www.saborescard.cl"
                    >
                      https://www.saborescard.cl
                    </a>
                  </li>

                  <li>
                    <strong>Holding U-Payments: </strong>U-Payments Company
                    Ltda. (Holding), Sabores Card Spa (Tarjeta de Alimentación),
                    U-Pay Prepagos S.A. (Emisor), Universal Payments Spa.
                    (Software Factory), U-Pay Processing Spa (Procesador
                    Transaccional).
                  </li>

                  <li>
                    <strong>Tarjeta Habiente: </strong>Persona natural a quien
                    se le emite la Tarjeta de Alimentación para que la use en
                    compras del rubro Alimentación.
                  </li>

                  <li>
                    <strong>CPF: </strong>Cuenta de Provisión de Fondos-
                  </li>
                </ul>
              </TermsItemCont>

              <TermsItemCont>
                <h5>APERTURA DE TARJETA DE PAGO CON PROVISIÓN DE FONDOS</h5>

                <p>
                  <strong>Tarjeta digital Sabores Card</strong>
                </p>

                <p>
                  Mediante la aceptación de estos{" "}
                  <strong>Términos y Condiciones</strong>, el Emisor pone a tu
                  disposición una tarjeta de pago con provisión de fondos o
                  Tarjeta de alimentación Sabores Card (en adelante “Sabores
                  Card”), emitida en forma virtual, a tu nombre, para que
                  utilices como medio de pago en la red de establecimientos
                  afiliados. Al contar con la Tarjeta virtual Sabores Card,
                  significa que no recibirás una tarjeta física para operar,
                  sino que esta será emitida de forma virtual y podrás hacer uso
                  de ella por medios electrónicos o digitales.
                </p>

                <p>
                Las Tarjetas Sabores Card, son nominativas y son recargadas 
                por tu empleador (cliente empresa) al menos una vez al mes, 
                en pesos chilenos, hasta por los montos y límites a que se 
                refiere el punto: “
                  <strong>USO DE TARJETA ALIMENTACIÓN SABORES CARD</strong>“, 
                  del presente documento.
                </p>

                <p>
                Las Tarjetas Sabores Card, las puedes utilizar para compras, solo en el rubro de alimentación, con la cual el usuario (tarjetahabiente) realizará compras con el beneficio de alimentación que su empleador (cliente empresa) pondrá a su disposición. Podrás utilizarla para la adquisición de alimentos prestados por los establecimientos comerciales digitales del rubro alimentación que acepten este medio de pago Mastercard.
                </p>

                <p>
                  El Emisor podrá habilitar adicionalmente la realización de
                  compra presencial, emisión de tarjetas físicas, o cualquier
                  otra modalidad de uso de la Tarjeta Sabores Card que de
                  conformidad a estos <strong> Términos y Condiciones</strong> y
                  a la normativa vigente sea aplicable.
                  <br />
                  Te informaremos oportunamente de las nuevas modalidades de uso
                  que se implementen, las que te serán aplicables sólo cuando
                  las aceptes expresamente.
                </p>

                <p>
                  <strong>
                  Cuenta de provisión de fondos Tarjeta Alimentación Sabores Card
                  </strong>
                </p>

                <p>
                  Mediante la aceptación de estos{" "}
                  <strong>Términos y Condiciones</strong>, autorizas y encargas
                  al Emisor a que abra y habilite en tu nombre, una Cuenta de
                  Provisión de Fondos (en adelante, la “CPF”) que tendrá por
                  objeto exclusivo la recepción de los fondos destinados a
                  provisionar la Tarjeta Sabores Card. El saldo disponible en la
                  CPF sólo podrá ser destinado por el Emisor a efectuar los
                  pagos que se ordenen mediante el uso de tu Tarjeta Sabores
                  Card, al cargo de las comisiones que pudieran proceder a
                  futuro. Emisor también puede a efectuar reembolso de los
                  recursos recibidos por el cliente empresa al cliente empresa.
                </p>

                <p>
                  Te hacemos presente que, a esta fecha, Sabores Card, no cobra
                  comisiones a sus usuarios por los servicios prestados. En el
                  caso que los servicios sean recargados por una comisión, te
                  informaremos previamente y sólo te serán aplicables al aceptar
                  expresamente la modificación en tal sentido de estos Términos
                  y Condiciones.
                </p>

                <p>
                  El saldo o disponibilidad efectiva para realizar pagos con tu
                  Tarjeta Sabores Card corresponderá al monto neto de los abonos
                  y cargos registrados en tu CPF. La CPF no considera una línea
                  de crédito ni puede sobregirarse, por lo que, si el saldo
                  disponible es insuficiente para una determinada transacción,
                  ésta no será realizada.
                </p>
              </TermsItemCont>

              <TermsItemCont>
                <h5>USO DE TARJETA SABORES CARD</h5>

                <p>
                  <strong>On-boarding y acceso</strong>
                </p>

                <p>
                  El Emisor siempre podrá solicitarte información adicional y
                  documentos que respalden la información entregada para efectos
                  de tu registro, el cual podrá ser denegado, suspendido o
                  cancelado en caso de que el Emisor considere que no cumplas
                  los requisitos mínimos como Usuario, detecte inconsistencias o
                  existan indicios de actividades sospechosas o contrarias a las
                  leyes de Chile o de cualquier otra jurisdicción, a estos <strong>Términos y Condiciones</strong> o a las Políticas de
                  Sabores Card.
                </p>

                <p>
                  Toda la información que proporciones para tu registro como
                  usuario se entenderá que la estás entregando en carácter de
                  declaración jurada, con tu compromiso de mantenerla
                  actualizada y de informar lo antes posible de cualquier cambio
                  en tu información personal y de contacto. Debido a ello, no
                  nos responsabilizamos por la veracidad y exactitud de la
                  información que proporciones para estos efectos.
                </p>

                <p>
                  El tratamiento que haremos de tus datos personales junto con
                  los derechos que te asisten en relación con la autorización
                  que nos entregas para tratar tus datos personales están
                  regulados en la Política de Privacidad del Emisor, que se
                  encuentra al final de este documento. Te invitamos a revisar
                  dicha Política detenidamente y a consultarnos cualquier duda
                  que puedas tener al respecto.
                  <br />
                  El Emisor solo efectuará el tratamiento de tus datos
                  personales cuando cuente con tu autorización o la ley le
                  reconozca otra base de legalidad para tratar tus datos y,
                  únicamente para los fines que se indican en la Política de
                  Privacidad. Tu autorización podríamos obtenerla a través de
                  cualquier medio que pueda ser objeto de consulta posterior,
                  tales como la página web, formularios, formatos, actividades,
                  concursos (presenciales o en redes sociales), mensajes de
                  datos o aplicación mobile.
                </p>

                <p>
                  Toda operación que se realice utilizando tus datos y claves de
                  acceso en aplicación mobile de Sabores Card, será considerada
                  realizada por ti, salvo que previamente nos hayas comunicado
                  que tu identificación, firma electrónica, cuenta de Usuario,
                  contraseña, cualquier otra medida de autenticación que se
                  implemente para el uso de los servicios, o tu dispositivo
                  móvil asociado, han sido extraviados o podrían estar siendo
                  utilizados por un tercero no autorizado, en cuyo caso,
                  activaremos los protocolos de seguridad indicados más
                  adelante.
                </p>

                <p>
                  El Emisor cumplirá las instrucciones que solicites a través
                  email{" "}
                  <a href="mailto:contacto@saborescard.cl">
                    contacto@saborescard.cl
                  </a>
                  , a través de chatbot en aplicación mobile o línea telefónica
                  indicada en sitio web., instrucciones que serán consideradas
                  mandatos para todos los efectos legales, quedando el Emisor
                  investido de las facultades necesarias para cumplirlas y
                  efectuar, por consiguiente, los cargos o abonos que procedan.
                  Dicho mandato se entenderá conferido por cada orden de pago, y
                  estará sujeto, en todos sus aspectos, a lo dispuesto en estos
                  <strong> Términos y Condiciones</strong>. Todos los mandatos
                  otorgados en este instrumento se entenderán revocados por
                  haberse producido el término del presente contrato.
                </p>

                <p>
                  Al suscribir estos <strong> Términos y Condiciones</strong>,
                  aceptas que será suficiente rendición de cuentas por parte del
                  Emisor, el envío del estado de cuenta de tu CPF, dentro de los
                  primeros 15 días de cada mes, respecto de las transacciones
                  realizadas con tu Tarjeta Sabores Card, en el mes
                  inmediatamente anterior a la emisión del correspondiente
                  estado de cuenta, el que te será remitido al correo
                  electrónico que mantengas registrado con nosotros. En el caso
                  que, de conformidad a la normativa aplicable el Emisor quede
                  autorizado para dejar de emitir dicho estado de cuenta,
                  autorizas desde ya al Emisor para ello.
                </p>

                <p>
                  En todo caso, siempre podrás acceder al registro de
                  transacciones que conste en tu sesión como usuario a través de
                  la aplicación mobile y solicitarnos, por medio de nuestro
                  canal de atención al cliente, un resumen de tus movimientos,
                  saldos y cualquier otra información relacionada con el uso de
                  tu Tarjeta Sabores Card.
                </p>

                <p>
                  La Tarjeta Sabores Card no permite giros en cajeros
                  automáticos, transferencias bancarias ni otro tipo de
                  transacción, que no se compras en rubro de alimentación, al
                  ser una tarjeta virtual, sin la aplicación mobile, no podrás
                  acceder a los servicios, y, por tanto, las transacciones que
                  desees realizar con cargo a tu CPF no podrían materializarse.
                </p>

                <p>
                  <strong>Abonos y cargos a tu CPF</strong>
                </p>

                <p>
                Las transacciones con cargo a tu Tarjeta Sabores Card, son compras, es decir las puedes utilizar para compras, solo en el rubro de alimentación, con la cual el usuario (tarjetahabiente) realizará compras con el beneficio de alimentación que su empleador (cliente empresa) pondrá a su disposición. Podrás utilizarla para la adquisición de alimentos prestados por los establecimientos comerciales digitales del rubro alimentación que acepten este medio de pago Mastercard, siempre y cuando cuentes con saldo suficiente en tu cuenta CPF.{" "}
                </p>

                <p>
                  Otras transacciones de cargos como: giros en cajeros
                  automáticos, instrucción de transferencias electrónicas de
                  fondos a otras cuentas corrientes bancarias, cargo a otras CPF
                  abiertas por el mismo Emisor, entre otros, no están permitidas
                  para este producto en particular, que es la tarjeta de
                  alimentación Sabores Card.
                </p>

                <p>
                  Las transacciones con abono, a tu Tarjeta Sabores Card, son recargas realizadas sólo por tu empleador (cliente empresa), que suscribió el acuerdo con nosotros, para entregarte como beneficio la Tarjeta de alimentación Sabores Card. Estas recargas o abonos tu empleador las realiza al menos una vez al mes, en pesos chilenos, hasta por los montos y límites a que se refiere el punto: “Límites de Uso “, del presente documento.
                </p>

                <p>
                  Otras transacciones de abonos como: instrucción de
                  transferencias electrónicas de fondos desde otras cuentas
                  corrientes bancarias, abono desde otras CPF abiertas por el
                  mismo Emisor, entre otros, no están permitidas para este
                  producto en particular, que es la tarjeta de alimentación
                  Sabores Card.
                </p>

                <p>
                  Los montos que abones en la CPF serán en pesos chilenos, y no
                  generarán reajustes ni intereses. Podremos pedirte
                  antecedentes acerca del origen de los fondos provisionados en
                  tu CPF, así como tu residencia fiscal. En tal caso, debes
                  proporcionar información auténtica, fidedigna, completa y
                  actualizada.
                </p>

                <p>
                  El Emisor responderá en todo momento por los recursos captados
                  cuyo saldo se registre en tu CPF.
                </p>

                <p>
                  <strong>Determinación del Saldo Provisionado</strong>
                </p>

                <p>
                  Podrás informarte permanentemente del saldo disponible en tu
                  CPF y del resumen de movimientos realizados, desde la
                  aplicación mobile de Sabores Card y los demás canales que el
                  Emisor habilite para ello. A estos efectos, el Emisor
                  determinará al menos diariamente, el saldo resultante entre
                  cargos y abonos efectuados con la Tarjeta Sabores Card y
                  registrados en la CPF.{" "}
                </p>

                <p>
                  Si deseas realizar una transacción con tu Tarjeta Sabores
                  Card, el saldo disponible debe ser suficiente para cubrirla,
                  puesto que, si es inferior, dicha transacción no podrá
                  realizarse.
                </p>

                <p>
                  <strong>Retiros del Saldo Provisionado</strong>
                </p>

                <p>
                  No podrás realizar retiros de tú tarjeta de alimentación, ya que es un beneficio que te brinda tu empleador a través de la Tarjeta de Alimentación sabores Card. Tu saldo acumulado lo puedes gastar en comercios digitales del rubro alimentación.
                </p>

                <p>
                  En caso de desvinculación, podrás seguir haciendo uso de tu tarjeta de alimentación Sabores Card, por un plazo de 180 días desde la fecha de tu desvinculación. 
                </p>

                <p>
                  El cliente empresa (empleador), previo acuerdo o pacto
                  contigo, según en cumplimiento de la normativa de la
                  legislación Chilena que se enmarca en la vinculación legal
                  entre empleador y empleado, podría solicitar al Emisor,
                  retirar la totalidad o parte de los fondos provisionados en tu
                  CPF, sin reajustes ni intereses (conforme lo establecen la Ley
                  N° 20.950 y el Capítulo III.J.1.3 del Compendio del Banco
                  Central), ya sea abonando el saldo disponible en una cuenta
                  corriente bancaria nacional, en una cuenta a la vista o en
                  otra cuenta con provisión de fondos, o bien, a través de otros
                  medios que a futuro pueda disponer el Emisor para efectuar
                  dicha operación. La restitución se efectuará de conformidad a
                  estos <strong> Términos y Condiciones</strong>, una vez
                  deducidas las comisiones que procedieren y cualquier otro
                  saldo adeudado al Emisor. El Cliente empresa sólo podrás
                  retirar hasta el saldo de dinero previamente provisionado en
                  tu CPF.
                </p>

                <p>
                  <strong>Límites de uso</strong>
                </p>

                <p>
                  Podrás acumular saldos en la CPF que superen el límite
                  señalado en el Compendio del Banco Central para Tarjetas
                  Nominativas, ya que el Emisor cuenta en su aplicación Mobile,
                  medios de autenticación remota que de conformidad al Compendio
                  del Banco Central. No obstante, lo anterior, no constituye en
                  ningún caso una obligación para el Emisor, ni podrá ser
                  exigido de forma alguna por el Usuario, quien desde ya acepta
                  regirse por los límites máximos que, en conformidad a la
                  normativa aplicable, determine el Emisor.
                </p>

                <p>
                  Asimismo, a objeto de proteger tus transacciones y de evitar
                  fraudes, el Emisor podrá implementar otros límites de uso de
                  la Tarjeta Sabores Card, tales como límite de transacciones
                  diarias, montos máximos por transacción, u otros montos
                  máximos, que se indican a continuación:
                </p>

                <table>
                  <tbody>
                    <tr>
                      <td colSpan={2}>
                        <strong>Límites de Autorización Transaccional</strong>
                      </td>
                    </tr>

                    <tr>
                      <td>Monto autorización mínimo-diaria</td>
                      <td>$1</td>
                    </tr>

                    <tr>
                      <td>Monto autorización máximo diario:</td>
                      <td>Sin límite</td>
                    </tr>

                    <tr>
                      <td colSpan={2}>
                        <strong>Límites de transacciones de Compras</strong>
                      </td>
                    </tr>

                    <tr>
                      <td>Tope diario en Pesos:</td>
                      <td>Sin límite</td>
                    </tr>

                    <tr>
                      <td>Tope mensual en Pesos:</td>
                      <td>Sin límite</td>
                    </tr>

                    <tr>
                      <td>Tope cantidad de transacciones diarias: </td>
                      <td>Sin límite</td>
                    </tr>

                    <tr>
                      <td>Tope cantidad de transacciones mensual: </td>
                      <td>Sin límite</td>
                    </tr>

                    <tr>
                      <td colSpan={2}>
                        <strong>
                          Límites de transacciones de abono (Carga mensual)
                        </strong>
                      </td>
                    </tr>

                    <tr>
                      <td>Tope diario en Pesos: </td>
                      <td>$ 500.000</td>
                    </tr>

                    <tr>
                      <td>Tope mensual en Pesos: </td>
                      <td>$ 500.000</td>
                    </tr>

                    <tr>
                      <td>Tope cantidad de transacciones diarias: </td>
                      <td>5</td>
                    </tr>

                    {/* <tr>
                      <td>Tope cantidad de transacciones mensual: </td>
                      <td>10</td>
                    </tr> */}
                  </tbody>
                </table>

                <br />

                <p>
                  <strong>Reversiones, Contracargos y Cancelaciones</strong>
                </p>

                <p>
                  Al recibir un pago a través de la aplicación mobile de Tarjeta
                  de alimentación Sabores Card, es posible que se deban efectuar
                  reversiones, contracargos, cancelaciones o desconocimientos en
                  la operación debido a errores u omisiones involuntarias. Por
                  el hecho de suscribir estos{" "}
                  <strong>Términos y Condiciones</strong> aceptas desde ya que
                  podemos efectuar tales actuaciones para efectos de subsanar
                  los errores u omisiones involuntarias que puedan producirse.
                </p>

                <p>
                  En caso de que, por cualquier motivo, se revierta una
                  operación por la cual se hubieren abonado fondos a tu CPF, y
                  esos fondos ya hubieren sido utilizados por ti en una o más
                  órdenes de pago, el Emisor tendrá derecho a exigirte la
                  devolución de los fondos respectivos y el pago de cualquier
                  otro cargo que haya sufrido como consecuencia de la reversión.
                </p>

                <p>
                  Si no cumplas con tu obligación de abonar dicho monto dentro
                  del plazo de 2 días hábiles siguientes a la reversión,
                  contracargo, cancelación o desconocimiento en el pago, se
                  entenderá que autorizas irrevocablemente al Emisor a efectuar
                  descuentos de los futuros ingresos que recibas mediante la
                  aplicación mobile de tarjeta de alimentación Sabores Card,
                  hasta el pago total de la deuda respectiva, incluyendo
                  capital, intereses y costas. Según corresponda. Lo anterior,
                  en todo caso, no impedirá ni será obstáculo para que el Emisor
                  pueda ejercer todas las acciones legales que estén a su
                  alcance para cobrar la suma adeudada como consecuencia de la
                  reversión, contracargo, cancelación o desconocimiento en el
                  pago.
                </p>

                <p>
                  <strong>Caducidad de Depósitos</strong>
                </p>

                <p>
                  Los fondos mantenidos en la CPF estarán sujetos al sistema de
                  caducidad de depósitos, captaciones y acreencias previsto en
                  el artículo 8° de la Ley N° 20.950 y el artículo 156 de la Ley
                  General de Bancos.
                </p>
              </TermsItemCont>

              <TermsItemCont>
                <h5>POLÍTICAS DE SEGURIDAD</h5>

                <p>
                  Para el acceso y operación de la CPF y la Tarjeta Sabores
                  Card, deberás seguir los procedimientos y mecanismos de
                  identificación, autenticación y seguridad que el Emisor
                  hubiere dispuesto para cada canal, tales como número de
                  usuario, clave secreta y/o dinámica, doble factor de
                  autentificación u otros mecanismos de autenticidad que el
                  Emisor implemente a futuro, de conformidad con las mejores
                  prácticas del mercado y las disposiciones legales y
                  regulatorias respectivas. Cualquier modificación en tales
                  procedimientos y mecanismos, te serán previamente informados
                  por el Emisor a través de la aplicación mobile Sabores Card.
                </p>

                <p>
                En caso de extravío, hurto, robo, pérdida, adulteración o falsificación de tu Tarjeta Sabores Card, o cualquier otra circunstancia similar que afecte tu Tarjeta Sabores Card, los medios de autenticación dispuestos por el Emisor para su uso, o el acceso mediante el dispositivo móvil en que operes con la aplicación mobile de Sabores Card, debes dar Aviso Inmediato al Emisor a través de nuestra línea telefónica <strong>800 360 105</strong> habilitada para este fin, en el caso de contar con tu teléfono móvil asociado a tu tarjeta digital Sabores Card puedes bloquear directamente en la aplicación en opción bloqueo permanente y contactarnos a través del chatbot de atención incorporado en la aplicación mobile, o de los demás canales que a futuro Sabores Card disponga para tales efectos. Si no hemos recibido aviso oportuno, no podremos tomar medidas al respecto, ni seremos responsables por las operaciones que se realicen de manera fraudulenta.
                </p>

                <p>
                  El Emisor adoptará las medidas necesarias y prudentes para
                  resguardar la seguridad de tus datos y de tu clave secreta.
                  Sin perjuicio de lo anterior, los sistemas de seguridad
                  requieren cooperación y buen uso de sus elementos, por lo que
                  es tu obligación como Usuario manejarlos de manera responsable
                  y con la debida diligencia y cuidado, y dando un uso adecuado
                  y lícito a los mismos.
                </p>

                <p>
                  Debes siempre resguardar la seguridad de tu Tarjeta Sabores
                  Card, bajo tu custodia personal y mantener tus claves y medios
                  de autenticación en carácter de estrictamente confidencial,
                  sin revelarlas a terceros.
                </p>

                <p>
                  Es importante que entiendas que las operaciones que se
                  realicen con tu identificación de Usuario y en conformidad a
                  las medidas de autenticación dispuestas, tales como tu clave
                  secreta, constituyen una firma electrónica y producen el mismo
                  efecto que tu firma manuscrita y, por tanto, son un mecanismo
                  de aceptación por medios remotos que permite identificarte al
                  menos formalmente como su autor, para todos los efectos
                  legales y contractuales a que hubiere lugar. Por tanto, será
                  de tu responsabilidad todo perjuicio derivado del mal uso o la
                  utilización errónea de tu Usuario y de las claves de seguridad
                  asociadas, a menos que hubieres informado personal y
                  previamente del hecho de su pérdida o sustracción de
                  conformidad a lo establecido en estos{" "}
                  <strong>Términos y Condiciones</strong>.
                </p>

                <p>
                  En caso de detectarse cambios en la información que has
                  registrado en la aplicación mobile Sabores Card, o bien, ante
                  cualquier irregularidad en las transacciones relacionadas con
                  tu identificación o la del medio de pago, o simplemente como
                  medida de protección a tu identidad ante operaciones o
                  movimientos relacionadas con patrones de fraude o que por su
                  particularidad hagan presumir riesgo en los intereses del
                  Usuario y/o de Sabores Card, podremos suspender o bloquear
                  temporalmente y en forma total o parcial, los Servicios y tu
                  acceso a tu tarjeta Sabores Card, con la finalidad de
                  corroborar la legitimidad de los movimientos sospechosos.
                </p>

                <p>
                  Sin perjuicio de lo anterior, al aceptar estos Términos Y
                  Condiciones autorizas al Emisor para proceder al cierre o
                  suspensión de los Servicios con el fin de prevenir usos
                  indebidos o fraudulentos de éstos. Sólo a modo de ejemplo, se
                  entienden incluidos como usos indebidos o fraudulentos, las
                  transacciones que no digan relación con la finalidad para la
                  cual fue otorgada la Tarjeta Sabores Card; las transacciones
                  que, en base a la información que el Emisor tenga de su
                  Usuario, pudiesen representar operaciones carentes de
                  justificación económica; las conductas de un Usuario que
                  representen un riesgo de imagen para sabores Card; las
                  transacciones respecto de las cuales exista alguna denuncia
                  seria y fundada por parte de un tercero; entre otras. En estos
                  casos, el Emisor podrán también marcar o abortar operaciones,
                  bloquear accesos a canales, bloquear cuentas, retener fondos,
                  entre otros.
                </p>

                <p>
                  Ante una situación como las señaladas anteriormente, nuestros ejecutivos podrían contactarte por vía telefónica o correo electrónico <strong>contacto@saborescard.cl</strong>, a fin de corroborar tus datos e intentar evitar posibles fraudes.
                </p>

                <p>
                  A este respecto, es importante que entiendas que para
                  suspender o bloquear temporalmente y en forma total o parcial
                  los Servicios, se suspenderá tu acceso a la aplicación mobile
                  de Sabores Card que los soporta y, por tanto, NO podrás hacer
                  uso de tu Tarjeta Sabores Card.
                </p>

                <p>
                  El uso de Sabores Card, es bajo tu exclusiva responsabilidad y
                  riesgo. Es responsabilidad del Usuario obtener los elementos
                  necesarios para el adecuado uso de la Tarjeta Sabores Card,
                  tales como el equipo o dispositivo móvil, los programas o
                  aplicaciones que permiten el funcionamiento de éstos y el
                  correspondiente acceso a internet.
                </p>

                <p>
                  Sabores Card opera sobre una infraestructura electrónica de
                  acceso remoto vía internet, su acceso permanente no puede ser
                  garantizado. Si bien el Emisor tiene por política adoptar
                  medidas para resguardar la continuidad y adecuado
                  funcionamiento de los servicios y de los sistemas que lo
                  soportan, por su naturaleza éstos pueden verse expuestos a
                  problemas técnicos que afecten su normal ejecución,
                  contingencia a que se está expuesto cualquiera sea el
                  administrador o proveedor de dichas herramientas y que no
                  desaparece en virtud de la aceptación de los presentes
                  <strong> Términos y Condiciones</strong>. Por lo mismo, el
                  Emisor no asume obligación ni responsabilidad alguna, por
                  cualquier interrupción o desperfecto en su funcionamiento,
                  problemas de conexión, falta de cobertura o cobertura
                  intermitente que no sea imputable al Emisor.
                </p>

                <p>
                  El Emisor podrá interrumpir o suspender temporalmente los
                  Servicios cuando deban efectuarse mantenciones a los sistemas
                  o medie caso fortuito o existan circunstancias graves que
                  requieran de tales medidas a objeto de proteger el interés del
                  Usuario o evitar fraudes. No será de responsabilidad del
                  Emisor las interrupciones, alteraciones o errores que
                  provengan de caso fortuito o fuerza mayor. Sin perjuicio de lo
                  anterior, cualquier interrupción o suspensión no programada te
                  será informada por el Emisor dentro de un plazo razonable
                  desde que hubiere ocurrido. Respecto de interrupciones o
                  suspensiones programadas, éstas serán informadas previamente.
                </p>
              </TermsItemCont>

              <TermsItemCont>
                <h5>COMISIONES Y GASTOS</h5>

                <p>
                  Se deja constancia de que, a esta fecha, no existen
                  comisiones, impuestos ni gastos que sean de tu cargo. Sin
                  embargo, en la eventualidad que el Emisor establezca cobros de
                  comisiones por operaciones, o en el futuro la normativa
                  aplicable establezca gastos y/o impuestos que deriven del
                  otorgamiento de este contrato o de las operaciones que en él
                  se describen; te informaremos de tales comisiones, gastos y/o
                  impuestos, y solicitaremos de tu consentimiento expreso para
                  realizar una operación en que sean aplicables. En caso de que
                  no aceptes la modificación a los{" "}
                  <strong>Términos y Condiciones</strong> según lo antes
                  indicado, no podrás acceder a los servicios y entenderemos que
                  manifiestas tu intención de poner término al presente
                  contrato.
                </p>

                <table>
                  <tbody>
                    <tr>
                      <td colSpan={2}>
                        <strong>
                          Costos o Recargos por transaccional de compras
                        </strong>
                      </td>
                    </tr>

                    <tr>
                      <td>Compras Nacionales por cada transacción</td>
                      <td>0%</td>
                    </tr>

                    <tr>
                      <td colSpan={2}>
                        <strong>Costos o Recargos por emisión</strong>
                      </td>
                    </tr>

                    <tr>
                      <td>Emisión Tarjeta Digital por cada emisión (*)</td>
                      <td>$ 0</td>
                    </tr>
                  </tbody>
                </table>

                <p>
                  (*) Las emisiones de Tarjeta Digital Sabores Card, están
                  sujetas a la autorización de la empresa quien contrata el
                  benefició de tarjeta alimentación Sabores Card para sus
                  colaboradores.
                </p>
              </TermsItemCont>

              <TermsItemCont>
                <h5>VIGENCIA DEL CONTRATO Y TÉRMINO ANTICIPADO</h5>
                <p>
                  <strong>Vigencia del Contrato</strong>
                </p>
                <p>
                  El contrato que surge entre el Emisor y tú una vez aceptados
                  estos <strong> Términos y Condiciones</strong>, tendrá vigencia
                  hasta el día 31 de diciembre del año siguiente al de su
                  aceptación, y se renovará tácita, automática y sucesivamente
                  por nuevos periodos anuales, salvo que cualquiera de las
                  partes ponga en conocimiento de la otra su intención de no
                  renovarlo, de conformidad a lo señalado en esta sección.
                </p>

                <p>
                  Para comunicar tu decisión de no renovar el contrato, deberás
                  dar aviso al Emisor a través de los canales de tu empleador,
                  que generalmente es el área de beneficios de la herencia de
                  Recursos Humanos, ellos se comunicaran con el Emisor e
                  indicaran la instrucción según corresponda, si existen fondos
                  de por medio, estos serán devueltos íntegramente a tu
                  empleador, es decir al cliente empresa.
                </p>

                <p>
                  En caso de que el Emisor decidiera no renovar el contrato al vencimiento de este o de cualquiera de sus prórrogas, te comunicará este hecho mediante aviso enviado con al menos 15 días de anticipación, solicitando adicionalmente que gastes tus fondos de la CPF, en caso de haberlos y que dispondrás de 180 días para realizarlo.
                </p>

                <p>
                  Sin perjuicio de lo anterior, tanto el cliente empresa como el
                  Emisor podrán, en cualquier momento, poner término al contrato
                  de forma anticipada, en los casos y de conformidad al acuerdo
                  comercial suscrito entre las partes.
                </p>

                <p>
                  Te hacemos presente que, debido a que la Tarjeta Sabores Card
                  es una tarjeta virtual que funciona a través de una aplicación
                  mobile, si por cualquier causa se pusiera término al contrato
                  que suscribas para acceder y utilizar la aplicación mobile
                  Sabores Card, no podrás hacer uso de tu Tarjeta Sabores Card,
                  sin embargo, esta seguirá vigente hasta que se dé Término a tu
                  CPF por orden de tu empleador (cliente empresa).
                </p>

                <p>
                  En caso de que te desvincules de tu empleador, quien es el que contrata para ti, el beneficio de Alimentación se te comunicará mediante aviso, que gastes tus fondos de la CPF, en caso de haberlos y que dispondrás de 180 días para poder gastarlos o según lo convenido legalmente con tu empleador, quien puede saldar o conceder, los saldos en tu finiquito o mantener tu tarjeta de alimentación por los siguientes 180 días.
                </p>

                <p>
                  <strong>Término anticipado por parte del Emisor</strong>
                </p>

                <p>
                  A su vez, el Emisor podrá poner término anticipado al contrato
                  si:
                </p>

                <ul>
                  <li>
                    No dieras cumplimiento íntegro y oportuno a todas y cada una
                    de las obligaciones para con el Emisor, reservándose el
                    Emisor el derecho de reclamar los daños y perjuicios que tal
                    incumplimiento le haya causado;
                  </li>

                  <li>
                    Se detecta por el Emisor omisiones o falsedades en las
                    informaciones proporcionadas al suscribir estos Términos y
                    Condiciones, o durante la ejecución de los Servicios que en
                    virtud de ellos contratas;
                  </li>

                  <li>
                    Se detecta por el Emisor una actuación fraudulenta o ilícita
                    relacionada con la ejecución del contrato, o se hace uso
                    fraudulento o mal uso en cualquier forma de la Tarjeta
                    Sabores Card y la CPF asociada y sus fondos, y/o de
                    cualquier producto, aplicación, plataforma, canal o medio
                    tecnológico habilitado por el Emisor en relación con el
                    presente contrato. Se entenderá que hay un mal uso si los
                    productos son utilizados para comisión de crímenes, delitos
                    o simples delitos, o si el Cliente infringiere cualquier
                    norma legal o reglamentaria aplicable; con el fin de dar
                    cumplimiento a lo dispuesto en la Ley Nº20.393.
                  </li>

                  <li>
                    No acreditas oportuna y debidamente el origen de los fondos
                    depositados, entregados o puestos a disposición del Emisor
                    habiendo sido solicitado por éste; o si, de cualquier otra
                    forma, no cumples con las solicitudes que el Emisor
                    razonablemente te efectúe a fin de dar cumplimiento a la
                    normativa sobre prevención de lavado de activos y
                    financiamiento del terrorismo;
                  </li>

                  <li>
                    Incurres en conductas que sean constitutivas de agravio,
                    ofensa, malos tratos, insultos, violencia o en cualquier
                    otra forma produzcan una afectación a la integridad física o
                    psíquica a cualquier persona que dependa o preste servicios
                    al Emisor;
                  </li>

                  <li>
                    Solicitas acogerte a un procedimiento de liquidación
                    voluntaria, o uno o más acreedores solicitan tu liquidación
                    forzosa, y en general en todos aquellos casos en los que
                    tengas la calidad de deudor en un procedimiento concursal de
                    liquidación regulado en la ley 20.720 o aquellas que en el
                    futuro la modifiquen o reemplacen;
                  </li>

                  <li>
                    El Emisor toma conocimiento del fallecimiento del Usuario
                    titular, sin perjuicio de los derechos que le asisten a los
                    herederos; o si el Usuario fuere declarado interdicto;
                  </li>

                  <li>
                    Revocas cualquier autorización o mandato que hayas conferido
                    al Emisor para prestar adecuadamente los servicios
                    contratados;
                  </li>

                  <li>
                    Mantienes inactivos los Servicios de que dan cuenta estos
                    <strong> Términos y Condiciones</strong>, durante un plazo
                    superior a 2 años; o en el mismo plazo, no actualices la
                    aplicación Sabores Card o cualquier producto, aplicación,
                    plataforma, canal o medio tecnológico habilitado por Sabores
                    Card para la prestación de los Servicios contratados;
                  </li>

                  <li>
                    No aceptes las modificaciones a estos{" "}
                    <strong>Términos y Condiciones </strong>
                    que el Emisor te haya informado;
                  </li>

                  <li>
                    Se verifica cualquier otra causal de término que la
                    normativa legal y reglamentaria vigente contemple.
                  </li>
                </ul>

                <p>
                  En estos casos, el Emisor te comunicará el término anticipado
                  mediante aviso enviado con al menos 15 días de anticipación,
                  solicitando adicionalmente el retiro de tus fondos de la CPF,
                  en caso de haberlos. Lo anterior es sin perjuicio de la
                  facultad del Emisor para proceder a la suspensión inmediata de
                  uno o más de los Servicios o funcionalidades asociadas a la
                  Tarjeta y la CPF, con la finalidad de prevenir usos indebidos
                  o fraudulentos de éstos.
                </p>

                <p>
                  <strong>Efectos</strong>
                </p>

                <p>
                  El término del contrato tendrá como consecuencia que tu CPF
                  será cerrada en forma definitiva e irrevocable, y
                  deshabilitada la Tarjeta Sabores Card emitida a tu nombre. Si
                  a ese momento existieren saldos disponibles en tu CPF, estos
                  deberán ser retirados de conformidad a lo señalado en la
                  sección: “<strong>Retiros del Saldo Provisionado</strong>”, de
                  estos Términos Y Condiciones.
                </p>
              </TermsItemCont>

              <TermsItemCont>
                <h5>COMUNICACIONES</h5>

                <p>
                  Las notificaciones y cualquiera otra comunicación que conforme
                  al presente contrato debamos realizarte, serán enviadas a tu
                  correo electrónico registrado en la aplicación mobile Sabores
                  Card, y se presumirán recibidas al día hábil siguiente de
                  haber sido enviadas. Debido a lo anterior, te reiteramos la
                  importancia de mantenernos permanentemente actualizados de tu
                  dirección de correo electrónico.
                </p>

                <p>
                  Salvo que se haya señalado una forma de notificación especial
                  en estos <strong> Términos y Condiciones</strong>, cualquier
                  notificación que desees ejercer al Emisor, la podrás realizar
                  a través del sitio web de la línea telefónica{" "}
                  <strong>600 3600 405</strong>, correo electrónico{" "}
                  <a href="mailto:contacto@saborescard.cl">
                    contacto@saborescard.cl
                  </a>
                  , chatbot de la aplicación mobile sabores Card o de otros
                  medios que el Emisor implemente.
                </p>

                <p>
                  Sin perjuicio de lo anterior, en caso de que tengas cualquier
                  duda o problema en relación con estos{" "}
                  <strong>Términos y Condiciones</strong>, podrás comunicarte
                  con nosotros por medio de nuestro canal de atención al
                  cliente, línea telefónica <strong>600 3600 405</strong>,
                  correo electrónico{" "}
                  <a href="mailto:contacto@saborescard.cl">
                    contacto@saborescard.cl
                  </a>
                  , chatbot de la aplicación mobile sabores Card o de otros
                  medios que el Emisor implemente.
                </p>
              </TermsItemCont>

              <TermsItemCont>
                <h5>DOMICILIOS</h5>

                <p>
                  Para todos los efectos que legales derivados de estos Términos
                  y Condiciones, el domicilio que nos hayas comunicado al
                  efectuar tu registro como Usuario, se entenderá el aplicable
                  para efectos del contrato que se celebra una vez aceptados
                  estos <strong> Términos y Condiciones</strong>. Lo anterior,
                  salvo que, con posterioridad a dicho registro, nos hayas
                  notificado un domicilio distinto. En tal evento, aplicará el
                  último domicilio que nos hayas notificado.
                </p>

                <p>
                  Ten presente que, para obtener una Tarjeta Sabores Card y
                  abrir con el Emisor la correspondiente CPF, es necesario que
                  tu domicilio se encuentre en Chile. Por tanto, cualquier
                  modificación de domicilio que realices, deberá ser siempre
                  dentro del territorio nacional.
                </p>

                <p>
                  El domicilio del Emisor será Avenida Apoquindo 5550, piso 13, Las Condes, Santiago, salvo que, con posterioridad a la fecha de los <strong>Términos y Condiciones</strong>, el Emisor haya notificado su cambio de domicilio en el sitio{" "}
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.saborescard.cl"
                  >
                    https://www.saborescard.cl
                  </a>
                </p>
              </TermsItemCont>

              <TermsItemCont>
                <h5>JURISDICCIÓN Y LEY APLICABLE</h5>

                <p>
                  estos <strong> Términos y Condiciones</strong> y las relaciones
                  contractuales a que dé lugar, se regirán por las leyes
                  vigentes en la República de Chile. Cualquier controversia
                  derivada del presente acuerdo, su existencia, validez,
                  interpretación, alcance o cumplimiento, será sometida a los
                  tribunales ordinarios de la comuna y Ciudad de Santiago de
                  Chile.
                </p>

                <p style={{ textAlign: "center" }}>
                  Infórmese sobre las entidades autorizadas para emitir tarjetas
                  de Pago en el país, quienes se encuentran inscritas en los
                  Registros de Emisores de Tarjetas que lleva este Servicio, en{" "}
                  <a
                    href="https://www.cmfchile.cl/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    www.cmfchile.cl
                  </a>
                </p>
              </TermsItemCont>
            </TermsListCont>
          </MaxWidthWrap>
        </TermsContentCont>

        <Footer />
      </TermsWrap>
    </>
  );
};

export default Terms;
