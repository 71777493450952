import styled from "styled-components";

export const Button = styled.button(
  ({ theme, primary, isValid }) => `

  background: ${
    primary ? theme.colors.primary2 :  "transparent"
  };

  font-size: 1.4rem;
  font-weight: ${theme.fontWeight.semiBold};
  color: ${primary ? theme.colors.light : theme.colors.primary2};

  padding: 1.2rem 2.4rem;

  min-height: 3.2rem;

  border: ${primary ? "none" : "1px solid #E71D73"};
  border-radius: 3rem;

  &:hover {
    opacity: 0.9;
    transform: scale(0.98);
  }

  @media ${theme.breakpoints.lg} {
    padding: 0.8rem 5rem;
  }
`
);
