import React, { useState, useRef } from "react";

import { useNavigate } from "react-router-dom";

import Swal from "sweetalert2";

import ReCAPTCHA from "react-google-recaptcha";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { sendMailContact } from "../../api/apiMail";

import { Button } from "../Button/ButtonStyled";
import { FormInputError, FormInputWrap } from "../FormInput/FormInputStyled";
import {
  FormContactWrap,
  FormSingleInputCont,
  FormSubmitCont,
  FormTwoInputCont,
  ReCaptchaCont,
  ReCaptchaMessage,
} from "./FormStyled";

import Loader from "../Loader/Loader";

import { allowNumsLetters, allowOnlyLetters, allowOnlyNumber, phoneRegExp } from "../../utils/form";

//import { RECAPTCHA_KEY } from "../../api";


//
const schema = yup.object().shape({
  firstName: yup.string().trim().required("Nombre es requerido."),
  lastName: yup.string().trim().required("Apellido es requerido."),
  email: yup
    .string()
    .trim()
    .required("Email es requerido.")
    .email("Email es inválido"),
  phone: yup
    .string()
    .matches(phoneRegExp, "El teléfono no es válido")
    .required("Teléfono es requerido.")
    .min(9, "Debe tener al menos 9 dígitos.")
    .max(9, "No debe exceder de 9 dígitos."),
  comments: yup.string().trim(),
});

const FormContact = (props) => {
  const [loading, setLoading] = useState(false);
  const [captchaValid, setCaptchaValid] = useState(null);
  //const [userValid, setUserValid] = useState(false);

  const navigate = useNavigate();

  //
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      comments: "",
    },
  });

  //
  const captcha = useRef(null);

  // Captcha
  const onChange = (value) => {
    //console.log(captcha.current.getValue());

    if (captcha.current.getValue()) {
      console.log("El usuario no es un robot");
      setCaptchaValid(true);
    }

    //console.log("Captcha value:", value);
  };


  // Submit Form
  const handleContactForm = (data) => {
    //
    if (captcha.current.getValue()) {
      console.log("El usuario no es un robot");

      //setUserValid(true);

      setCaptchaValid(true);

      //
      setLoading(true);

      let valcaptcha = captcha.current.getValue();

      const infoMail = {
        name: data.firstName + " " + data.lastName,
        phone: data.phone,
        subject: "Contacto Sabores Card",
        text: data.comments,
        to: data.email,
        captcha: valcaptcha,
      };

      sendMailContact(infoMail).then((response) => {
        console.log("API Mail", response);

        if (response.status === "ERROR") {
          console.log("Error", response);

          //
          setTimeout(() => {
            //
            Swal.fire({
              title: "Oops..",
              text: "Algo salió mal, por favor intenténtelo de nuevo",
              icon: "error",
              confirmButtonText: "Entendido",
              confirmButtonColor: "",
              buttonsStyling: false,
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutUp",
              },
            }).then((result) => {
              if (result.isConfirmed || result.isDenied) {
                //
              } else {
                //
              }
            });

            setLoading(false);
          }, 3000);
        } else {
          console.log("OK", response);

          console.log("contact form submit", data);

          //
          setTimeout(() => {
            //
            Swal.fire({
              title: "Felicitaciones!",
              text: "Has podido enviar el formulario con exito",
              icon: "success",
              confirmButtonText: "Entendido",
              confirmButtonColor: "",
              buttonsStyling: false,
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutUp",
              },
            }).then((result) => {
              if (result.isConfirmed || result.isDenied) {
                //
                setTimeout(() => {
                  //
                  navigate("/");
                }, 1200);
              } else {
                //
                setTimeout(() => {
                  //
                  navigate("/");
                }, 1200);
              }
            });

            setLoading(false);
          }, 3000);
        }
      });
      // .catch((err) => {
      //   console.error(err);
      // });

      //
      reset();
    } else {
      console.log("Por favor acepta el captcha");

      setCaptchaValid(false);
    }
  };

  //
  // console.log("firstName", watch("firstName"));
  // console.log("lastName", watch("lastName"));
  // console.log("email", watch("email"));
  // console.log("phone", watch("phone"));
  // console.log("Errors", errors);
  // console.log("isValid", isValid);

  return (
    <>
      <FormContactWrap>
        <FormTwoInputCont>
          <FormInputWrap>
            <Controller
              name="firstName"
              control={control}
              render={({ field }) => (
                <>
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    autoComplete="off"
                    //{...register("firstName")}
                    {...field}
                    onChange={(val) => {
                      let value = allowOnlyLetters(val.target.value);
                      field.onChange(value);
                    }}
                  />
                  <label htmlFor="firstName">Nombre</label>
                </>
              )}
            />
            <FormInputError>{errors.firstName?.message}</FormInputError>
          </FormInputWrap>

          <FormInputWrap>
            <Controller
              name="lastName"
              control={control}
              render={({ field }) => (
                <>
                  <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    autoComplete="off"
                    //{...register("lastName")}
                    {...field}
                    onChange={(val) => {
                      let value = allowOnlyLetters(val.target.value);
                      field.onChange(value);
                    }}
                  />
                  <label htmlFor="lastName">Apellido</label>
                </>
              )}
            />
            <FormInputError>{errors.lastName?.message}</FormInputError>
          </FormInputWrap>
        </FormTwoInputCont>

        <FormSingleInputCont>
          <FormInputWrap>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    autoComplete="off"
                    //{...register("email")}
                    {...field}
                  />
                  <label htmlFor="email">Correo electrónico</label>
                </>
              )}
            />
            <FormInputError>{errors.email?.message}</FormInputError>
          </FormInputWrap>
        </FormSingleInputCont>

        <FormSingleInputCont>
          <FormInputWrap>
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <>
                  <input
                    type="tel"
                    name="phone"
                    id="phone"
                    autoComplete="off"
                    {...field}
                    onChange={(val) => {
                      let value = allowOnlyNumber(val.target.value);
                      field.onChange(value);
                    }}
                  />
                  <label htmlFor="phone">Teléfono de contacto</label>
                </>
              )}
            />
            <FormInputError>{errors.phone?.message}</FormInputError>
          </FormInputWrap>
        </FormSingleInputCont>

        <FormSingleInputCont>
          <FormInputWrap>
            <Controller
              name="comments"
              control={control}
              render={({ field }) => (
                <>
                  <textarea
                    {...field}
                    name="comments"
                    id="comments"
                    onChange={(val) => {
                      let value = allowNumsLetters(val.target.value);
                      field.onChange(value);
                    }}
                  />
                  <label htmlFor="comments">Comentarios</label>
                </>
              )}
            />
          </FormInputWrap>
        </FormSingleInputCont>

        <ReCaptchaCont>
          <ReCAPTCHA
            sitekey="6Lfb5SYfAAAAACYfUieUMlcLRlsUPNEV_5bx1a7u"
            hl="es-419"
            size="normal"
            onChange={onChange}
            ref={captcha}
          />
        </ReCaptchaCont>

        {isValid && !captchaValid && (
          <ReCaptchaMessage>
            <p>Debes activar el captcha</p>
          </ReCaptchaMessage>
        )}

        <FormSubmitCont>
          <Button
            primary
            //enable={isValid}
            onClick={handleSubmit(handleContactForm)}
          >
            {loading ? (
              <>
                <Loader />
              </>
            ) : (
              <>Enviar</>
            )}
          </Button>
        </FormSubmitCont>
      </FormContactWrap>
    </>
  );
};

export default FormContact;
