import React from "react";

import styled from "styled-components";

import { motion } from "framer-motion";

import useWindowDimensions from "../hooks/useDimensions";

import Header from "../components/Header/Header";
import HomeHero from "../components/Home/HomeHero/HomeHero";
import HomeCard from "../components/Home/HomeCard/HomeCard";
import HomeWhy from "../components/Home/HomeWhy/HomeWhy";
import HomeApp from "../components/Home/HomeApp/HomeApp";
import { BorderGradient } from "../components/GradientComponents/Gradient";
import { BannerSection } from "../components/Reusable";
import HomeAlliance from "../components/Home/HomeAlliance/HomeAlliance";
import HomeForm from "../components/Home/HomeForm/HomeForm";
import Footer from "../components/Footer/Footer";

export const HomeWrap = styled(motion.div)``;

const Home = () => {
  const { width } = useWindowDimensions();

  return (
    <>
      <HomeWrap initial="hidden" animate="show">
        <Header isTransparent />

        <HomeHero sectionID="Hero" />

        <HomeCard sectionID="What" />

        <HomeWhy sectionID="Why" />

        <HomeApp sectionID="App" />

        {width > 1023 && <BannerSection isAlliance />}

        <HomeAlliance sectionID="Alliance" />

        <BorderGradient />

        <HomeForm sectionID="Form" />

        <BorderGradient />

        <Footer />
      </HomeWrap>
    </>
  );
};

export default Home;
