/* eslint-disable import/no-anonymous-default-export */

export default {
  // Font family
  fonts: {
    title: "Montserrat, sans-serif",
    main: "Montserrat, sans-serif",
  },

  // Font weight
  fontWeight: {
    bold: "700",
    semiBold: "600",
    normal: "400",
    light: "300",
  },

  // Colors for layout
  colors: {
    light: "#fff",
    dark: "#3F464F",
    primary1: "#c74492",
    primary2: "#E71D73",
    primary3: "#EED0F9",
    secondary1: "#502361",
    secondary2: "#632ce4",
    secondary3: "#371B42",
    secondary4: "#A42A86",
    background1: "#f5f5f5",
    background2: "#211625",
    background3: "#FBFBFB",
    background4: "#1F1225",
    background5: "#442251",
    accent1: "hsl(34.9,98.6%,72.9%)",
    text1: "#3F464F",
    button: "hsl(205.1,100%,36.1%)",
  },

  // Spacing
  spacing: {
    headerHeight: "7.7rem",
    sectionPaddingX: "2rem",
    sectionPaddingY: "8rem",
  },

  // Breakpoints for responsive design
  breakpoints: {
    xs: "screen and (min-width: 420px)",
    sm: "screen and (min-width: 640px)",
    md: "screen and (min-width: 768px)",
    lg: "screen and (min-width: 1024px)",
    xl: "screen and (min-width: 1280px)",
    xxl: "screen and (min-width: 1366px)",
  },
};
