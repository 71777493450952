import styled from "styled-components";

import { NavLink } from "react-router-dom";

export const MenuWrap = styled.nav``;

export const MenuList = styled.ul`
  display: flex;
  gap: 1rem;

  font-size: 1.7rem;

  & li {
    font-weight: 500;
    color: ${(props) => props.theme.colors.light};

    padding: 1rem 0;
    margin-right: 4rem;

    cursor: pointer;

    & > .active {
      color: ${(props) => props.theme.colors.primary1};
    }

    &:nth-child(5) {
      position: relative;

      display: flex;
      align-items: center;
      gap: 1.6rem;

      color: ${(props) => props.theme.colors.primary1};

      border-left: 1px solid ${(props) => props.theme.colors.light};

      padding-left: 4rem;

      & > a:first-child {
        color: ${(props) => props.theme.colors.light};
      }

      & > svg {
        font-size: 1.6rem;
      }
    }
  }
`;

export const MenuLink = styled(NavLink)`
  &:hover {
    color: ${(props) => props.theme.colors.primary1};
  }
`;
