import styled from "styled-components";

export const DropdownWrap = styled.div`
  position: absolute;
  top: 50px;
  left: 0;

  background: ${(props) => props.theme.colors.light};

  min-width: 12rem;

  padding: 1rem;

  border-radius: 0.5rem;
`;

export const DropdownList = styled.ul`
  & li {
    color: ${(props) => props.theme.colors.primary1};

    margin-right: 0;
  }
`;

export const DropdownItem = styled.li``;

export const DropdownLink = styled.a`
  color: ${(props) => props.theme.colors.primary1}

  &:hover {}
`;
