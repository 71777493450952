import styled from "styled-components";

export const FormInputWrap = styled.div`
  position: relative;

  margin-bottom: 3rem;

  & input,
  textarea {
    //position: relative;

    background: #f5f5f5;

    font-weight: 300;

    border: transparent;

    transition: all 0.3s ease;

    &:placeholder-shown + label {
      transform: translateY(100%);
    }

    &:focus {
      border-color: #2b96f1;

      & + label {
        color: #119da4;

        transform: translateY(-20px);
      }
    }
  }

  & label {
    position: absolute;
    top: 1.3rem;
    left: 1rem;

    font-size: 12px;
    font-weight: 400;
    color: #442251;

    background: transparent;

    padding: 0 10px;

    cursor: text;

    pointer-events: none;

    transition: transform 100ms ease;
    //transition: all 0.3s ease;

    transform: translateY(-20px);

    z-index: 2;
  }

  & textarea {
    font-size: 1.3rem;

    resize: none;
  }

  // & input:focus + label,
  // input:valid + label,
  // textarea:focus + label,
  // textarea:valid + label {
  //   font-size: 1.1rem;
  //   font-weight: 500;
  //   top: -0.5rem;
  // }
`;

export const FormInputError = styled.div(
  ({ theme, isValid }) => `
  
    font-size: 1.2rem;
    color: ${isValid ? "#adf542" : theme.colors.primary2};  
  `
);
