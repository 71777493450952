import styled from "styled-components";

export const AccordionWrap = styled.div(
  ({ isFAQ, isActive }) => `

    margin-bottom: ${isFAQ ? "4rem" : "1.2rem"};

    transition: ${isActive ? "all 1s ease" : null};
  `
);

;

export const AccordionTitle = styled.div(
  ({ theme, isFAQ }) => `
  display: flex;
  justify-content: space-between;

  cursor: pointer;

  & > h4 {
    color: ${theme.colors.primary1};
  }

  & > div {
    color: ${isFAQ ? theme.colors.primary1 : theme.colors.light};
    font-size: ${isFAQ ? "2rem" : "1.6rem"};
  }
`
);



export const AccordionContent = styled.div(
  ({ theme, isFAQ, isActive }) => `
  background: 'transparent';

  color: ${isFAQ ? theme.colors.dark : theme.colors.light};

  padding: 1rem 0;

  transition: ${isActive ? "all 1s ease" : null}
`
);



;
