import styled from "styled-components";

import bgPhysical from "../../assets/img/bg-sc-physical.jpg";

export const SCWrap = styled.div`
  padding-top: ${(props) => props.theme.spacing.headerHeight};
`;

// Hero section
export const SCHeroWrap = styled.section`
  position: relative;

  width: 100%;
  height: 100%;
`;

export const SCHeroVideo = styled.video`
  aspect-ratio: 16 / 9;

  display: block;

  width: 100%;
  min-height: 100vh;

  object-fit: cover;

  vertical-align: baseline;

  @media ${(props) => props.theme.breakpoints.lg} {
    max-height: 52rem;
  }
`;

export const SCHeroContent = styled.div`
  position: absolute;
  left: 10%;
  bottom: 20%;

  max-width: 70vw;

  @media ${(props) => props.theme.breakpoints.lg} {
    & > h1 {
      font-size: 4rem;
    }

    & > p {
      font-size: 2rem;
    }
  }
`;

export const SCHeroContentTitle = styled.h1`
  color: ${(props) => props.theme.colors.light};
`;

export const SCHeroContentParagraph = styled.p`
  color: ${(props) => props.theme.colors.primary3};

  margin-bottom: 3rem;
`;

export const SCHeroContentBtnCont = styled.div`
  display: flex;

  & > button {
  }

  @media ${(props) => props.theme.breakpoints.lg} {
    //justify-content: center;
  }
`;

// Physical section
export const SCPhysicalWrap = styled.section`
  background-image: 
    url(${bgPhysical});
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;

  padding: ${(props) => props.theme.spacing.sectionPaddingY}
    ${(props) => props.theme.spacing.sectionPaddingX};

  @media ${(props) => props.theme.breakpoints.xl} {
    display: flex;
    align-items: flex-end;

    padding: 30rem ${(props) => props.theme.spacing.sectionPaddingX} 4rem;
  }
`;

export const SCPhysicalCardCont = styled.div`
  & > img {
    width: 20rem;
  }
`;

export const SCPhysicalContentCont = styled.div`
  background: rgba(67, 30, 77, 0.6);

  padding: 2rem;

  border-radius: 4rem;
`;

export const SCPhysicalTitleCont = styled.div`
  text-align: center;

  margin-bottom: 4rem;

  & > h3 {
  }
`;

export const SCPhysicalItemsCont = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  justify-content: space-between;

  @media ${(props) => props.theme.breakpoints.lg} {
  }
`;

export const SCPhysicalItemCont = styled.div`
  text-align: center;

  flex: 1 1 250px;

  color: ${(props) => props.theme.colors.light};

  & > div {
    margin: auto;

    & > img {
      width: 4rem;

      @media ${(props) => props.theme.breakpoints.lg} {
      }
    }
  }

  & > h4 {
    margin: 2rem 0;
  }

  & > p {
    font-weight: ${(props) => props.theme.fontWeight.light};
  }
`;

// Digital section
export const SCDigitalWrap = styled.section`
  background: radial-gradient(
    60% 24.96% at 50% 30%,
    #ae358b 0%,
    #6f1f64 61.95%,
    #1e131e 100%
  );

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  padding: ${(props) => props.theme.spacing.sectionPaddingY}
    ${(props) => props.theme.spacing.sectionPaddingX};

  @media ${(props) => props.theme.breakpoints.lg} {
    background: radial-gradient(
      43.51% 44.96% at 48.47% 37.95%,
      #ae358b 0%,
      #6f1f64 61.95%,
      #1e131e 100%
    );
  }
`;

export const SCDigitalCardCont = styled.div`
  text-align: center;

  & > img {
    width: 36rem;
  }
`;

export const SCDigitalTitleCont = styled.div`
  text-align: center;

  margin-bottom: 6rem;
`;

export const SCDigitalItemsCont = styled.div`
  @media ${(props) => props.theme.breakpoints.lg} {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(50rem, 1fr));
  }
`;
