import React from "react";

import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";

import {
  ComplaintsContentCont,
  ComplaintsItemCont,
  ComplaintsListCont,
  ComplaintsTitleCont,
  ComplaintsWrap,
} from "../components/Complaints/ComplaintsStyled";

import {
  HeroSection,
  MaxWidthWrap,
  TitleSection,
} from "../components/Reusable";

const Complaints = () => {
  return (
    <>
      <ComplaintsWrap>
        <Header />

        <HeroSection isComplaints />

        <ComplaintsContentCont>
          <MaxWidthWrap>
            <ComplaintsTitleCont>
              <TitleSection>Canal de denuncias</TitleSection>
            </ComplaintsTitleCont>

            <ComplaintsListCont>
              {/*  */}
              <ComplaintsItemCont>
                <p>
                  Para mantener, proteger y fortalecer el cumplimiento de los
                  estándares éticos y valóricos en los negocios e integridad en
                  todo tipo de operaciones Sabores Card cuenta con un canal de
                  denuncias, simple y seguro que te permitirá realizar denuncias
                  relacionadas con conducta laboral, respeto por las personas,
                  fraudes y con la Ley N° 20.393 (Lavado de Dinero,
                  Financiamiento del Terrorismo, Cohecho, Receptación,
                  Corrupción entre Privados, Apropiación Indebida, Negociación
                  Incompatible y Administración Desleal).
                </p>
              </ComplaintsItemCont>

              <ComplaintsItemCont>
                <h3>Los canales establecidos son los siguientes:</h3>
              </ComplaintsItemCont>

              <ComplaintsItemCont>
                <ul>
                  <li>
                    Casilla de Correo electrónico:
                    <span> canaldedenuncias@saborescard.cl</span>
                  </li>
                  <li>
                    Teléfono: <span>600 3600 405</span>
                  </li>
                </ul>
              </ComplaintsItemCont>

              <ComplaintsItemCont>
                <h3>
                  Al realizar una denuncia, debes tener presente lo siguiente:
                </h3>

                <ul>
                  <li>
                    El correo electrónico se ha establecido sólo para la
                    recepción de denuncias de manera confidencial y anónima si
                    el usuario así lo requiere.
                  </li>
                  <li>
                    Para que realicen consultas o denuncias sobre materias
                    éticas o informen infracciones relacionadas con la comisión
                    de los delitos de cohecho a funcionario público, nacional o
                    extranjero; lavado de activos, financiamiento del terrorismo
                    o receptación, cometidos por algún accionista, director,
                    funcionario o proveedor, en el ejercicio de sus funciones, y
                    en su propio beneficio.
                  </li>
                  <li>
                    El mecanismo correspondiente a la habilitación de una
                    casilla que se ha establecido de forma que puedas realizar,
                    de manera anónima, las denuncias que estimes conveniente.
                  </li>
                  <li>
                    Ten en cuenta que para un mejor análisis de la denuncia
                    debes indicar la mayor cantidad de información que sepas o
                    te sea posible, por ejemplo: área donde se produjo, nombre
                    del encargado, nombre de personas involucradas, fecha, hora,
                    montos, dirección, empresa, etc.
                  </li>
                  <li>
                    Toda denuncia presentada por cualquiera de los canales
                    indicados será investigada por las unidades encargadas, si
                    se enmarca en las situaciones indicadas anteriormente.
                  </li>
                </ul>

                <p>
                  Una vez que el usuario haya enviado un correo electrónico con
                  la denuncia debe recibir n correo de respuesta automática
                  indicando lo siguiente:
                </p>

                <p>
                  “Agradecemos su colaboración al alertar sobre la conducta
                  denunciada, estamos comprometidos con los comportamientos
                  éticos y legales en nuestra organización. Nos comprometemos a
                  Investigar a fondo la conducta o comportamiento denunciado.”
                </p>
              </ComplaintsItemCont>
            </ComplaintsListCont>
          </MaxWidthWrap>
        </ComplaintsContentCont>

        <Footer />
      </ComplaintsWrap>
    </>
  );
};

export default Complaints;
