import styled from "styled-components";

export const CardWrap = styled.section`
  display: grid;

  background: ${(props) => props.theme.colors.background3};

  padding: ${(props) => props.theme.spacing.sectionPaddingY}
    ${(props) => props.theme.spacing.sectionPaddingX};

  @media ${(props) => props.theme.breakpoints.lg} {
  }
`;

export const CardtitleCont = styled.div`
  text-align: center;

  margin-bottom: 3rem;

  @media ${(props) => props.theme.breakpoints.lg} {
    margin-bottom: 5rem;
  }
`;

export const CardImageContainer = styled.div`
  @media ${(props) => props.theme.breakpoints.lg} {
    justify-self: center;
    text-align: center;

    & > img {
      width: 42rem;
    }
  }
`;

export const CardContentContainer = styled.div`
  @media ${(props) => props.theme.breakpoints.lg} {
    & > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 3rem;
    }
  }
`;

export const CardInfoItemsContainer = styled.div`
  @media ${(props) => props.theme.breakpoints.lg} {
    align-self: center;
  }
`;

export const CardCTAContainer = styled.div`
  text-align: center;

  @media ${(props) => props.theme.breakpoints.lg} {
    text-align: left;

  }
`;
