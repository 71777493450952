import React from "react";

import { HashLink as Link } from "react-router-hash-link";

import { motion } from "framer-motion";

import { browserName } from "react-device-detect";

import useWindowDimensions from "../../../hooks/useDimensions";
import { useScrollAnimation } from "../../../hooks/useScrollAnimation";
import { fromRightAnimation, fromUp } from "../../../utils/animations";

import {
  CardWrap,
  CardContentContainer,
  CardInfoItemsContainer,
  CardImageContainer,
  CardCTAContainer,
  CardtitleCont,
} from "./HomeCardStyled";

import { TextGradient } from "../../GradientComponents/Gradient";
import { MaxWidthWrap, TitleSection } from "../../Reusable";
import { Button } from "../../Button/ButtonStyled";
import InfoItem from "../../InfoItem/InfoItem";

import deepCard from "../../../assets/img/tarjeta-3d.png";

const HomeCard = (props) => {
  const { sectionID } = props;

  const { width } = useWindowDimensions();

  const [element, controls] = useScrollAnimation();

  console.log("Navegador", browserName);

  return (
    <>
      <CardWrap id={sectionID} ref={element}>
        <CardtitleCont
          as={motion.div}
          animate={controls}
          initial="hidden"
          variants={fromUp}
        >
          <TitleSection>
            {browserName !== "Safari" ? (
              <TextGradient>
                {width < 1023 ? (
                  <>
                    La más moderna y <br /> eficiente tarjeta prepago <br />
                    Mastercard&reg; de <br />
                    alimentación
                  </>
                ) : (
                  <>
                    La más moderna y eficiente <br /> tarjeta prepago
                    Mastercard&reg; de <br />
                    alimentación
                  </>
                )}
              </TextGradient>
            ) : (
              <TextGradient>
                La más moderna y eficiente tarjeta prepago <br />
                Mastercard&reg; de alimentación
              </TextGradient>
            )}
          </TitleSection>
        </CardtitleCont>

        <CardContentContainer>
          <MaxWidthWrap>
            {/*  */}
            {width > 1023 && (
              <CardImageContainer>
                <motion.img
                  src={deepCard}
                  alt=""
                  animate={controls}
                  initial="hidden"
                  variants={fromUp}
                />
              </CardImageContainer>
            )}

            {/*  */}

            <CardInfoItemsContainer
              as={motion.div}
              animate={controls}
              initial="hidden"
              variants={fromRightAnimation}
            >
              <InfoItem />

              <CardCTAContainer>
                <Link to="/saborescard#SCHero">
                  <Button>Quiero saber más</Button>
                </Link>
              </CardCTAContainer>
            </CardInfoItemsContainer>
          </MaxWidthWrap>
        </CardContentContainer>
      </CardWrap>
    </>
  );
};

export default HomeCard;
