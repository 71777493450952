import React from "react";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import ScrollToTop from "./components/ScrollToTop/ScrollToTop";

import Home from "./containers/Home";
import SaboresCard from "./containers/SaboresCard";
import Simulador from "./containers/Simulador";
import FAQ from "./containers/FAQ";
import Contacto from "./containers/Contact";
import Terms from "./containers/Terms";
import Privacy from "./containers/Privacy";
import Complainst from "./containers/Complainst";
import Ley from "./containers/Ley";
import Cookies from "./containers/Cookies";

const routesApp = () => {
  return (
    <>
      <Router>
        <ScrollToTop />

        <Routes>
          <Route index path="/" element={<Home />} />
          <Route path="/saborescard" element={<SaboresCard />} />
          <Route path="/simulador" element={<Simulador />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/contacto" element={<Contacto />} />
          <Route path="/terminosycondiciones" element={<Terms />} />
          <Route path="/politicasdecookies" element={<Cookies />} />
          <Route path="/politicadeprivacidad" element={<Privacy />} />
          <Route path="/denuncias" element={<Complainst />} />
          <Route path="/ley" element={<Ley />} />
        </Routes>
      </Router>
    </>
  );
};

export default routesApp;
