import styled from "styled-components";

export const WhyWrap = styled.section`
  display: grid;

  background: ${(props) => props.theme.colors.light};

  padding: ${(props) => props.theme.spacing.sectionPaddingY}
    ${(props) => props.theme.spacing.sectionPaddingX};

  @media ${(props) => props.theme.breakpoints.lg} {
    background: inherit;
  } ;
`;

export const WhyTitleCont = styled.div`
  text-align: center;

  margin-bottom: 8rem;
`;

export const WhyItemsContainer = styled.div`
  width: 74vw;

  & > .swiper {
    overflow: inherit;

    & .swiper-wrapper {
      & .swiper-slide {
        flex-shrink: 1 !important;
      }
    }
  }

  @media ${(props) => props.theme.breakpoints.lg} {
    display: flex;
    justify-content: center;

    width: 100%;

    & > div {
      display: flex;
      flex-direction: row;
      gap: 4rem;

      overflow: unset;
    }
  }
`;

export const WhyItemContainer = styled.div(
  ({ worker, shop, theme }) => `

  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: ${theme.colors.light};

  min-height: 30rem;  
  min-width: 24rem;

  padding: 2rem;

  border-radius: 3rem;

  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);

  margin-bottom: 5rem;

  &::before {
    content: "";
    position: absolute;

    background: ${
      worker
        ? "linear-gradient(90deg, #4875d6 0%, #dc5ca9 100%)"
        : shop
        ? "linear-gradient(90deg, #DC5CA9 0%, #E5BA50 100%)"
        : "linear-gradient(90deg, #66C6CE 0%, #4875D6 100%)"
    };

    min-height: 30rem;
    min-width: 24rem;

    border-radius: 2rem;

    filter: blur(13px);

    z-index: -1; 

    @media ${theme.breakpoints.lg} {
      min-height: 28rem;
      min-width: 31rem;    
      
    }  
  }
  
  & > p {
    font-size: 1.6rem;
    font-weight: ${theme.fontWeight.semiBold};

    @media ${theme.breakpoints.lg} {
      font-size: 2.2rem;
    }
  }

  & > svg {
    position: absolute;
    bottom: 5%;
    right: 6%;

    font-size: 4rem;
    
    cursor: pointer;

    @media ${theme.breakpoints.lg} {
      font-size: 3.6rem;
    }
  }

  @media ${theme.breakpoints.lg} {
    min-height: 28rem;
    min-width: 30rem;
    max-width: unset;
  }
`
);

export const WhyItemBox = styled.div(
  ({ worker, shop, theme, isModal }) => `

  display: flex;
  justify-content: center;
  align-items: center;
  
  background: ${
    worker
      ? "linear-gradient(90deg, #4875d6 0%, #dc5ca9 100%)"
      : shop
      ? "linear-gradient(90deg, #DC5CA9 0%, #E5BA50 100%)"
      : "linear-gradient(90deg, #66C6CE 0%, #4875D6 100%)"
  };
  
  padding: ${isModal ? ".5rem" : "1rem"};
  
  border-radius: 10px;
  
  margin-bottom: ${isModal ? "0" : "2rem"};

  @media ${theme.breakpoints.lg} {
    padding: .6rem;   
    
  }

  & > img {
    width: ${isModal ? "2.6rem" : "4.2rem"};

    stroke: #fff;
    fill: ${theme.colors.light};
    
    & > svg {     
      color: ${theme.colors.light};

      & polygon {
        stroke: ${theme.colors.light};
      }

    }    
  }     
  `
);

export const WhyCTAContainer = styled.div`
  display: flex;
  justify-content: center;

  @media ${(props) => props.theme.breakpoints.lg} {
    align-items: center;
    gap: 1rem;

    & > p {
      font-size: 2rem;
      font-weight: ${(props) => props.theme.fontWeight.bold};

      & > span {
        color: ${(props) => props.theme.colors.primary2};
      }
    }

    & > svg {
      font-size: 2.8rem;
      color: ${(props) => props.theme.colors.primary2};

      cursor: pointer;

      &:hover {
        opacity: 0.9;
        transform: scale(1.2);
      }
    }
  }
`;

// MODAL
export const WhyModalWrap = styled.div`
  height: 96vh;

  @media ${(props) => props.theme.breakpoints.lg} {
    display: flex;

    height: unset;
    min-height: 28rem;
  }
`;

export const WhyModalColumnOne = styled.div`
  @media ${(props) => props.theme.breakpoints.lg} {
    position: relative;

    & > video {
      max-width: 25rem;
      height: 100%;

      object-fit: cover;

      -webkit-border-top-left-radius: 3rem;
      -webkit-border-bottom-left-radius: 3rem;
      -moz-border-radius-topleft: 3rem;
      -moz-border-radius-bottomleft: 3rem;
      border-top-left-radius: 3rem;
      border-bottom-left-radius: 3rem;
    }
  }
`;

export const WhyModalColumnTwo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 96vh;

  padding: 3rem 1rem 1rem;

  @media ${(props) => props.theme.breakpoints.lg} {
    height: inherit;
    width: 100%;

    padding: 1.5rem 1rem 1rem;
  }
`;

export const WhyModalColumnThree = styled.div`
  align-self: end;
`;

export const WhyModalHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  margin-bottom: 2rem;

  & > h3 {
    font-size: 2.4rem;
    font-weight: ${(props) => props.theme.fontWeight.semiBold};

    @media ${(props) => props.theme.breakpoints.lg} {
      font-size: 2rem;
    }
  }
`;

export const WhyModalBody = styled.div`
  @media ${(props) => props.theme.breakpoints.lg} {
    width: 100%;
  }
`;

export const WhyModalBodyItem = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1.6rem;

  margin-bottom: 1rem;

  & > p {
    font-size: 1.5rem;
    font-weight: ${(props) => props.theme.fontWeight.normal};

    & > span {
      font-weight: ${(props) => props.theme.fontWeight.semiBold};
    }
  }

  @media ${(props) => props.theme.breakpoints.lg} {
    
    & > p {
      font-size: 1.2rem;
    }
  }
`;

export const WhyModalBodyBusinessFooter = styled.div`
  text-align: center;

  margin-top: 2rem;

  & > h4 {
    font-size: 1.8rem;
    font-weight: ${(props) => props.theme.fontWeight.bold};

    margin-bottom: 2.2rem;

    & > span {
      color: ${(props) => props.theme.colors.primary2};
    }
  }

  @media ${(props) => props.theme.breakpoints.lg} {

    text-align: unset;

    display: flex;
    flex-direction: column;

    & > h4 {
      font-size: 1.4rem;
      align-self: start;

      margin-bottom: 3.6rem;
    }

    & > button {
      align-self: center;
    }
  }
`;

export const WhyModalFooter = styled.div`
  align-self: end;

  padding: 1rem 1.4rem;

  svg {
    font-size: 3.5rem;

    cursor: pointer;
  }
`;
