import styled from "styled-components";

import bgAlliance from "../assets/img/bg-home-alianza.jpg";

//
import bgContactTablet from "../assets/img/banner-contact-tablet.png";
import bgContactDesk from "../assets/img/banner-contact-desk.webp";

//
import bgPolicyTablet from "../assets/img/banner-policy-tablet.png";
import bgPolicyDesk from "../assets/img/banner-policy-desk.webp";

//
import bgTermsTablet from "../assets/img/banner-terms-tablet.png";
import bgTermsDesk from "../assets/img/banner-terms-desk.webp";

//
import bgFAQTablet from "../assets/img/banner-faq-tablet.png";
import bgFAQDesk from "../assets/img/banner-faq-desk.webp";

//
import bgComplaintsDesk from "../assets/img/banner-complaints.jpg";

// Logo
export const Logo = styled.img`
  width: 12rem;
`;

// Overlay
export const Overlay = styled.div`
  position: fixed;
  top: ${(props) => props.theme.spacing.headerHeight};
  left: 0;
  right: 0;
  bottom: 0;

  display: ${({ sidebar }) => (sidebar ? "flex" : "none")};

  background-color: rgba(0, 0, 0, 0.5);

  //opacity: 1;

  //transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  -webkit-tap-highlight-color: transparent;

  z-index: 3;
`;

// Section Title
export const TitleSectionContainer = styled.div(
  ({ marginBLG }) => `

  text-align: center;

  margin-bottom: ${marginBLG ? "9rem" : "4rem"}; 

  `
);

export const TitleSection = styled.h3(
  ({ theme, weightNormal, colorLight }) => `
    font-size: 2.2rem;
    font-weight: ${
      weightNormal ? theme.fontWeight.normal : theme.fontWeight.bold
    };

    color: ${colorLight ? theme.colors.light : null};
  
    @media ${theme.breakpoints.lg} {
      font-size: 3.2rem;
    }

    @media ${theme.breakpoints.xxl} {
      font-size: 4.2rem;
    }
  `
);

// Banner
export const BannerSection = styled.div(
  ({ isAlliance }) => `
  background-image: radial-gradient(
    rgba(53, 17, 42, 0) 0%, 
    rgba(152, 138, 149, 0.2) 51.78%, 
    #372532 100%), 
  url(${bgAlliance});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;

  min-height: ${isAlliance ? "40rem" : null};
`
);

// Hero
export const HeroSection = styled.div(
  ({ theme, isContact, isFAQ, isTerms, isPolicy, isComplaints }) => `
  background-image: url(${
    isContact
      ? bgContactTablet
      : isFAQ
      ? bgFAQTablet
      : isTerms
      ? bgTermsTablet
      : isPolicy
      ? bgPolicyTablet
      : isComplaints
      ? bgComplaintsDesk
      : null
  });
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  min-height: 25rem;

  @media ${theme.breakpoints.lg} {
    background-image: url(${
      isContact
        ? bgContactDesk
        : isFAQ
        ? bgFAQDesk
        : isTerms
        ? bgTermsDesk
        : isPolicy
        ? bgPolicyDesk
        : isComplaints
        ? bgComplaintsDesk
        : null
    });

    min-height: 30rem;
  }
`
);

// Max width wrap
export const MaxWidthWrap = styled.div(
  ({ theme }) => `
    max-width: 650px;

    margin: auto;

    @media ${theme.breakpoints.lg} {
      max-width: 1170px;
    }
  `
);

// HR tag Line
export const LineHR = styled.hr`
  height: 1px;
  background: #442251;
  border: 0;
`;
