import React, { useState, useEffect } from "react";

import { IconContext } from "react-icons/lib";
// import { FaHamburger, FaTimes } from "react-icons/fa";

import theme from "../../assets/themes/default";

import useWindowDimensions from "../../hooks/useDimensions";

import { HeaderWrap, HeaderIcon, HeaderIconLogo } from "./HeaderStyled";

import { SidebarData } from "../Sidebar/SidebarData";
import { Sidebar, SidebarWrap } from "../Sidebar/SidebarStyled";
import SubMenu from "../Sidebar/SubMenu";
import Burger from "../Burger/Burger";
import NavLinks from "../NavLinks/NavLinks";

import { Logo, MaxWidthWrap, Overlay } from "../Reusable";

import LogoSC from "../../assets/img/LogoSaboresLight.svg";

const Header = (props) => {
  const { isTransparent } = props;

  const [sidebar, setSidebar] = useState(false);
  const [background, setBackground] = useState(false);

  const { width } = useWindowDimensions();

  const showSidebar = () => setSidebar(!sidebar);

  const redirecting = (target) => {
    if (target === "Usuario") {
      window.location.href = "https://web-public-eastus.u-payments.com/";
      
    } else if (target === "Empresas") {
      window.location.href = " https://business-sbc-eastus.u-payments.com/";

    } else {
      return false;
    }
  };

  // Navbar scroll changeBackground function
  const changeBackground = () => {
    if (isTransparent) {
      if (window.scrollY >= 22) {
        setBackground(true);
      } else {
        setBackground(false);
      }
    }
  };

  //
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;

    const yOffset = -70;

    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  useEffect(() => {
    changeBackground();

    window.addEventListener("scroll", changeBackground);

    // return () => {}
  }, []);

  return (
    <>
      <Overlay sidebar={sidebar} onClick={showSidebar} />

      <HeaderWrap background={background} isTransparent={isTransparent}>
        {width < 1023 ? (
          <MaxWidthWrap isTablet>
            <HeaderIcon to="#">
              <Burger clickEvent={showSidebar} sidebar={sidebar} />
            </HeaderIcon>

            <HeaderIconLogo to="/#Hero" scroll={scrollWithOffset}>
              <Logo src={LogoSC} />
            </HeaderIconLogo>

            <div style={{ width: "20px" }}></div>
          </MaxWidthWrap>
        ) : (
          <MaxWidthWrap>
            <HeaderIconLogo to="/#Hero">
              <Logo src={LogoSC} />
            </HeaderIconLogo>

            <NavLinks />

            <div></div>
          </MaxWidthWrap>
        )}
      </HeaderWrap>

      <Sidebar sidebar={sidebar}>
        <SidebarWrap>
          <IconContext.Provider value={{ color: theme.colors.primary1 }}>
            {/* 
            <SidebarIcon>
              <FaTimes onClick={showSidebar} />
            </SidebarIcon> 
            */}

            {SidebarData.map((item, index) => {
              return (
                <SubMenu
                  item={item}
                  key={index}
                  showSidebar={showSidebar}
                  redirecting={redirecting}
                />
              );
            })}
          </IconContext.Provider>
        </SidebarWrap>
      </Sidebar>
    </>
  );
};

export default Header;
