import styled from "styled-components";

//
export const InfoItemsContainer = styled.div``;

export const InfoItemContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1rem;

  margin-bottom: 3rem;

  @media ${(props) => props.theme.breakpoints.lg} {
    margin-bottom: 4rem;
  }
`;

export const InfoItemTextContainer = styled.div`
  & p:first-child {
    font-size: 1.3rem;
    font-weight: ${(props) => props.theme.fontWeight.semiBold};
    color: ${(props) => props.theme.colors.text1};
    letter-spacing: 1px;

    margin-bottom: 0.5rem;
  }

  & p:last-child {
    font-size: 1.3rem;
    font-weight: ${(props) => props.theme.fontWeight.normal};
    color: ${(props) => props.theme.colors.text1};
    letter-spacing: 0.5px;

    line-height: 1.4;

    & > span {
      font-weight: ${(props) => props.theme.fontWeight.semiBold};
    }
  }

  @media ${(props) => props.theme.breakpoints.lg} {
    max-width: 34rem;

    & p:first-child {
      font-size: 1.6rem;
    }

    & p:last-child {
      font-size: 1.4rem;
    }
  }
`;
