import React from "react";
import { BurgerStyled } from "./BurgerStyled";

const Burger = ({clickEvent , sidebar}) => {
  return (
    <BurgerStyled onClick={clickEvent} sidebar={sidebar}>
      <div />
      <div />
      <div />
    </BurgerStyled>
  );
};

export default Burger;
