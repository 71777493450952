import styled from "styled-components";

export const PrivacyWrap = styled.div`
  padding-top: ${(props) => props.theme.spacing.headerHeight};
`;

export const PrivacyContentCont = styled.div`
  padding: ${(props) => props.theme.spacing.sectionPaddingY}
    ${(props) => props.theme.spacing.sectionPaddingX};
`;

export const PrivacyTitleCont = styled.div`
  text-align: center;

  margin-bottom: 4rem;
`;

export const PrivacyListCont = styled.section``;

export const PrivacyItemCont = styled.div`
  margin-bottom: 3rem;

  & > h3 {
    font-size: 1.8rem;
    text-transform: uppercase;
    margin-bottom: 2rem;
  }

  & > p {
    text-align: justify;
    margin-bottom: 2rem;
  }

  & a {
    text-decoration: underline;
    color: unset;
  }

  & ul {
    padding-left: 1.4rem;

    margin-bottom: 2rem;

    & > li {
      list-style-type: unset;
      padding-left: 1rem;

      margin-bottom: .8rem;
    }
  }

  & table,
  td {
    border: 1px solid;
  }

  & table {
    width: 100%;

    table-layout: fixed;

    border-collapse: collapse;

    & td {
      padding: 1rem;

      &:first-child {
        width: auto;

        font-weight: 600;
      }

      &:last-child {
        width: 70%;
      }
    }
  }
`;

export const PrivacyItemSubCont = styled.div`
  margin-bottom: 2rem;

  & > p {
    margin-bottom: 2rem;
  }
`;

export const PrivacyTableCont = styled.div`
  overflow-x: scroll;

  margin-bottom: 2rem;
`;

export const PrivacySubtitle = styled.p`
  font-weight: 600;
  font-style: italic;
`;
