// import crypto from "crypto";
import CryptoJS from "crypto-js";

// Funcion para encriptar
export function encryptedaes256cbc(data, publicKey) {
  let iv = CryptoJS.randomBytes(16);
  let cipher = CryptoJS.createCipheriv("aes-256-cbc", Buffer.from(publicKey), iv);
  let encrypted = cipher.update(data);
  encrypted = Buffer.concat([encrypted, cipher.final()]);
  return iv.toString("hex") + ":" + encrypted.toString("hex");
}
