import React, { useState } from "react";

import { MenuWrap, MenuList, MenuLink } from "./NavLinksStyled";
import Dropdown from "../Dropdown/Dropdown";

import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const NavLinks = () => {
  const [dropdown, setDropdown] = useState(false);

  const showDropdown = () => setDropdown(!dropdown);

  return (
    <>
      <MenuWrap>
        <MenuList>
          <li>
            <MenuLink
              to="/saborescard"
              className={(navData) => (navData.isActive ? "active" : "")}
            >
              Sabores Card
            </MenuLink>
          </li>
          <li>
            <MenuLink to="/simulador">Simulador</MenuLink>
          </li>
          <li>
            <MenuLink to="/faq">Preguntas frecuentes</MenuLink>
          </li>
          <li>
            <MenuLink to="/contacto">Contacto</MenuLink>
          </li>
          <li onClick={showDropdown}>
            <MenuLink to="#">Login</MenuLink>
            {dropdown ? <FaChevronUp /> : <FaChevronDown />}

            {dropdown && <Dropdown />}
          </li>
        </MenuList>
      </MenuWrap>
    </>
  );
};

export default NavLinks;
