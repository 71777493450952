import React from "react";

import { HashLink as Link } from "react-router-hash-link";

import { motion } from "framer-motion";

import { Button } from "../../Button/ButtonStyled";
import { MaxWidthWrap, TitleSection } from "../../Reusable";

import {
  AppWrap,
  AppContentCont,
  AppContentColOne,
  AppTitleCont,
  AppSubtitleCont,
  AppBadgesCont,
  AppCTACont,
  AppWhiteRow,
  AppImgCont,
  AppBadgeItem,
} from "./HomeAppStyled";

import useWindowDimensions from "../../../hooks/useDimensions";
import { useScrollAnimation } from "../../../hooks/useScrollAnimation";

import GooglePlay from "../../../assets/img/badge-googlePlay-w.svg";
import AppStore from "../../../assets/img/badge-appStore-w.svg";
import AppGallery from "../../../assets/img/badge-appGallery-w.svg";
import AppImg from "../../../assets/img/app-home-animated.png";

//
import QRGPImg from "../../../assets/img/qr-code-android.png";
import QRASImg from "../../../assets/img/qr-code-AppsStore.png";
import QRASAltImg from "../../../assets/img/qr-code-AppGallery.png";
import {
  fromDown,
  fromLeftAnimation,
  fromRightAnimation,
  fromUp,
} from "../../../utils/animations";

const HomeApp = (props) => {
  const { sectionID } = props;

  const { width } = useWindowDimensions();

  const [element, controls] = useScrollAnimation();

  return (
    <>
      <AppWrap id={sectionID} ref={element}>
        {width > 1023 && (
          <>
            <AppWhiteRow isTop />
          </>
        )}

        <AppContentCont>
          <MaxWidthWrap>
            <AppContentColOne>
              <AppTitleCont
                as={motion.div}
                animate={controls}
                initial="hidden"
                variants={fromUp}
              >
                <TitleSection colorLight>App Sabores Card</TitleSection>
              </AppTitleCont>

              <AppSubtitleCont>
                <h5>
                  La tarjeta prepago digital <span>para todos!</span>
                </h5>
              </AppSubtitleCont>

              {width < 1023 && (
                <>
                  <AppImgCont>
                    <motion.img
                      src={AppImg}
                      alt=""
                      animate={controls}
                      initial="hidden"
                      variants={fromDown}
                    />
                  </AppImgCont>
                </>
              )}

              <AppBadgesCont
                as={motion.div}
                animate={controls}
                initial="hidden"
                variants={fromDown}
              >

                <AppBadgeItem style={{ visibility: "hidden" }}>
                  <img src={AppStore} alt="" className="badgeTwo" />
                  {width > 1023 && <img src={QRASImg} alt="" />}
                </AppBadgeItem>
                
                <AppBadgeItem>
                  <img src={GooglePlay} alt="" className="badgeOne" />
                  {width > 1023 && <img src={QRGPImg} alt="" />}
                </AppBadgeItem>

                <AppBadgeItem style={{ visibility: "hidden" }}>
                  <img src={AppGallery} alt="" className="badgeThree" />
                  {width > 1023 && <img src={QRASAltImg} alt="" />}
                </AppBadgeItem>
              </AppBadgesCont>

              <AppCTACont>
                <Link to="/saborescard#SCDigital">
                  <motion.div
                    animate={controls}
                    initial="hidden"
                    variants={fromLeftAnimation}
                  >
                    <Button primary>Quiero saber más</Button>
                  </motion.div>
                </Link>
              </AppCTACont>
            </AppContentColOne>

            {width > 1023 && (
              <AppImgCont>
                <motion.img
                  src={AppImg}
                  alt=""
                  animate={controls}
                  initial="hidden"
                  variants={fromRightAnimation}
                />
              </AppImgCont>
            )}
          </MaxWidthWrap>
        </AppContentCont>

        {width > 1023 && (
          <>
            <AppWhiteRow />
          </>
        )}
      </AppWrap>
    </>
  );
};

export default HomeApp;
