import styled from "styled-components";

export const InfoItemCircleCheckWrap = styled.div`
  display: flex;
  gap: 1.6rem;

  margin-bottom: 3rem;

  & > div:first-child {
  }

  & > div:last-child {
    color: ${(props) => props.theme.colors.light};

    & > h5 {
      font-size: 1.6rem;
      font-weight: ${(props) => props.theme.fontWeight.semiBold};

      margin-bottom: 1rem;
    }

    & > p {
      font-size: 1.6rem;
      font-weight: ${(props) => props.theme.fontWeight.light};
    }
  }
`;
