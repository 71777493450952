import React from "react";

// import * as AiIcons from "react-icons/ai";
// import * as IoIcons from "react-icons/io";

import arrowUp from "../../assets/img/arrowUp.svg";
import arrowDown from "../../assets/img/arrowDown.svg";

export const SidebarData = [
  {
    title: "Login",
    path: "#",
    // icon: <AiIcons.AiFillHome />,
    iconClosed: <img src={arrowDown} alt="" />,
    iconOpened: <img src={arrowUp} alt="" />,

    subNav: [
      {
        title: "Próximamente",
        path: "#",
        // icon: <IoIcons.IoIosPaper />,
      },
      // {
      //   title: "Empresas",
      //   path: "#",
      //   // icon: <IoIcons.IoIosPaper />,
      // },
    ],
  },
  {
    title: "Sabores Card",
    path: "/saborescard",
    //icon: <IoIcons.IoIosPaper />,
  },
  {
    title: "Simulador de ahorro",
    path: "/simulador",
    //icon: <IoIcons.IoIosPaper />,
  },
  {
    title: "Preguntas frecuentes",
    path: "/faq",
    //icon: <IoIcons.IoIosPaper />,
  },
  {
    title: "Contacto",
    path: "/contacto",
    //icon: <IoIcons.IoIosPaper />,
  },
];
