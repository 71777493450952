import styled from "styled-components";

export const ContactWrap = styled.div`
  padding-top: ${(props) => props.theme.spacing.headerHeight};
`;

export const ContactHero = styled.section``;

export const ContactFormWrap = styled.section` 

  padding: ${(props) => props.theme.spacing.sectionPaddingY}
    ${(props) => props.theme.spacing.sectionPaddingX};  

  max-width: 650px;
  margin: auto;  

  @media ${(props) => props.theme.breakpoints.lg} {
    display: flex;
    flex-direction: column;
    justify-content; center;
    align-items: center;    

    max-width: 1170px;
  }
`;

export const ContactTitleCont = styled.div`
  text-align: center;

  margin-bottom: 4rem;
`;
