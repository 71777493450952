import styled from "styled-components";

import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

export const MenuWrapFooter = styled.div``;

export const MenuListFooter = styled.ul(
  ({ menu }) => `

  & > li {
    margin-bottom: .5rem;
  }
`
);

export const MenuHashFooter = styled(HashLink)`
  font-size: 1.4rem;
  font-weight: ${(props) => props.theme.fontWeight.light};
  color: ${(props) => props.theme.colors.light};
`;

export const MenuLinkFooter = styled(Link)`
  font-size: 1.4rem;
  font-weight: ${(props) => props.theme.fontWeight.light};
  color: ${(props) => props.theme.colors.light};
`;
