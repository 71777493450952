import React from 'react';

import {
  DropdownWrap,
  DropdownList,
  DropdownItem,
  DropdownLink,
} from "./DropdownStyled";


const Dropdown = () => {
  return (
    <>
      <DropdownWrap>
        <DropdownList>
          <DropdownItem>
            <DropdownLink
              href="#"
              target="_blank"
              rel="noopener noreferrer"
            >
              Próximamente
            </DropdownLink>
          </DropdownItem>
          {/* 
          <DropdownItem>
            <DropdownLink
              href="#"
              target="_blank"
              rel="noopener noreferrer"
            >
              Proximamente
            </DropdownLink>
          </DropdownItem> 
          */}
        </DropdownList>
      </DropdownWrap>
    </>
  );
};

export default Dropdown;
