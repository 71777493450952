import React from "react";

import { Helmet } from "react-helmet";

export const HelmetSEO = () => {
  return (
    <div>
      <Helmet>
        {/* 
        <html lang="en" />
        <title>Hello from React Helmet</title>
        <meta name="description" content="Basic example" /> 
        */}

        <meta name="theme-color" content="#211625" />

        {/* Google Font */}
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&display=swap"
          rel="stylesheet"
        />

        {/* Normalize */}
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css"
          integrity="sha512-NhSC1YmyruXifcj/KFRWoC561YpHpc5Jtzgvbuzx5VozKpWvQ+4nXhPdFgmx8xqexRcpAglTj9sIBWINXa8x5w=="
          crossorigin="anonymous"
          referrerpolicy="no-referrer"
        />
      </Helmet>
    </div>
  );
};
