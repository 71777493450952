import React, { useState, useEffect, useRef } from "react";

import { HashLink as Link } from "react-router-hash-link";

import {
  SCHeroContent,
  SCHeroContentBtnCont,
  SCHeroContentParagraph,
  SCHeroContentTitle,
  SCHeroVideo,
  SCHeroWrap,
} from "./SCStyled";
import { Button } from "../Button/ButtonStyled";

import useWindowDimensions from "../../hooks/useDimensions";

import bgHeroVideo from "../../assets/video/video-hero-sc.mp4";
import bgHeroVideoWebM from "../../assets/video/video-hero-sc.webm";

const SCHero = (props) => {
  const { sectionID } = props;

  const [toPlay, setToPlay] = useState(false);

  //
  const { width } = useWindowDimensions();

  const videoRef = useRef();

  const handlePlayVideo = () => {
    videoRef.current.play();

    setToPlay(!toPlay);
  };

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -20;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  useEffect(() => {
    console.log("se montó");
    handlePlayVideo();
  }, []);

  return (
    <SCHeroWrap id={sectionID}>
      <SCHeroVideo
        autoPlay
        loop
        ref={videoRef}
        muted
        webkit-playsinline="true"
        playsinline="true"
      >
        <source src={bgHeroVideoWebM} type="video/webm" />

        <source src={bgHeroVideo} type="video/mp4" />
      </SCHeroVideo>

      <SCHeroContent>
        <SCHeroContentTitle>
          {width < 1023 ? (
            <>Tarjeta prepago Sabores Card</>
          ) : (
            <>
              Tarjeta prepago <br />
              Sabores Card
            </>
          )}
        </SCHeroContentTitle>

        <SCHeroContentParagraph>
          {width < 1023 ? (
            <>
              La tarjeta de alimentación y beneficios, más genial creada para
              colaboradores
            </>
          ) : (
            <>
              La tarjeta de alimentación y <br /> beneficios, más genial creada
              para <br />
              colaboradores
            </>
          )}
        </SCHeroContentParagraph>

        <SCHeroContentBtnCont>
          <Link to="/#Form" scroll={(el) => scrollWithOffset(el)}>
            <Button primary>Solicitar Sabores Card</Button>
          </Link>
        </SCHeroContentBtnCont>
      </SCHeroContent>
    </SCHeroWrap>
  );
};

export default SCHero;
