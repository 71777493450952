import styled from "styled-components";

export const HeroWrap = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;

  background: ${(props) => props.theme.colors.background2};

  min-height: 100vh;

  padding: ${(props) => props.theme.spacing.headerHeight} 2rem 2rem;

  @media ${(props) => props.theme.breakpoints.lg} {
    justify-content: space-around;
    align-items: center;
    gap: 2rem;
  } ;
`;

//
export const HeroContentContainer = styled.div`
  @media ${(props) => props.theme.breakpoints.lg} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-items: center;
    gap: 3rem;

    margin: 0 auto;
  } ;
`;

export const HeroTextContainer = styled.div`
  text-align: center;

  margin-bottom: 3rem;

  @media ${(props) => props.theme.breakpoints.lg} {
    text-align: initial;

    margin-bottom: 0;
  }
`;

export const HeroTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  @media ${(props) => props.theme.breakpoints.lg} {
    justify-content: flex-start;
  }
`;

export const MainTitle = styled.h1`
  font-size: 3rem;
  font-weight: ${(props) => props.theme.fontWeight.bold};
  color: ${(props) => props.theme.colors.light};

  @media ${(props) => props.theme.breakpoints.lg} {
    font-size: 5rem;
  }

  @media ${(props) => props.theme.breakpoints.xxl} {
    font-size: 6rem;
  }
`;

export const MainIcon = styled.img`
  width: 3rem;

  @media ${(props) => props.theme.breakpoints.lg} {
    width: 4rem;
  }

  @media ${(props) => props.theme.breakpoints.xxl} {
    width: 5rem;
  }
`;

export const HeroDescription = styled.p`
  font-size: 1.7rem;
  font-weight: ${(props) => props.theme.fontWeight.normal};
  color: ${(props) => props.theme.colors.light};
  line-height: 1.2;

  @media ${(props) => props.theme.breakpoints.lg} {
    font-size: 3rem;
  }

  & span:first-child {
    font-weight: ${(props) => props.theme.fontWeight.bold};
    text-transform: uppercase;
  }

  & span:last-child {
    color: ${(props) => props.theme.colors.primary1};
  }

  @media ${(props) => props.theme.breakpoints.xxl} {
    font-size: 4rem;
  }
`;

export const HeroImageContainer = styled.div`
  text-align: center;

  & > img {
    width: 380px;
  }

  @media ${(props) => props.theme.breakpoints.lg} {
    position: relative;

    text-align: initial;

    & > img {
      width: 400px;

      @media ${(props) => props.theme.breakpoints.xxl} {
        width: 570px;
      }
    }
  }
`;

export const HeroImageCard = styled.img`
  width: 44vw;

  transform: rotate(324deg);

  @media ${(props) => props.theme.breakpoints.xs} {
    width: 16rem;
  }

  @media ${(props) => props.theme.breakpoints.lg} {
    position: absolute;
    top: -105px;
    left: 10%;

    width: 18rem;

    z-index: 2;
  }

  @media ${(props) => props.theme.breakpoints.xxl} {
    top: -105px;
    left: 20%;

    width: 24rem;
  }
`;

export const HeroImageApp = styled.img`
  @media ${(props) => props.theme.breakpoints.lg} {
    position: absolute;
    top: -150px;
    left: 50%;

    width: 28rem;

    z-index: 0;
  }

  @media ${(props) => props.theme.breakpoints.xxl} {
    top: -180px;
    left: 60%;

    width: 38rem;
  }
`;

//
export const HeroCTAContainer = styled.div`
  text-align: center;

  align-self: center;

  @media ${(props) => props.theme.breakpoints.lg} {
  } ;
`;
