import React, { useState } from "react";

import { BiMinus, BiPlus } from "react-icons/bi";

import {
  AccordionContent,
  AccordionTitle,
  AccordionWrap,
} from "./AccordionStyled";

const Accordion = (props) => {
  const { title, children, isFAQ } = props;

  const [isActive, setIsActive] = useState(false);

  return (
    <>
      <AccordionWrap isFAQ={isFAQ}>
        <AccordionTitle onClick={() => setIsActive(!isActive)} isFAQ={isFAQ}>
          <h4>{title}</h4>
          <div>{isActive ? <BiMinus /> : <BiPlus />}</div>
        </AccordionTitle>

        {isActive && <AccordionContent isFAQ>{children}</AccordionContent>}
      </AccordionWrap>
    </>
  );
};

export default Accordion;
