import styled from "styled-components";

import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";


export const HeaderWrap = styled.header(
  ({ background, theme, isTransparent }) => `
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  background: ${
    background
      ? theme.colors.background2
      : isTransparent
      ? "transparent"
      : theme.colors.background2
  };

  //height: ${theme.spacing.headerHeight};

  padding: 1rem;

  z-index: 3;

  @media ${theme.breakpoints.lg} {}

  & svg {
    justify-self: center;
    color: ${theme.colors.light};
    font-size: 3.2rem;
  }
`
);

export const HeaderIcon = styled(Link)`
  cursor: pointer;
`;

export const HeaderIconLogo = styled(HashLink)`
  cursor: pointer;
`;
