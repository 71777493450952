import { useState } from "react";

import { SidebarLink, SidebarLabel, DropdownLink } from "./SidebarStyled";

const SubMenu = ({ item, showSidebar, redirecting }) => {
  const [subnav, setSubnav] = useState(false);

  const showSubnav = () => setSubnav(!subnav);

  return (
    <>
      <SidebarLink
        to={item.path}
        onClick={item.subNav && showSubnav}
        showsubnav={subnav ? true : null}
      >
        <div>
          {item.icon}
          <SidebarLabel>{item.title}</SidebarLabel>
        </div>

        <div>
          {item.subNav && subnav
            ? item.iconOpened
            : item.subNav
            ? item.iconClosed
            : null}
        </div>
      </SidebarLink>

      {subnav &&
        item.subNav.map((item, index) => {
          let key = item.title;

          const handleRedirecting = () => {
            redirecting(key);
          };

          return (
            <DropdownLink
              to={item.path}
              key={index}
              onClick={showSidebar}
              showsubnav={subnav}
            >
              {item.icon}
              <SidebarLabel onClick={handleRedirecting}>
                {item.title}
              </SidebarLabel>
            </DropdownLink>
          );
        })}
    </>
  );
};

export default SubMenu;
