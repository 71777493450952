import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { RiCloseCircleLine } from "react-icons/ri";

import {
  WhyItemBox,
  WhyModalBody,
  WhyModalBodyBusinessFooter,
  WhyModalBodyItem,
  WhyModalColumnOne,
  WhyModalColumnTwo,
  WhyModalColumnThree,
  WhyModalFooter,
  WhyModalHeader,
  WhyModalWrap,
} from "./HomeWhyStyled";

import { Button } from "../../Button/ButtonStyled";

import useWindowDimensions from "../../../hooks/useDimensions";

import workerImg from "../../../assets/img/workerWhite.svg";
import shopImg from "../../../assets/img/shop.svg";
import buildingImg from "../../../assets/img/buildingWhite.svg";
import medalImg from "../../../assets/img/medal.svg";

//
import videoWorker from "../../../assets/video/video-trabajador.mp4";
import videoShop from "../../../assets/video/video-comercio.mp4";
import videoBusiness from "../../../assets/video/video-empresa.mp4";

import videoWorkerWebM from "../../../assets/video/video-trabajador.webm";
import videoShopWebM from "../../../assets/video/video-comercio.webm";
import videoBusinessWebM from "../../../assets/video/video-empresa.webm";

const HomeWhyModal = ({
  isWorker,
  isShop,
  isBusiness,
  closeModal,
  handlePlayVideo,
  vidRef,
}) => {
  const { width } = useWindowDimensions();

  const navigate = useNavigate();

  const handleBtnRoute = () => {
    navigate("/simulador");
  };

  useEffect(() => {
    console.log("se montó");

    handlePlayVideo();
  }, []);

  useEffect(() => {
    if (width < 1024) {
      document.body.style.overflow = "hidden";
      return () => (document.body.style.overflow = "");
    }
  }, []);

  return (
    <>
      <WhyModalWrap>
        {width > 1023 && (
          <WhyModalColumnOne>
            <video
              autoPlay
              loop
              webkit-playsinline="true"
              playsinline="true"
              ref={vidRef}
            >
              <source
                src={
                  isWorker
                    ? videoWorkerWebM
                    : isShop
                    ? videoShopWebM
                    : videoBusinessWebM
                }
                type="video/webm"
              />

              <source
                src={
                  isWorker ? videoWorker : isShop ? videoShop : videoBusiness
                }
                type="video/mp4"
              />
            </video>
          </WhyModalColumnOne>
        )}

        <WhyModalColumnTwo>
          <div>
            {/* Header */}
            <WhyModalHeader>
              {isWorker && (
                <>
                  <WhyItemBox worker isModal>
                    <img src={workerImg} alt="Colaboradores" />
                  </WhyItemBox>
                  <h3>Colaboradores</h3>
                </>
              )}

              {isShop && (
                <>
                  <WhyItemBox shop isModal>
                    <img src={shopImg} alt="comercios" />
                  </WhyItemBox>
                  <h3>Comercios</h3>
                </>
              )}

              {isBusiness && (
                <>
                  <WhyItemBox isModal>
                    <img src={buildingImg} alt="empresas" />
                  </WhyItemBox>
                  <h3>Empresas</h3>
                </>
              )}
            </WhyModalHeader>

            {/* Body */}
            <WhyModalBody>
              {isWorker && (
                <>
                  {/* Item 1 */}
                  <WhyModalBodyItem>
                    <img src={medalImg} alt="medalla" />
                    <p>
                      <span>En todo Chile,</span> miles de comercios digitales
                      de alimentación afiliados a <span>Mastercard.</span>
                    </p>
                  </WhyModalBodyItem>

                  {/* Item 2 */}
                  <WhyModalBodyItem>
                    <img src={medalImg} alt="medalla" />
                    <p>
                      Puede ser usado en plataformas de delivery de compras de
                      alimentos.
                    </p>
                  </WhyModalBodyItem>

                  {/* Item 3 */}
                  <WhyModalBodyItem>
                    <img src={medalImg} alt="medalla" />
                    <p>
                      Los saldos de las tarjetas <span>no vencen</span> y
                      <span> son acumulables</span> de un mes para otro. Además
                      puede gestionar el consumo diario y donde utilizar el
                      beneficio.
                    </p>
                  </WhyModalBodyItem>

                  {/* Item 4 */}
                  <WhyModalBodyItem>
                    <img src={medalImg} alt="medalla" />
                    <p>
                      Podrás tener una tarjeta de prepago física y/o digital con
                      <span> Mastercard</span>, la cual contempla un club de
                      puntos y beneficios para todos.
                    </p>
                  </WhyModalBodyItem>
                </>
              )}

              {isShop && (
                <>
                  {/* Item 1 */}
                  <WhyModalBodyItem>
                    <img src={medalImg} alt="medalla" />
                    <p>
                      <span>Mas ventas y mayor crecimiento!</span> Existirá un
                      potencial grande de usuarios que tendrán acceso a la
                      tarjeta Sabores Card, dispuestos a consumir.
                    </p>
                  </WhyModalBodyItem>

                  {/* Item 2 */}
                  <WhyModalBodyItem>
                    <img src={medalImg} alt="medalla" />
                    <p>
                      Los comercios que reciben Sabores Card tendrán los pagos
                      de sus ventas hasta en 24hrs. a través de las redes POS
                      disponibles en Chile. Donde acepten{" "}
                      <span>Mastercard</span>, aceptan{" "}
                      <span> Sabores Card!</span>
                    </p>
                  </WhyModalBodyItem>

                  {/* Item 3 */}
                  <WhyModalBodyItem>
                    <img src={medalImg} alt="medalla" />
                    <p>
                      No requiere implementación de dispositivos adicionales de
                      pagos.
                    </p>
                  </WhyModalBodyItem>
                </>
              )}

              {isBusiness && (
                <>
                  {/* Item 1 */}
                  <WhyModalBodyItem>
                    <img src={medalImg} alt="medalla" />
                    <p>
                      <span>En todo Chile,</span> miles de comercios digitales
                      de alimentación afiliados a <span>Mastercard.</span>
                    </p>
                  </WhyModalBodyItem>

                  {/* Item 3 */}
                  <WhyModalBodyItem>
                    <img src={medalImg} alt="medalla" />
                    <p>
                      No requiere implementación de dispositivos adicionales de
                      pagos.
                    </p>
                  </WhyModalBodyItem>

                  {/* Item 4 */}

                  <WhyModalBodyBusinessFooter>
                    <h4>
                      Simula el <span>ahorro tributario</span> que tendrá tu
                      empresa al contratar <span>Sabores Card.</span>
                    </h4>

                    <Button primary onClick={handleBtnRoute}>
                      Simular ahorro tributario
                    </Button>
                  </WhyModalBodyBusinessFooter>
                </>
              )}
            </WhyModalBody>
          </div>

          {/* Footer */}
          {width < 1023 && (
            <WhyModalFooter>
              <RiCloseCircleLine onClick={closeModal} />
            </WhyModalFooter>
          )}
        </WhyModalColumnTwo>

        {width > 1023 && (
          <WhyModalColumnThree>
            <WhyModalFooter>
              <RiCloseCircleLine onClick={closeModal} />
            </WhyModalFooter>
          </WhyModalColumnThree>
        )}
      </WhyModalWrap>
    </>
  );
};

export default HomeWhyModal;
