import styled, { keyframes, css } from "styled-components";

//
const textAnimation = keyframes`  
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 200% 0;
  }  

  100% {
    background-position: 0 0;
  }  
`;

//
const boxAnimation = keyframes`  
  0% {
    background-position: 0 50%;
  }

  50% {
    background-position: 100% 50%;
  }  

  100% {
    background-position: 0 50%;
  }  
`;

export const TextGradient = styled.span`
  color: transparent;

  //background-image: linear-gradient(90deg, #4875D6 0%, #DC5CA9 100%);
  background: linear-gradient(
      45deg,
      #4875d6,
      #dc5ca9,
      #e5ba50,
      #b2cd5d,
      #66c6ce,
      #4875d6
    )
    0% 0% / 400%;

  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  animation: ${textAnimation} 10s ease infinite;
`;

export const BoxGradient = styled.div(
  ({ theme, isTransparent, isBig }) => css`
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 4rem;
    height: 4rem;

    border-radius: 1rem;

    overflow: hidden;

    & > img,
    & > svg {
      font-size: 2.8rem;

      color: ${(props) => props.theme.colors.dark};
      fill: #3f464f;

      z-index: 1;
    }

    & > img {
      width: 2.6rem;

      @media ${(props) => props.theme.breakpoints.lg} {
        width: 4rem;
      }
    }

    &:before {
      animation: ${boxAnimation} 4s ease infinite;

      content: "";

      position: absolute;
      top: -25%;
      right: 0;
      bottom: 0;
      left: -25%;

      background: linear-gradient(
          124deg,
          #4875d6,
          #dc5ca9,
          #e5ba50,
          #b2cd5d,
          #66c6ce,
          #4875d6
        )
        0% 0% / 200%;

      //background-size: 200% 200%;

      width: 150%;
      height: 150%;
    }

    &:after {
      content: "";

      position: absolute;
      top: 6.5%;
      left: 7.5%;

      background: ${isTransparent ? "transparent" : theme.colors.light};

      width: 85%;
      height: 85%;

      border-radius: 0.5rem;
    }

    @media ${(props) => props.theme.breakpoints.lg} {
      width: 6rem;
      height: 6rem;

      & > svg {
        font-size: 4rem;
      }
    }
  `
);

export const BoxGradientAlt = styled.div(
  ({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;

    background-image: linear-gradient(90deg, #b46cec, #ce7eb6, #c174d2),
      linear-gradient(90deg, #b46cec, #ce7eb6, #c174d2);
    background-size: 100% 5px;
    background-position: 0 0, 0 100%;
    background-repeat: no-repeat;

    width: 6.4rem;
    height: 6.4rem;

    //padding: .7rem;

    border-left: 5px solid #b46cec;
    border-right: 5px solid #ce7eb6;

    border-radius: 1rem;

    //animation: ${boxAnimation} 4s ease infinite;

    & > svg {
      color: ${theme.colors.light};

      font-size: 4rem;
    }
  `
);

export const CircleGradientAlt = styled.div(
  () => css`
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    --b: 2px; /* border width*/

    //border-width: 5px;

    width: 4rem;
    height: 4rem;

    z-index: 0;

    //animation: ${boxAnimation} 4s ease infinite;

    &:before {
      content: "";

      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      background: linear-gradient(
        to top,
        #5454d4,
        #2aa9ff,
        rgba(249, 116, 104)
      );

      -webkit-mask: radial-gradient(
        farthest-side,
        transparent calc(100% - var(--b) - 1px),
        #fff calc(100% - var(--b))
      );

      mask: radial-gradient(
        farthest-side,
        transparent calc(100% - var(--b) - 1px),
        #fff calc(100% - var(--b))
      );

      border-radius: 50%;

      z-index: -1;
    }

    @media ${(props) => props.theme.breakpoints.lg} {
      width: 6rem;
      height: 6rem;
    }
  `
);

//
export const BorderGradient = styled.div`
  width: 100%;
  height: 0.7rem;

  background: linear-gradient(
    270deg,
    #00c5da 0%,
    #7a8fda 14.73%,
    #bd71db 33.64%,
    #ce7eb6 69.09%,
    #e58d87 86.67%,
    #f49867 100%
  );
`;
