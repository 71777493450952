//
const animationType = 'spring';
const animationDelay = .4;
const animationDuration = 1.8;

// 
export const fromLeftAnimation = {
  hidden: {
    x: "-100vw",
    opacity: 0,
    transition: {
      type: animationType,
      duration: animationDuration,
      delay: animationDelay,
    },
  },
  show: {
    x: 0,
    opacity: 1,
    transition: {
      type: animationType,
      duration: animationDuration,
      delay: animationDelay,
    },
  },
};

export const fromRightAnimation = {
  hidden: {
    x: "100vw",
    opacity: 0,
    transition: {
      type: animationType,
      duration: animationDuration,
      delay: animationDelay,
    },
  },
  show: {
    x: 0,
    opacity: 1,
    transition: {
      type: animationType,
      duration: animationDuration,
      delay: animationDelay,
    },
  },
};

export const fromUp = {
  hidden: {
    y: "-10vh",
    opacity: 0,
    transition: {
      type: animationType,
      duration: animationDuration,
      delay: animationDelay,
    },
  },
  show: {
    y: 0,
    opacity: 1,
    transition: {
      type: animationType,
      duration: animationDuration,
      delay: animationDelay,
    },
  },
};

export const fromUpTwo = {
  hidden: {
    y: "-30vh",
    opacity: 0,
    transition: {
      type: animationType,
      duration: animationDuration,
      delay: animationDelay,
    },
  },
  show: {
    y: 0,
    opacity: 1,
    transition: {
      type: animationType,
      duration: animationDuration,
      delay: animationDelay,
    },
  },
};

export const fromDown = {
  hidden: {
    y: "10vh",
    opacity: 0,
    transition: {
      type: animationType,
      duration: animationDuration,
      delay: animationDelay,
    },
  },
  show: {
    y: 0,
    opacity: 1,
    transition: {
      type: animationType,
      duration: animationDuration,
      delay: animationDelay,
    },
  },
};

export const scaleAnimation = {
  hidden: {
    scale: 0,
    opacity: 0,
    transition: {
      type: animationType,
      duration: animationDuration,
      delay: animationDelay,
    },
  },
  show: {
    scale: 1,
    opacity: 1,
    transition: {
      type: animationType,
      duration: animationDuration,
      delay: animationDelay,
    },
  },
};

export const scaleAnimation2 = {
  hidden: {
    scale: 2,
    opacity: 0,
    transition: {
      type: animationType,
      duration: animationDuration,
      delay: animationDelay,
    },
  },
  show: {
    scale: 1,
    opacity: 1,
    transition: {
      type: animationType,
      duration: animationDuration,
      delay: animationDelay,
    },
  },
};

export const textAnimation = {
  hidden: {
    opacity: 0,
    transition: {
      type: animationType,
      duration: animationDuration,
      delay: animationDelay,
    },
  },
  show: {
    opacity: 1,
    transition: {
      type: animationType,
      duration: animationDuration,
      delay: animationDelay,
    },
  },
};

export const childrenAnimation = {
  hidden: {
    opacity: 0,
    y: "-10vw",
    transition: {
      //when: "beforeChildren",
      type: animationType,
      duration: animationDuration,
      delay: animationDelay,
    },
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1.8,
    },
  },
};

export const parentContainerAnimation = {
  hidden: {
    opacity: 0,
    transition: {
      type: animationType,
      duration: animationDuration,
      delay: animationDelay,
    },
  },

  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.4,
      type: animationType,
      duration: animationDuration,
      delay: animationDelay,
    },
  },
};

// export const fromTranslateLeftAnimation = {
//   hidden: { x: -700 },
//   show: { x: 0 },
// };

// export const topContainerAnimation = {
//   hidden: {
//     opacity: 0,
//   },
//   show: {
//     opacity: 1,
//   },
// };

// export const videoAnimation = {
//   hidden: { x: -800, opacity: 0 },
//   show: { x: 0, opacity: 1 },
// };

// export const reasonsAnimation = {
//   hidden: { x: 800, opacity: 0 },
//   show: { x: 0, opacity: 1 },
// };

// export const navbarAnimation = {
//   hidden: { y: -100 },
//   show: { y: 0 },
// };
