// Phone regex
export const phoneRegExp = /^[0-9]*$/;

// Email regex
export const emailReg = /^[^\s@]+@[^\s@]+\.([^\s@]{2,})+$/;
//export const emailReg = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

// Alphabet regex
export const alphabetReg = /[^a-zA-Z0-9_ ]*$/;

// Currency format
export const convertNumber = (number) => {
  let numberFormat = Number(number);

  return Intl.NumberFormat('es-CL', {
    style: 'currency',
    currency: 'CLP',
  })
    .format(numberFormat)
    .replace('$', '');
};

//
export const allowOnlyNumber = (value) => {
  let verifyNum = value.replace(/[^A-Z\d]/g, '');
  return verifyNum;
};

//
export const allowOnlyLetters = (value) => {
  let verifyText = value.replace(/[^a-z ]/gi, '');
  return verifyText;
};

//
// export const allowOnlyEmail = (value) => {
//   let verifyText = value.replace(emailReg, '');
//   return verifyText;
// };

//
export const allowNumsLetters = (value) => {
  let verifyText = value.replace(alphabetReg, '');
  return verifyText;
};

//
export const handleSpecialCh = (e) => {
  if (
    e.charCode === 46 ||
    e.charCode === 45 ||
    e.charCode === 101 ||
    e.charCode === 43
  )
    e.preventDefault(); // not allowed to type .
};

//
export const handleArrowKeys = (e) => {
  if ([32, 38, 40].indexOf(e.keyCode) > -1) {
    e.preventDefault();
  }
};
