import React from "react";

import {
  MenuHashFooter,
  MenuLinkFooter,
  MenuListFooter,
  MenuWrapFooter,
} from "./NavLinksFooterStyled";

const NavLinksFooter = ({ isHome, isMenu, isLegal }) => {
  //
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;

    const yOffset = -70;

    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  return (
    <>
      <MenuWrapFooter>
        {isHome && (
          <MenuListFooter>
            {/* 
            <li onClick={scrollWithOffset}>
              <MenuHashFooter to="/#What" scroll={scrollWithOffset}>
                Qué es Sabores Card
              </MenuHashFooter>
            </li> 
            */}
            <li>
              <MenuHashFooter to="/#Why" scroll={scrollWithOffset}>
                ¿Por qué Sabores Card?
              </MenuHashFooter>
            </li>
            <li>
              <MenuHashFooter to="/#App" scroll={scrollWithOffset}>
                App Sabores Card
              </MenuHashFooter>
            </li>
            <li>
              <MenuHashFooter to="/#Alliance" scroll={scrollWithOffset}>
                Alianza
              </MenuHashFooter>
            </li>
            <li>
              <MenuHashFooter to="/#Form" scroll={scrollWithOffset}>
                Quiero contratar
              </MenuHashFooter>
            </li>
          </MenuListFooter>
        )}

        {isMenu && (
          <MenuListFooter>
            <li>
              <MenuLinkFooter to="/saborescard">Sabores Card</MenuLinkFooter>
            </li>
            <li>
              <MenuLinkFooter to="/simulador">Simulador</MenuLinkFooter>
            </li>
            <li>
              <MenuLinkFooter to="/faq">Preguntas frecuentes</MenuLinkFooter>
            </li>
            <li>
              <MenuLinkFooter to="/contacto">Contacto</MenuLinkFooter>
            </li>
          </MenuListFooter>
        )}

        {isLegal && (
          <MenuListFooter>
            <li>
              <MenuLinkFooter to="/terminosycondiciones">
                Términos y condiciones
              </MenuLinkFooter>
            </li>
            <li>
              <MenuLinkFooter to="/politicadeprivacidad">
                Política de privacidad
              </MenuLinkFooter>
            </li>
            <li>
              <MenuLinkFooter to="/politicasdecookies">
                Política de cookies
              </MenuLinkFooter>
            </li>
            <li>
              <MenuLinkFooter to="/denuncias">
                Canal de denuncias
              </MenuLinkFooter>
            </li>
            <li>
              <MenuLinkFooter to="/ley">Ley 21.234</MenuLinkFooter>
            </li>
          </MenuListFooter>
        )}
      </MenuWrapFooter>
    </>
  );
};

export default NavLinksFooter;
