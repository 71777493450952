import React, { useState } from "react";

import { motion } from "framer-motion";

import { browserName } from "react-device-detect";

import {
  FormCardBack,
  FormCardFront,
  FormContainer,
  FormTabContact,
  FormTabConversion,
  FormTabsCont,
  FormTitleContainer,
  FormWrap,
} from "./HomeFormStyled";

import useWindowDimensions from "../../../hooks/useDimensions";
import { useScrollAnimation } from "../../../hooks/useScrollAnimation";

import { MaxWidthWrap, TitleSection } from "../../Reusable";
import FormConversion from "../../Forms/FormConversion";
import FormContact from "../../Forms/FormContact";
import { fromDown, fromUp } from "../../../utils/animations";

const HomeForm = (props) => {
  const [showFormConversion, setShowFormConversion] = useState(true);
  const [showFormContact, setShowFormContact] = useState(false);

  const { sectionID } = props;

  const { width } = useWindowDimensions();

  const [element, controls] = useScrollAnimation();

  const handleShowFormConversion = () => {
    setShowFormContact(false);
    setShowFormConversion(true);
  };

  const handleShowFormContact = () => {
    setShowFormConversion(false);
    setShowFormContact(true);
  };

  return (
    <FormWrap id={sectionID} ref={element}>
      {width < 1023 ? (
        <MaxWidthWrap>
          <FormTitleContainer>
            <TitleSection>Quiero ser cliente</TitleSection>
          </FormTitleContainer>

          <FormContainer>
            <FormConversion />
          </FormContainer>
        </MaxWidthWrap>
      ) : (
        <MaxWidthWrap>
          <FormTabsCont
            as={motion.div}
            animate={controls}
            initial="hidden"
            variants={fromUp}
          >
            <FormTabConversion
              onClick={handleShowFormConversion}
              showFormConversion={showFormConversion}
            >
              <h4>Quiero ser cliente</h4>
            </FormTabConversion>

            <FormTabContact
              onClick={handleShowFormContact}
              showFormContact={showFormContact}
            >
              <h4>Quiero consultar</h4>
            </FormTabContact>
          </FormTabsCont>

          <motion.div animate={controls} initial="hidden" variants={fromDown}>
            {browserName !== "Safari" ? (
              <FormContainer
                showFormContact={showFormContact}
                showFormConversion={showFormConversion}
              >
                <FormCardFront
                  className="formCard"
                  showFormConversion={showFormConversion}
                >
                  <div>
                    <h3>Quiero ser Cliente</h3>
                  </div>
                  <FormConversion />
                </FormCardFront>

                <FormCardBack
                  className="formCard"
                  showFormContact={showFormContact}
                >
                  <div>
                    <h3>Quiero consultar</h3>
                  </div>
                  <FormContact />
                </FormCardBack>
              </FormContainer>
            ) : (
              <>
                <div className="formContainerSafari">
                  {showFormConversion && (
                    <>
                      <div>
                        <h3>Quiero ser Cliente</h3>
                      </div>
                      <FormConversion />
                    </>
                  )}

                  {showFormContact && (
                    <>
                      <div>
                        <h3>Quiero consultar</h3>
                      </div>
                      <FormContact />
                    </>
                  )}
                </div>
              </>
            )}
          </motion.div>
        </MaxWidthWrap>
      )}
    </FormWrap>
  );
};

export default HomeForm;
