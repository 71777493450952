import React from "react";

import {
  FooterDesktopCont,
  FooterDesktopRowOne,
  FooterColumnOne,
  FooterColumnTwo,
  FooterColumnThree,
  FooterColumnFour,
  FooterColumnFive,
  FooterSubtitle,
  FooterDesktopRowTwo,
  FooterColumnOneAlt,
  FooterColumnTwoAlt,
  FooterMailLink,
  FooterColumnLogoList,
} from "./FooterStyled";
import { Logo } from "../Reusable";
import NavLinksFooter from "../NavLinks/NavLinksFooter";
import SocialMedia from "../SocialMedia/SocialMedia";

//
import LogoSC from "../../assets/img/LogoSaboresLight.svg";
import UProcessing from "../../assets/img/u-pay-processing1.png";
import UProcessing2 from "../../assets/img/u-pay-processing2.png";

import UPayments from "../../assets/img/u-payments.svg";
import Mastercard from "../../assets/img/mastercard.svg";
import Award from "../../assets/img/premio-excelencia.svg";
import Pci from "../../assets/img/pci.svg";
import PciCertified from "../../assets/img/pci-certified.svg";

import MicrosoftPartner from "../../assets/img/microsoft-partner.svg";

import BestCompanies from "../../assets/img/best-companies.svg";
import Seglan from "../../assets/img/seglan.svg";
import Oracle from "../../assets/img/oracle.svg";
import Cisco from "../../assets/img/cisco.svg";

const FooterDesktop = (props) => {
  const { handleMailTo } = props;

  return (
    <>
      <FooterDesktopCont>
        <FooterDesktopRowOne>
          <FooterColumnOne>
            <Logo src={LogoSC} />
            <img src={UProcessing2} alt="logo u-pay processing" />
            <img src={UProcessing} alt="logo u-pay processing" />
            <div>
              <h4>&copy; {new Date().getFullYear()} Sabores Card</h4>
            </div>
          </FooterColumnOne>

          <FooterColumnTwo>
            <FooterSubtitle>Home</FooterSubtitle>
            <NavLinksFooter isHome />
          </FooterColumnTwo>

          <FooterColumnThree>
            <FooterSubtitle>Menu</FooterSubtitle>
            <NavLinksFooter isMenu />
          </FooterColumnThree>

          <FooterColumnFour>
            <FooterSubtitle>Legales</FooterSubtitle>
            <NavLinksFooter isLegal />
          </FooterColumnFour>

          <FooterColumnFive>
            <a
              target="_blank"
              href="https://www.u-payments.com/"
              rel="noopener noreferrer"
            >
              <img src={UPayments} alt="logo u-payments" />
            </a>

            <a
              target="_blank"
              href="https://www.mastercard.cl/es-cl.html"
              rel="noopener noreferrer"
            >
              <img src={Mastercard} alt="logo mastercard" />
            </a>
          </FooterColumnFive>
        </FooterDesktopRowOne>

        <FooterDesktopRowTwo>
          <FooterColumnOneAlt>
            <div>
              <h5>Síguenos en</h5>
              <SocialMedia />
            </div>

            <div>
              <FooterMailLink to="#" onClick={handleMailTo}>
                contacto@saborescard.cl
              </FooterMailLink>
            </div>

            <div>
              <a className="footer__phone-link" href="tel:600 3600 405">
                600 3600 405
              </a>
            </div>
          </FooterColumnOneAlt>

          <FooterColumnTwoAlt>
            <div>
              <h5>Premios y certificaciones U-Payments</h5>
            </div>

            <FooterColumnLogoList>
              <ul>
                <li>
                  <img src={Award} alt="premio" width={70} />
                </li>
                <li>
                  <img src={Pci} alt="pci" width={120} />
                </li>
                <li>
                  <img src={MicrosoftPartner} alt="premio" width={100} />
                </li>
                <li>
                  <img src={BestCompanies} alt="premio" width={50} />
                </li>
                <li>
                  <img src={PciCertified} alt="pci certified" width={60} />
                </li>
                <li>
                  <img src={Seglan} alt="seglan" width={70} />
                </li>
                <li>
                  <img src={Oracle} alt="Oracle" width={80} />
                </li>
                <li>
                  <img src={Cisco} alt="cisco" width={70} />
                </li>
              </ul>
            </FooterColumnLogoList>

            {/* <FooterColumnLogoList>
              <ul>
                <li>
                  <img src={BestCompanies} alt="premio" width={50} />
                </li>
                <li>
                  <img src={PciCertified} alt="pci certified" width={60} />
                </li>
                <li>
                  <img src={Seglan} alt="seglan" width={70} />
                </li>
                <li>
                  <img src={Oracle} alt="Oracle" width={80} />
                </li>
                <li>
                  <img src={Cisco} alt="cisco" width={70} />
                </li>
              </ul>
            </FooterColumnLogoList> */}
          </FooterColumnTwoAlt>
        </FooterDesktopRowTwo>
      </FooterDesktopCont>
    </>
  );
};

export default FooterDesktop;
